import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { MenubarModule } from 'primeng/menubar';
import { SearchWidgetComponent } from './search-widget/search-widget.component';
import { ToolbarModule } from 'primeng/toolbar';
import { AccordionModule } from 'primeng/accordion';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { CarouselModule } from "primeng/carousel";
import { TabViewModule } from 'primeng/tabview';
import { PanelModule } from 'primeng/panel';
import { TagModule } from 'primeng/tag';
import { CardModule } from 'primeng/card';
import { ApplicablePassesWidgetComponent } from './applicable-passes-widget/applicable-passes-widget.component';
import { InDemandCoursesWidgetComponent } from './in-demand-courses-widget/in-demand-courses-widget.component';
import { UpcomingConferencesWidgetComponent } from './upcoming-conferences-widget/upcoming-conferences-widget.component';
import { StayInTouchContactFormComponent } from './stay-in-touch-contact-form/stay-in-touch-contact-form.component';
import { DropdownModule } from 'primeng/dropdown';
import { CpePassesWidgetComponent } from './cpe-passes-widget/cpe-passes-widget.component';
import { ExploreAllTopicsWidgetComponent } from './explore-all-topics-widget/explore-all-topics-widget.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PipesModule } from '../_pipes/pipes.module';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SearchWidgetComponent,
    ApplicablePassesWidgetComponent,
    InDemandCoursesWidgetComponent,
    UpcomingConferencesWidgetComponent,
    StayInTouchContactFormComponent,
    CpePassesWidgetComponent,
    ExploreAllTopicsWidgetComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MenubarModule,
    InputTextModule,
    InputNumberModule,
    ButtonModule,
    ToolbarModule,
    AccordionModule,
    ScrollPanelModule,
    CheckboxModule,
    RadioButtonModule,
    CalendarModule,
    MultiSelectModule,
    CarouselModule,
    TabViewModule,
    PanelModule,
    TagModule,
    CardModule,
    DropdownModule,
    InputSwitchModule,
    PipesModule
  ],
  exports: [
    HeaderComponent, 
    FooterComponent, 
    SearchWidgetComponent, 
    ApplicablePassesWidgetComponent, 
    InDemandCoursesWidgetComponent, 
    UpcomingConferencesWidgetComponent, 
    StayInTouchContactFormComponent, 
    CpePassesWidgetComponent,
    ExploreAllTopicsWidgetComponent
  ]
})
export class MainLayoutModule {}
