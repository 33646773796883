<div id="header-banner" class="p-1 flex justify-content-center align-items-center flex-wrap" *ngIf="!isServer">
  <div class="container">
    <div *ngIf="banners?.data.length > 0">
      <p-carousel
        [value]="banners?.data"
        [showIndicators]="false"
      >
        <ng-template let-banner pTemplate="banner">
          <a href='{{ normalizeUrl(banner.attributes.field_url.uri) }}' >
          <div class="row">
            <span class="font-bold">
              <span *ngIf="banner.attributes.field_discount">
                <span class="lg:text-2xl">{{ banner.attributes.field_discount }} OFF </span>
              </span>
              <span class="lg:text-2xl">{{ banner.attributes.title }}</span>
            </span><br />
          </div>
          <div class="row" style="text-align: center;">{{ banner.attributes.field_text }}</div>
          </a>
        </ng-template>
      </p-carousel>
    </div>
  </div>
</div>
<div class="cpe-container header-list-menu" *ngIf="!isServer">
  <p-menubar id="mainMenu" [model]="menuItems" class="cpe-wrapper" *ngIf="visible">
    <ng-template pTemplate="start">
      <div class="p-menubar-start-inner">
        <div id="logo-title">
          <div id="logo"><a href="/" routerLink="/" title="Home" rel="home"><img src="assets/logo-new.png" alt="Home" id="logo-image" class="mr-2"></a></div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="end">
      <span class="p-input-icon-right">
        <div class="input-bar-box">
          <i class="pi pi-search" id="search-input-icon" (click)="showSearchInput()" ></i>
          <input type="text" [style.visibility]="!displaySearchInput ? 'hidden' : 'visible'" class="form-control input-btn-btn" id="search" pInputText placeholder="Search..." [(ngModel)]="searchText" (keydown.enter)="search()" (input)="searchService.searchQuery = searchText" />
        </div>
        <p class="signmenu" *ngIf="!isLoggedIn" (click)="takeAction('Sign In')"><i class="pi pi-user" id="search-input-icon"></i> Sign In</p>
        <p class="signmenu" *ngIf="isLoggedIn" (click)="takeAction('Sign Out')"><i class="pi pi-sign-out" id="search-input-icon"></i> Sign Out</p>
        <div class="cartmenu" (click)="checkout()">
          <i class="pi pi-shopping-cart" id="search-input-icon"></i><span class="badge">{{cartItemsCount}}</span>
        </div>
      </span>
    </ng-template>
  </p-menubar>
</div><!--cpe-container-->
