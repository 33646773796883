<div class="cpe-container" id="EthicsCourses">
    <div class="cpe-wrapper pb-6">
        <div class="grid pt-2">
            <div class="column-heading cpe-dark-blue-background align-content-center w-full pl-3">
                <h1>Ethics Webinars, Self-Study & Seminars</h1>
            </div>
            <div class="col-12 flex justify-content-center align-items-center pb-2">
                <img src="assets/ethics-courses.png">
            </div>
            <div class="col-12 flex justify-content-center align-items-center">
                <p class="m-0 ethics-courses-desc">
                    If you're a CPA, chances are you'll need to earn CPE Credits in Professional Ethics.
                    CPE INC. is committed to helping you satisfy your Ethics requirement by offering a
                    full schedule of Ethics live webinars, self-study courses and seminars.
                    Click "View Details" for the course description and a calendar of program dates
                </p>
            </div>
            <div class="col-12 flex justify-content-center align-items-center">
                <h3 class="m-0 font-bold ethics-courses-desc">TIMES VARY. CLICK ON PROGRAM'S CALENDAR FOR SPECIFIC TIMES.</h3>
            </div>
            <div class="col-12 pb-3">
                <div class="col-12 flex justify-content-center align-items-center">
                    <h3 class="m-0 label">Select Your State and Desired Course Format</h3>
                </div>
                <div class="grid">
                    <div class="col-2 col-offset-3 mr-2">
                        <p-dropdown id="ethics-courses-search-dropdown" [options]="states" optionLabel="name" optionValue="key" [(ngModel)]="selectedState" placeholder="Choose a state" [showClear]="true" [dropdownIcon]="'fa-solid fa-caret-down'">
                            <ng-template let-item>
                              <span class="button-label">{{ item }}</span>
                            </ng-template>
                          </p-dropdown>
                    </div>
                    <div class="col-2 mr-3">
                        <p-dropdown id="ethics-courses-search-dropdown" [options]="formats" optionLabel="name" optionValue="key" [(ngModel)]="selectedFormat" placeholder="Choose a format" [showClear]="true" [dropdownIcon]="'fa-solid fa-caret-down'">
                            <ng-template let-item>
                              <span class="button-label">{{ item }}</span>
                            </ng-template>
                          </p-dropdown>
                    </div>
                    <div class="col">
                        <p-button styleClass="font-bold" id="search-ethic-courses-btn" label="Search" (onClick)="searchEthicCourses()"></p-button>
                    </div>
                </div>
            </div>
            <div class="col-12" *ngIf="!showSearchResults">
                <div class="column-heading cpe-dark-blue-background align-content-center pl-3">
                    <h1>Featured Ethics Courses</h1>
                </div>
                <div *ngIf="errorMsg" class="alert alert-danger mt-3 mb-0">{{errorMsg}}</div>
                <p-table [value]="featuredEthicCourses" [paginator]="false" [rows]="25" [loading]="loading" 
                            *ngIf="!errorMsg" [class.responsive-search-result-table]="displayResponsiveTableView">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="title" class="w-6">Course Title <p-sortIcon field="title"></p-sortIcon></th>
                            <th pSortableColumn="field_hours">Credits <p-sortIcon field="field_hours"></p-sortIcon>
                            </th>
                            <th>Format</th>
                            <th pSortableColumn="field_price">Price <p-sortIcon field="field_price"></p-sortIcon></th>
                            <th>&nbsp;</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-course>
                        <tr>
                            <td style="text-wrap: balance">
                                <span class="p-column-title" style="min-width: 100px; font-size: 20px;">{{ course.field_canonical_title}}</span>
                                {{ !displayResponsiveTableView ? course.field_canonical_title : '' }}
                            </td>
                            <td colspan="4" *ngIf="displayResponsiveTableView">
                                <span class="p-column-title">
                                  <p>{{ course.field_course_type ? course.field_course_type : '' }}</p>
                                  <p>{{ course?.field_hours ? course.field_hours : '' }} {{ course.field_hours  === 1.00 ? 'Credit' : 'Credits' }} | ${{ course.field_price ? course.field_price : '' }}</p>
                                </span>
                                <span class="p-column-title">
                                  &nbsp;
                                </span>
                                <span class="p-column-title">
                                  &nbsp;
                                </span>
                                <span class="p-column-title">
                                  <p>
                                    <a pButton pRipple label="View Details" id="course-{{course.uuid}}" 
                                    class="view-pass-details-btn font-bold white-space-nowrap" icon="pi pi-angle-right" iconPos="right"
                                    [routerLink]="['/'+course.field_canonical_url]"></a>
                                  </p>
                                </span>
                              </td>
                            <td *ngIf="!displayResponsiveTableView">
                                <span class="p-column-title">Credits</span>
                                {{ course?.field_hours ? (course.field_hours | number : '1.1-1') : '' }}
                            </td>
                            <td *ngIf="!displayResponsiveTableView">
                                <span class="p-column-title">Format</span>
                                {{ course.field_course_type ? course.field_course_type : '' }}
                            </td>
                            <td *ngIf="!displayResponsiveTableView">
                                <span class="p-column-title">Price</span>
                                {{ course.field_price ? ('$'+course.field_price) : '' }}
                                
                            </td>
                            <td *ngIf="!displayResponsiveTableView">
                                <a pButton pRipple label="View Details" id="course-{{course.uuid}}" class="font-bold white-space-nowrap text-white" [routerLink]="['/'+course.field_canonical_url]"></a>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="col-12">
                <div class="column-heading cpe-dark-blue-background align-content-center pl-3">
                    <h1>{{ !showSearchResults ? 'All' : selectedFormat }} Ethics Courses</h1>
                </div>
                <div *ngIf="errorMsg" class="alert alert-danger mt-3 mb-0">{{errorMsg}}</div>
                <!-- if returnedTopics -->
                <p-table [value]="returnedCourses" [paginator]="paginator" [rows]="25" 
                    [class.responsive-search-result-table]="displayResponsiveTableView" [loading]="loading" *ngIf="!errorMsg">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="title">Course Title <p-sortIcon field="title"></p-sortIcon></th>
                            <th pSortableColumn="field_hours">Credits <p-sortIcon field="field_hours"></p-sortIcon>
                            </th>
                            <th>Format</th>
                            <th pSortableColumn="field_price">Price <p-sortIcon field="field_price"></p-sortIcon></th>
                            <th>&nbsp;</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-course>
                        <tr>
                            <td style="text-wrap: balance">
                                <span class="p-column-title" style="min-width: 100px; font-size: 20px;">{{ course.field_canonical_title}}</span>
                                {{ !displayResponsiveTableView ? course.field_canonical_title : '' }}
                            </td>
                            <td colspan="4" *ngIf="displayResponsiveTableView">
                                <span class="p-column-title">
                                  <p>{{ course.field_course_type ? course.field_course_type : '' }}</p>
                                  <p>{{ course?.field_hours ? course.field_hours : '' }} {{ course.field_hours  === 1.00 ? 'Credit' : 'Credits' }} | ${{ course.field_price ? course.field_price : '' }}
                                  </p>
                                </span>
                                <span class="p-column-title">
                                  &nbsp;
                                </span>
                                <span class="p-column-title">
                                  &nbsp;
                                </span>
                                <span class="p-column-title">
                                  <p>
                                    <a pButton pRipple label="View Details" id="course-{{course.uuid}}" 
                                    class="view-pass-details-btn font-bold white-space-nowrap" icon="pi pi-angle-right" iconPos="right"
                                    [routerLink]="['/'+course.field_canonical_url]"></a>
                                  </p>
                                </span>
                              </td>
                            <td *ngIf="!displayResponsiveTableView">
                                <span class="p-column-title">Credits</span>
                                {{ course?.field_hours ? (course.field_hours | number : '1.1-1') : '' }}
                            </td>
                            <td *ngIf="!displayResponsiveTableView">
                                <span class="p-column-title">Format</span>
                                {{ course.field_course_type ? course.field_course_type : '' }}
                            </td>
                            <td *ngIf="!displayResponsiveTableView">
                                <span class="p-column-title">Price</span>
                                {{ course.field_price ? ('$'+course.field_price) : '' }}
                                
                            </td>
                            <td *ngIf="!displayResponsiveTableView">
                                <a pButton pRipple label="View Details" id="course-{{course.uuid}}" class="font-bold white-space-nowrap text-white" [routerLink]="['/'+course.field_canonical_url]"></a>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- if returnedTopics -->
            </div>
        </div>
    </div>
</div>