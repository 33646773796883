<div class="content-page-container cpe-container">
    <div class="cpe-wrapper" [class.justify-content-start]="pageContent">
        <div class="grid">
            <div class="col-12">
                <div [innerHTML]="pageContent" *ngIf="!loading; else spinner"></div>
                <ng-template #spinner>
                    <p-progressSpinner></p-progressSpinner>
                  </ng-template>
            </div>
        </div>
    </div>
</div>