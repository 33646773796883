import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Address, Attendee, LineItem, PaymentMethod, ShoppingCart} from "../../shopping-cart.model";
import {BehaviorSubject, Observable, map} from "rxjs";
import { isPlatformBrowser } from '@angular/common';
import {DateTime} from "luxon";
import {environment} from "../../environments/environment";
import {SessData, SessionService} from "./session.service";

export interface Action {
  type: string,
  payload: LineItem,
}

@Injectable({
  providedIn: 'root',
})
export class CartService {
  public cart: ShoppingCart | null = null;
  public cartItems: LineItem[];
  private sessData: SessData | null = null;

  cartSource = new BehaviorSubject(this.cart);
  currentData = this.cartSource.asObservable();

  sampleAddToCartCourse = [
    {
      "uuid":"ff85b5b5-176e-4cb3-ad68-e9071da61f06",
      "name":"A Valuation Primer for Financial Reporting",
      "cost":299,
      "courseID":"WA370HCPR01119",
      "courseType": "webinar",
      "courseDate":"1735738200",
      "location":"KYW010",
      "field_canonical_url":"/webinar/a-valuation-primer-for-financial-reporting-webinar"
    },
    {
      "uuid":"15cb18c9-0ea5-49e5-a0f0-cff23fc7ede5",
      "name":"Accounting for Derivatives: An Overview",
      "cost":399,
      "courseID":"WA115ECPR01020",
      "courseType": "webinar",
      "courseDate":"1735738200",
      "location":"KYW010",
      "field_canonical_url":"/webinar/accounting-for-derivatives-an-overview-webinar"
    }
  ]
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private http: HttpClient,
    private sess: SessionService,
  ) {
    // Get current session data
    this.sess.currentData.subscribe((data) => {
      this.sessData = data;
    });
    /*
     * Set up cart from storage or CMS
     * We should have a timetstamp on the local cart and the backend cart.
     */
    let cart_id: string;
    let localCart: ShoppingCart = {
      status: 'in-progress'
    };
    // localCart = this.getCart();
    // Look to see if there's a cart for this user already
    // Having a problem with the local cart overriding the backend cart.
    if(localCart?.id) {
      cart_id = localCart.id;
    } else {
      // Look in session / user object for a cart ID, less likely until session is solidified
      cart_id = this.sessData?.user?.cartId;
    }
    //cart_id = '7342'; // For testing.
    let commerceCart: ShoppingCart;
    if(cart_id) {
      // Look in CMS for a cart
      this.getCommerceCartById(cart_id).subscribe({
        next: (data) => {
          console.log('cart.service: getCommerceCartById Success');
          if(data && data.hasOwnProperty('lineItems')) {
            commerceCart = data;
            // Do any calculations
            console.log('Retrieved saved cart from Commerce Cart');
          } else {
            console.log('Commerce Cart is either empty or does not exist.');
            // No lineItems in remote cart, clear local cart
            if(!this.hasItemsInCart()) {
              this.setCart(this.createShoppingCart());
              localCart = null;
            }
          }
          this.cartItems = [];
        },
        error: (error) => {
          // console.error('submitAcct returned false.');
          try {
            throw error;
          } catch (error) {
            console.error('getCommerceCartById returned false: ', error);
            // We should override the state to negate what we tried to do.
            //return this.cart;
          }
        },
        complete: () => {
          let local, remote: number;
          if(localCart?.changed) {
            console.log('Found localCart.changed');
            local = localCart?.changed
          } else {
            console.log('Did NOT find localCart.changed');
            local = 0;
          }
          if(Number(commerceCart?.changed)) {
            console.log('Found commerceCart.changed');
            remote = Number(commerceCart?.changed);
          } else {
            console.log('Did NOT find commerceCart.changed');
            remote = 0;
          }
          if(local > remote) {
            this.setCart(localCart);
            console.log('cart.service: Setting cart with local storage');
          } else if(local <= remote) {
            if(commerceCart && commerceCart.hasOwnProperty('lineItems')) {
              this.setCart(commerceCart);
              console.log('cart.service: Setting cart with remote storage');
            }
          } else {
            // maybe we don't have a cart
            console.log('cart.service:getCommerceCartById(): Creating empty cart!');
            this.setCart(this.createShoppingCart());
          }
        }
      })
    }
    // console.log('cart.service has been reloaded.');
  }
  // Was having problems making sure that the cart is refreshed from the server
  public resetCart() {
    this.cart = this.createShoppingCart();
    this.setCart(this.cart);
  }

  // This needs to return the cart
  public getCart(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/cart/get?_format=json`);
  }

  // If we need more granular control, can enable this
  // This would allow us to switch different storage types for SSR
  public setCart(cart: ShoppingCart): void {
    //sessionStorage.setItem('sessData', JSON.stringify(data));
    this.cart = cart;
    console.log('cart.service->setCart:',cart);
    let serialized = JSON.stringify(cart);
    if(isPlatformBrowser(this.platformId)) {
      window['localStorage'].setItem('cart', serialized);
    }
    this.cartSource.next(cart);
  }

  public addParticipant(item: LineItem, participant: Attendee) {

  }

  hasItemsInCart(): boolean {
    // return this.getCart().lineItems.length > 0;
    return true;
  }

  getCartItems() {
    return [];
    // this.cartItems = this.getCart().lineItems;
    // return this.cartItems;
  }

  getSubtotal():number {
    this.cartItems = this.getCartItems();
    let subtotal = 0;
    if(this.hasItemsInCart()) {
      for(let i in this.cartItems) {
        subtotal = subtotal+Number(this.cartItems[i].cost);

      }
    } else {
      subtotal = 0;
    }

    return subtotal;
  }
  getItemDiscounts(item: LineItem):number {
    let totalDiscounts = 0;
    if(item.hasOwnProperty('discounts') && item.discounts.length > 0) {
      for(let i in item.discounts) {
        if(item.discounts[i].type !== 'ghost_discount') {
          totalDiscounts = totalDiscounts+Number(item.discounts[i].amount);

        }
      }
    }
    return totalDiscounts;
  }

  random4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  public createShoppingCart(): ShoppingCart {
    console.warn('cart.service->createShoppingCart fired because null cart.')
    return {
      id: this.random4(), // Need a utility for this
      status: 'in-progress',
      lineItems: []
    }
  }

  /*
  * Cart getter
  * We should have carts bound to the users by cart_id, and if no cart session exists then we can use this to get the
  * cart from the last time they were on the site.
   */
  public getCommerceCartById(id: string) {
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/json");

    //return this.http.get<ShoppingCart>(`${environment.baseUrl}/cart/${id}`);
    return this.http.get<ShoppingCart>(`${environment.baseUrl}/cart/${id}`);
  }

  /*
  Store the complete cart in CMS
   */
  public storeCommerceCart(cartData: ShoppingCart) {

    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .set("X-CSRF-Token", this.sess.getAccessToken()); // for testing

    return this.http.post(`${environment.baseUrl}/cart/add?_format=json`, JSON.stringify(cartData), {headers})
    // return this.http.post(`https://dev.cpeonline.local/cms/cart/add?_format=json`, JSON.stringify(cartData), {headers})
      .pipe(
        // retry(3) // retry a failed request up to 3 times
      );
  }
  public deleteCommerceItem(id: string, uuid: string) {
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .set("X-CSRF-Token", this.sess.getAccessToken());

    return this.http.delete(`${environment.baseUrl}/cart/${id}/items/${uuid}?_format=json`, {headers})
      .pipe(
        // retry(3) // retry a failed request up to 3 times
      );
  }

  /*
  Need to reset local cart when there are no lineItems
   */
  public deleteCart() {
    if(!this.hasItemsInCart()) {
      this.setCart(this.createShoppingCart());
    }
  }

  // This needs to return the participants
  public getExistingParticipants(): Observable<any> {

    const headers = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("X-CSRF-Token", this.sess.getAccessToken());

    return this.http.get(`${environment.baseUrl}/cart/participant`, {headers});
  }

  // This needs to return the participants
  public saveParticipants(attendees: Attendee[]): Observable<any> {

    const headers = new HttpHeaders()
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("X-CSRF-Token", this.sess.getAccessToken());

    return this.http.post(`${environment.baseUrl}/cart/participant`, attendees, {headers},);
  }

  // This needs to return the order
  public getOrderDetails(id: number | string): Observable<any> {
    const targetURL = `${environment.baseUrl}/user-registration/${id}`;
    // console.log(`getOrderDetails: ${targetURL}`);
    return this.http.get(targetURL);
  }

  public getOrderDetailsUsingRender(id: number | string): Observable<any> {
    // TODO Add render-key to SSR private variable
    const targetURL = `${environment.baseUrl}/user-registration/${id}?render-key=123`;
    // console.log(`getOrderDetailsUsingRender: ${targetURL}`);
    return this.http.get(targetURL);
  }

  /**
   * Given a payment method object parse it and return a human-readable name for the method
   *
   * @param method
   */
  public formatPaymentMethodForDisplay(method: PaymentMethod) {

    if(method.type === 'bill_me') {
      return 'bill me';
    }

    if(method.type === 'credit_card') {
      let cardLast4 = method.cardNumber.substr(-4);
      let disp = 'Card ending ' + cardLast4;
      return disp;
    }

    return 'unknown'

  }
}
