import {Component, Inject, Input, OnChanges, OnInit, PLATFORM_ID, SimpleChanges} from '@angular/core';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import {HttpHeaders} from "@angular/common/http";
import {SearchService} from "../../_services/search.service";
import {TopicService} from "../../_services/topic.service";
import {ListBoxOption} from "../../_types/ui-types";
import { isPlatformBrowser } from '@angular/common';
import { Locations } from 'src/search-types';

@Component({
  selector: 'app-search-widget',
  templateUrl: './search-widget.component.html',
  styleUrls: ['./search-widget.component.scss']
})
export class SearchWidgetComponent implements OnInit, OnChanges {
  topics: any | null = null;
  rawResponse: any[];
  @Input() queryData : any;
  private timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  loading: Boolean = false;
  errorMsg: string = '';
  selectedFormats: any[] = [];
  selectedPasses: [];
  selectedTimes: [];
  textSearch: string;
  searchTopics: string;
  selectedTopics: [];
  selectedDates: string[] = [];
  selectedCredits: string[] =[];
  searchCredits: string;
  selectedLevel: [];
  selectedCost: string[] = [];
  selectedCities: string[] = [];
  priceRange: string;
  dateRange: string[] = [];
  //Store the city and state URL parameters here during processing
  city: string;
  state: string;
  customDateRange: any;
  showCustomDates: boolean = false;
  showCustomCredits: boolean = false;
  creditRangeMin: string;
  creditRangeMax: string;
  showCustomPrice: boolean = false;
  priceRangeMin: string;
  priceRangeMax: string;
  formats: any;
  passes: any;
  times: any;
  citiesAndStates: ListBoxOption[] = [];
  creditHours: any;
  priceRanges: any;
  earlyStart: Date;
  defaultDateEarlyStart: Date;
  defaultDateLateStart: Date;
  oldEarlyStart: Date;
  selectedEarlyStart: string = undefined;
  selectedLatestStart: string = undefined;
  lateStart: Date;
  oldLateStart: Date;
  searchTopicsList: any[] = [];
  showAllFilterExpanded: boolean = true;
  hasFilterFocus: boolean = false;
  utmResource: string;
  currentTimeZoneName: string;
  applicablePassesTooltip: string ="Select a pass option below to search for eligible courses. <a style='color: red'  href='/passes'>Click here</a> for more information on our pass options";

  private dates: any = {
    "today": DateTime.now().setLocale('en-US').toFormat('yyyy-MM-dd'),
    "tomorrow": DateTime.now().setLocale('en-US').plus({ days: 1 }).toFormat('yyyy-MM-dd'),
    "this-week": DateTime.now().setLocale('en-US').toFormat('yyyy-MM-dd') + "," + DateTime.now().setLocale('en-US').plus({ days: 7 }).toFormat('yyyy-MM-dd'),
    "this-month": DateTime.now().setLocale('en-US').startOf('month').toFormat('yyyy-MM-dd') + "," + DateTime.now().setLocale('en-US').endOf('month').toFormat('yyyy-MM-dd'),
    "next-month": DateTime.now().plus({ months: 1 }).setLocale('en-US').startOf('month').toFormat('yyyy-MM-dd') + "," + DateTime.now().plus({ months: 1 }).setLocale('en-US').endOf('month').toFormat('yyyy-MM-dd'),
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private router: Router,
    private es: SearchService,
    private topicService: TopicService,
    private searchService: SearchService,
) { }

  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId)) {
      this.currentTimeZoneName = DateTime.local().offsetNameShort;
      let allowed_timezone_names: string[] = ["PST","PDT","MST","MDT","CST","CDT","EST","EDT","UTC"]
      
      if(!allowed_timezone_names.includes(this.currentTimeZoneName)){
        let UTCDifference = (DateTime.local().offset / 60);
        let commonTimeZoneName =  "UTC"+(UTCDifference > 0 ? ("+"+UTCDifference) : UTCDifference);
        this.currentTimeZoneName = commonTimeZoneName;
      }

      let currentEDTTime: DateTime = DateTime.now().setZone('America/New_York');
      currentEDTTime = currentEDTTime.set({hour: 7, minute: 0, second: 0})
      const edtToUTC: DateTime = currentEDTTime.toUTC();
      let utcToLocal: DateTime = edtToUTC.setZone(DateTime.local().zoneName);
      
      this.defaultDateEarlyStart = new Date(utcToLocal.toISO());

      utcToLocal = utcToLocal.set({hour: utcToLocal.hour + 1});
      this.defaultDateLateStart = new Date(utcToLocal.toISO());

    }
    this.searchService.searchObservable.subscribe((data: boolean) => {
      if(data) {
        this.search();
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getFormats();
    this.getPasses();
    this.getTimes();
    this.getCitiesAndStates();
    this.getCreditHours();
    this.getPriceRanges();
    this.getSearchTopics();

    if(this.queryData && (this.queryData?.constructor === Object && Object.keys(this.queryData).length)) {
      if(Object.keys(this.queryData).length == 1 && this.queryData['textSearch']) {
        this.showAllFilterExpanded = true;
      } else {
        this.showAllFilterExpanded = false;
      }
      this.setValues();
    } else {
      this.showAllFilterExpanded = true;
      this.getTopics();
    }
  }

  private dateToYMD(date): string {
    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();
    return y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
  }

  search(): void {
    // Set up query parameters
    this.queryData = {}

    if(this.utmResource !== '') {
      this.queryData = Object.assign(this.queryData, {utm_resource: this.utmResource});
    }

    // We can NOW do fullTextSearch WITH CriteriaSearch
    if(this.es.searchQuery && this.es.searchQuery !== '') {
      // FullTextSearch
      this.queryData = Object.assign(this.queryData, {textSearch: this.es.searchQuery});
    }


    // If our UI control has a city add it as a URL search param
    if(this.selectedCities && this.selectedCities.length > 0) {
      let city: string[] = [this.selectedCities[0].split(", ")[0]];
      let state: string[] = [this.selectedCities[0].split(", ")[1]];
      this.queryData = Object.assign(this.queryData, {city: city, state: state});
    }

    // CriteriaSearch
    if (this.selectedFormats && this.selectedFormats?.length) {
      this.queryData = Object.assign(this.queryData, {formats: this.selectedFormats.join(",")});
    }
    if (this.selectedCredits && this.selectedCredits?.length) {
      let selectedCreditsArray : string[] = [];
      this.selectedCredits.forEach((credit: string) => {
        if(credit == "custom") {
          let customCreditHours = (this.creditRangeMin ? this.creditRangeMin : this.creditRangeMax) + "-" + (this.creditRangeMax ? this.creditRangeMax : this.creditRangeMin);
          selectedCreditsArray.push(customCreditHours)
        } else {
          selectedCreditsArray.push(credit)
        }
      })
      this.queryData = Object.assign(this.queryData, {searchCredits: selectedCreditsArray.join("_")});
    }
    if (this.selectedCost && this.selectedCost?.length) {
      let selectedCostArray : string[] = [];
      this.selectedCost.forEach((cost: string) => {
        if(cost == "custom") {
          let customPriceRanges = (this.priceRangeMin ? this.priceRangeMin : this.priceRangeMax) + "-" + (this.priceRangeMax ? this.priceRangeMax : this.priceRangeMin);
          selectedCostArray.push(customPriceRanges)
        } else {
          selectedCostArray.push(cost)
        }
        this.queryData = Object.assign(this.queryData, {priceRange: selectedCostArray.join("_")});
      })
    }
    if (this.dateRange && this.dateRange?.length) {
      this.queryData = Object.assign(this.queryData, {startDate: this.dateRange.join("_")});
    }
    if (this.earlyStart || this.lateStart) {
      this.queryData = Object.assign(this.queryData, {"time-zone": this.timezone});
      if(this.earlyStart) {
        this.queryData = Object.assign(this.queryData, {"start-time-earliest": this.selectedEarlyStart});
      }
      if(this.lateStart) {
        this.queryData = Object.assign(this.queryData, {"start-time-latest": this.selectedLatestStart});
      }
    }
    if (this.selectedTopics && this.selectedTopics?.length) {
      this.queryData = Object.assign(this.queryData, {topics: this.selectedTopics.join(",")});
    }
    if (this.selectedLevel && this.selectedLevel?.length) {
      this.queryData = Object.assign(this.queryData, {"knowledge-level": this.selectedLevel.join(",")});
    }
    if (this.selectedPasses && this.selectedPasses?.length) {
      this.queryData = Object.assign(this.queryData, {pass: this.selectedPasses.join(",")});
    }

    this.searchTopics = '';

    this.router.navigate(['/search-results'], {
      queryParams: this.queryData,
    });

  }
  setValues(reset: boolean = false): void {
    if(reset) {
      this.searchTopics = ''; // FullText search Field
      this.searchCredits = ''; // Testing field
      this.priceRange = ''; // Testing field
      this.selectedCredits = []; // Radio Buttons
      this.selectedCost = []; // Radio Buttons
      this.dateRange = []; // Radio Buttons
      this.selectedDates = [];
      this.selectedFormats = [];
      this.search()
    } else {
      if(this.queryData.utm_resource !== '') {
        this.utmResource = this.queryData.utm_resource;
      }

      if(this.queryData.textSearch !== '') {
        this.textSearch = this.queryData.textSearch;
      }

      if(this.queryData.formats?.length) {
        this.selectedFormats = this.queryData.formats.split(",");
      }
      if(this.queryData.searchCredits !== '') {
        this.searchCredits = this.queryData.searchCredits;
      }
      if(this.queryData.priceRange !== '') {
        this.priceRange = this.queryData.priceRange;
      }
      if(this.queryData.searchCredits !== undefined && this.queryData.searchCredits !== '') {
        let creditHoursArray: string[] = this.queryData.searchCredits.split("_");
        this.selectedCredits = [];
        creditHoursArray.forEach((credit: string) => {
          let matchedCreditHourIndex = this.creditHours.findIndex((ch:any) => ch.key == credit);
          if(matchedCreditHourIndex === -1) {
            this.creditRangeMin = credit.split("-")[0]
            this.creditRangeMax = credit.split("-")[1]
            this.selectedCredits.push("custom");
            this.showCustomCredits = true;
          } else {
            this.selectedCredits.push(credit);
          }
        })
      }
      if(this.queryData.priceRange !== undefined && this.queryData.priceRange !== '') {
        let costArray: string[] = this.queryData.priceRange.split("_");
        this.selectedCost = [];
        costArray.forEach((cost: string) => {
          let matchedPriceRangeIndex = this.priceRanges.findIndex((ch:any) => ch.key == cost);
          if(matchedPriceRangeIndex === -1) {
            this.priceRangeMin = cost.split("-")[0]
            this.priceRangeMax = cost.split("-")[1]
            this.selectedCost.push("custom");
            this.showCustomPrice = true;
          } else {
            this.selectedCost.push(cost);
          }
        })
      }
      if(this.queryData.startDate !== undefined && this.queryData.startDate !== '') {
        this.dateRange = this.queryData.startDate.split("_");
        this.selectedDates = [];
        this.dateRange.forEach((selectedDate: string) => {

          if(selectedDate == this.dates['today']) {
            this.selectedDates.push("today");
          } else if(selectedDate == this.dates['tomorrow']) {
            this.selectedDates.push("tomorrow");
          } else if(selectedDate == this.dates['this-week']) {
            this.selectedDates.push("this-week");
          } else if(selectedDate == this.dates['this-month']) {
            this.selectedDates.push("this-month");
          } else if(selectedDate == this.dates['next-month']) {
            this.selectedDates.push("next-month");
          } else {
            this.selectedDates.push("custom");
            this.showCustomDates = true;

            let customStartDate: string = selectedDate.split(",")[0];
            let customEndDate: string = selectedDate.split(",")[1];

            this.customDateRange = [];

            let customStartDateObj = new Date();
            customStartDateObj.setDate(Number(customStartDate.split("-")[2]))
            customStartDateObj.setMonth(Number(customStartDate.split("-")[1]) - 1)
            customStartDateObj.setFullYear(Number(customStartDate.split("-")[0]))

            let customEndDateObj = new Date();
            customEndDateObj.setDate(Number(customEndDate.split("-")[2]))
            customEndDateObj.setMonth(Number(customEndDate.split("-")[1]) - 1)
            customEndDateObj.setFullYear(Number(customEndDate.split("-")[0]))

            this.customDateRange = [ customStartDateObj, customEndDateObj];
          }
        })
      }

      // Set the selected city in the UI control with the value from our query params
      if(this.queryData.city !== '' && this.queryData.city !== undefined) {
        this.selectedCities = [this.queryData.city+", "+this.queryData.state];
      }

      if(this.queryData['start-time-earliest'] !== '' || this.queryData['start-time-latest'] !== '') {
        if(this.queryData['start-time-earliest']) {
          let earlyStartTimeArray = this.queryData['start-time-earliest'].split(":")
          this.earlyStart = new Date(1,1,1970, earlyStartTimeArray[0], earlyStartTimeArray[1], earlyStartTimeArray[2]);
        }
        if(this.queryData['start-time-latest']) {
          let latestStartTimeArray = this.queryData['start-time-latest'].split(":")
          this.lateStart = new Date(1,1,1970, latestStartTimeArray[0], latestStartTimeArray[1], latestStartTimeArray[2]);
        }
      }
      if(this.queryData.topics?.length) {
        this.selectedTopics = this.queryData.topics.split(",");
      }
      if(this.queryData['knowledge-level']?.length) {
        this.selectedLevel = this.queryData['knowledge-level'].split(",");
      }
      if(this.queryData.pass?.length) {
        this.selectedPasses = this.queryData.pass.split(",");
      }
    }
    this.getTopics();
  }

  customDateChanged(event): void {
    this.showCustomDates = event.checked?.findIndex((option: string) => option == "custom") !== -1;

    if(!this.showCustomDates) {
      if(this.customDateRange[0] !== null && this.customDateRange[1] !== null) {
        let customDateRageIndex = this.dateRange.findIndex((range: string) => range == (this.dateToYMD(this.customDateRange[0]) + ',' + this.dateToYMD(this.customDateRange[1])));
        if(customDateRageIndex !== -1) {
          this.dateRange.splice(customDateRageIndex, 1)
        }
        this.customDateRange = [];
      }
    }

  }

  // Takes radio button values and changes them to dateTime; ex: 2022-02-24
  setStartDates(): void {
    this.dateRange = [];
    if(this.selectedDates && this.selectedDates?.length) {
      this.selectedDates.forEach((selectedDate: string) => {
        if(selectedDate !== "custom") {
          this.dateRange.push(this.dates[selectedDate]);
        } else {
          if(this.customDateRange[0] !== null && this.customDateRange[1] !== null) {
            this.dateRange.push(this.dateToYMD(this.customDateRange[0]) + ',' + this.dateToYMD(this.customDateRange[1]));
          }
        }
      })
    }
    this.showCustomDates = this.selectedDates.includes("custom");
    console.log('setStartDates:', this.dateRange);
  }

  public modelEarlyStartChange() {
    this.oldEarlyStart = this.earlyStart;
  }

  public modelLatestStartChange() {
    this.oldLateStart = this.lateStart;
  }

  setStartTime(): void {
    console.log(this.oldEarlyStart, this.earlyStart)
    if(!this.oldEarlyStart) {
      this.oldEarlyStart = new Date();
    }
    if(!this.oldLateStart) {
      this.oldLateStart = new Date();
    }

    if(this.earlyStart) {
      if(this.oldEarlyStart.getTime() < this.earlyStart.getTime()) {
        if(!(this.earlyStart.getMinutes() % 15 == 0)) {
          let dt = new Date();
          dt.setMinutes(((Math.floor(this.earlyStart.getMinutes() / 15)) * 15));
          this.earlyStart = dt;
        }
      } else {
        if(!(this.earlyStart.getMinutes() % 15 == 0)) {
          let dt = new Date();
          dt.setMinutes(((Math.ceil(this.earlyStart.getMinutes() / 15)) * 15));
          this.earlyStart = dt;
        }
      }
      let h = this.earlyStart.getHours();
      let m = this.earlyStart.getMinutes();
      this.selectedEarlyStart = (h <= 9 ? '0' + h : h)+":"+(m <= 9 ? '0' + m : m)+":00";
    }
    if(this.lateStart) {

      if(this.oldLateStart.getTime() < this.lateStart.getTime()) {
        if(!(this.lateStart.getMinutes() % 15 == 0)) {
          let dt = new Date();
          dt.setMinutes(((Math.floor(this.lateStart.getMinutes() / 15)) * 15));
          this.lateStart = dt;
        }
      } else {
        if(!(this.lateStart.getMinutes() % 15 == 0)) {
          let dt = new Date();
          dt.setMinutes(((Math.ceil(this.lateStart.getMinutes() / 15)) * 15));
          this.lateStart = dt;
        }
      }

      let h = this.lateStart.getHours();
      let m = this.lateStart.getMinutes();
      this.selectedLatestStart = (h <= 9 ? '0' + h : h)+":"+(m <= 9 ? '0' + m : m)+":00";
    }
  }
  private getFormats(): void {
    this.formats = [
      {
        key: 'webinar',
        name: 'Live Webinar',
      },
      {
        key: 'self-study',
        name: 'Self-Study',
      },
      {
        key: 'virtual+conference',
        name: 'Virtual Conference',
      },
      {
        key: 'conference',
        name: 'In-Person Conference',
      },
      {
        key: 'seminar',
        name: 'In-Person Seminar',
      }
    ];
  }
  getPasses() {
    this.passes = [
      {
        key: 'ew',
        name: 'Evening & Weekend'
      },
      {
        key: 'anytime',
        name: 'Anytime'
      },
      {
        key: 'ss',
        name: 'Self-Study'
      },
      {
        key: 'combo',
        name: 'Combo'
      }
    ];
  }
  getTimes() {
    this.times = [
      {
        key: '12',
        name: '12:00 PM'
      },
      {
        key: '1',
        name: '1:00 PM'
      },
      {
        key: '5',
        name: '5:00 PM'
      },
      {
        key: '6',
        name: '6:00 PM'
      }
    ];

  }
  getTopics(): void {
    const headers = new HttpHeaders().set("Content-Type", "application/vnd.api+json");
    this.topicService.getTopics().subscribe((data) => {
      this.topics = data;
    });
  }

  getCitiesAndStates(): void {
    this.searchService.getCriteriaOptions().subscribe((items) => {
      let citiesAndStates: ListBoxOption[] = [];
      if(items.locations?.length) {
        items.locations.forEach((val: Locations) => {
          citiesAndStates.push({
            name: val.city+", "+val.state,
            code: val.city+", "+val.state
          });
        })
        this.citiesAndStates = citiesAndStates;
      }
    });



  }

  private getCreditHours(): void {
    this.creditHours = [
      {
        key: '2.0',
        name: '2.0 hours or less',
      },
      {
        key: '2.5-4.0',
        name: '2.5 to 4.0 hours',
      },
      {
        key: '4.5-7.5',
        name: '4.5 to 7.5 hours',
      },
      {
        key: '8.0-9.5',
        name: '8.0 to 9.5 hours',
      },
      {
        key: '10-50',
        name: '10+ hours',
      },

    ];
  }

  customCreditHoursChanged(event): void {
    this.showCustomCredits =  event.checked?.findIndex((option: string) => option == "custom") !== -1;
    if(!this.showCustomCredits) {
      this.creditRangeMin = "";
      this.creditRangeMax = "";
    }
  }

  private getPriceRanges(): void {
    this.priceRanges = [
      {
        key: '0-79',
        name: '$0-$79',
      },
      {
        key: '80-149',
        name: '$80-$149',
      },
      {
        key: '150-299',
        name: '$150-$299',
      },
      {
        key: '300-799',
        name: '$300-$799',
      },
      {
        key: '800-5000',
        name: '$800+',
      },

    ];
  }

  customPriceRangeChanged(event): void {
    this.showCustomPrice = event.checked?.findIndex((option: string) => option == "custom") !== -1;
    if(!this.showCustomPrice) {
      this.priceRangeMin = "";
      this.priceRangeMax = "";
    }
  }

  private getSearchTopics(): void {
    let searchTopicsList = this.topicService.codesAndCategories();

    // If an item has a display with category set to 'none' then don't include it in the list
    searchTopicsList.forEach( (item, index) => {
      if (item.descriptionWithCategory === 'none') {
        searchTopicsList.splice(index,1);
      }
    })

    //Sort topics alphabetically
    this.searchTopicsList = searchTopicsList.sort(function (a, b) {
      if (a.descriptionWithCategory < b.descriptionWithCategory) {
        return -1;
      }
      if (a.descriptionWithCategory > b.descriptionWithCategory) {
        return 1;
      }
      return 0;
    });

    //Move "State-Specific Ethics" topic at top
    this.searchTopicsList = this.searchTopicsList.sort(function (x, y) {
      return x.descriptionWithCategory == "State-Specific Ethics" ? -1 :
        y.descriptionWithCategory == "State-Specific Ethics" ? 1 : 0;
    })
  }
  clearFilters(): void {
    this.searchCredits = '';
    this.priceRange = '';
    this.selectedCredits = [];
    this.selectedCost = [];
    this.dateRange = [];
    this.selectedDates = [];
    this.showCustomDates = false;
    this.customDateRange = undefined;
    this.selectedFormats = [];
    this.earlyStart = undefined;
    this.lateStart = undefined;
    this.selectedTopics = [];
    this.selectedLevel = [];
    this.selectedPasses = [];
    this.selectedCities = [];

    this.showAllFilterExpanded = true;
    this.queryData = this.textSearch ? { textSearch: this.textSearch } : {};
    if(this.utmResource) {
      this.queryData['utm_source'] = this.utmResource;
    }
    this.router.navigate(['/search-results'], {
      queryParams: this.queryData,
    });
  }
}
