export const environment = {
  production: true,
  baseUrl: 'https://dcdev.cpeonline.com/cms',
  jsonApiUrl: 'https://dcdev.cpeonline.com/cms/jsonapi',
  cpeApiUrl: 'https://dcdev.cpeonline.com/cms/cpe_api',
  pageNotFoundUrl: '/let-us-help-you-0',
  buildId: '0.0.80',
  devOptions: false,
  enableErrorReporting: true,
  // Jared's DEV Workstation
  maxIdleSeconds: 3000,
  idleWarningDurationSeconds: 120,
  appConfig: {
    someParameter: 0, // Set to validate max plan duration (ex: 23760000000 = 275 days in milliseconds)
    webinars: {
      search: true
    }
  }
};
