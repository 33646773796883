<div id="cpe-passes" class="cpe-container cpe-passes pt-6">
    <div id="CpePasses" class="cpe-wrapper">
        <!-- <h1>Year-Long CPE Passes</h1>
        <h3>More Programs. More Flexibility. More Savings.</h3> -->

        <div class="grid">
            <div class="col-12">
                <div class="grid head">
                    <div class="col-4 head-top-menu"></div>
                    <div class="col-2 pass-box acive-box">
                        <svg class="arrowBox " viewBox="0 0 100 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <polygon stroke="rgb(0, 97, 184)" fill="#f4f4f4" stroke-width="4" points="2 2, 98 2, 98 105, 50 115, 2 105"></polygon>
                        </svg>
                       <div class="info-box">
                        <div class="title-pass">Most Support</div>
                        <div class="pass-widget-image self-icon my-2"></div>
                        <span class="evening">Evening & Weekend</span>
                        <p class="ac-prize">
                            $399
                            <span class="round"></span>
                            $699</p>
                        <!-- <p class="old-prize">$399</p> -->
                        <div class="action-buttons flex flex-wrap align-items-center justify-content-center card-container">
                            <p-button label="BUY 20 Hrs • $399" styleClass="m-1 p-1 white-space-nowrap vertical-align-baseline"></p-button>
                            <p-button label="BUY 40 Hrs • $699" styleClass="m-1 p-1 white-space-nowrap vertical-align-baseline"></p-button>
                        </div>
                       <!-- <div class="btn-btn-gp">
                        <button class="cart-pass">BUY 20 HRS $399</button>
                        <button class="cart-pass">BUY 40 HRS $699</button>
                       </div> -->
                       </div>
                    </div>
                    <div class="col-2 pass-box acive-box">
                        <svg class="arrowBox " viewBox="0 0 100 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <polygon stroke="rgb(0, 97, 184)" fill="#f4f4f4" stroke-width="4" points="2 2, 98 2, 98 105, 50 115, 2 105"></polygon>
                        </svg>
                        <div class="info-box">
                            <div class="title-pass">Most Support</div>
                            <div class="pass-widget-image anytime-icon my-2"></div>
                            <span class="evening">Anytime</span>
                            <p class="ac-prize">$499
                                <span class="round"></span>
                                $850</p>
                            <div class="action-buttons flex flex-wrap align-items-center justify-content-center card-container">
                                <p-button label="BUY 20 Hrs • $499" styleClass="m-1 p-1 white-space-nowrap vertical-align-baseline"></p-button>
                                <p-button label="BUY 40 Hrs • $850" styleClass="m-1 p-1 white-space-nowrap vertical-align-baseline"></p-button>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 pass-box acive-box non-active">
                        <svg class="arrowBox " viewBox="0 0 100 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <polygon stroke="rgb(0, 97, 184)" fill="#f4f4f4" stroke-width="4" points="2 2, 98 2, 98 105, 50 115, 2 105"></polygon>
                        </svg>
                        <div class="info-box">
                            <div class="title-pass">Most Support</div>
                            <div class="pass-widget-image self-icon my-2"></div>
                            <span class="evening-combo">Self-Study</span>
                            <p class="ac-prize">$550</p>
                            <div class="action-buttons flex flex-wrap align-items-center justify-content-center card-container">
                                <p-button label="BUY 40 Hrs • $550" styleClass="m-1 p-1 white-space-nowrap vertical-align-baseline"></p-button>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 pass-box acive-box non-active">
                        <svg class="arrowBox " viewBox="0 0 100 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <polygon stroke="rgb(0, 97, 184)" fill="#f4f4f4" stroke-width="4" points="2 2, 98 2, 98 105, 50 115, 2 105"></polygon>
                        </svg>
                        <div class="info-box">
                            <div class="title-pass">Most Support</div>
                            <div class="pass-widget-image anytime-icon my-2"></div>
                            <span class="evening-combo">Combo</span>
                            <p class="ac-prize">$975</p>
                            <div class="action-buttons flex flex-wrap align-items-center justify-content-center card-container">
                                <p-button label="BUY 40 Hrs • $975" styleClass="m-1 p-1 white-space-nowrap vertical-align-baseline"></p-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 content-full">
                <div class="grid">
                    <div class="col-4">Valid for</div>
                    <div class="col-2 even-color">1 Year from Date of Purchase</div>
                    <div class="col-2">1 Year from Date of Purchase</div>
                    <div class="col-2 even-color">1 Year from Date of Purchase</div>
                    <div class="col-2">1 Year from Date of Purchase</div>
                </div>
            </div>
            <div class="col-12 content-full">
                <div class="grid">
                    <div class="col-4">Includes</div>
                    <div class="col-2 even-color">Evening & Weekend Webinars</div>
                    <div class="col-2">Day, Evening & Weekend Webinars</div>
                    <div class="col-2 even-color">Webinars & Self-Study Programs</div>
                    <div class="col-2">Webinars & Self-Study Programs</div>
                </div>
            </div> 
            <div class="col-12 content-full">
                <div class="grid">
                    <div class="col-4">Available Courses</div>
                    <div class="col-2 even-color">100+</div>
                    <div class="col-2">250+</div>
                    <div class="col-2 even-color">140+</div>
                    <div class="col-2">390+</div>
                </div>
            </div>
            <div class="col-12 content-full">
                <div class="grid">
                    <div class="col-4">State-Specific Ethics</div>
                    <div class="col-2 even-color">30+</div>
                    <div class="col-2">30+</div>
                    <div class="col-2 even-color">30+</div>
                    <div class="col-2">60+</div>
                </div>
            </div>
        </div>
        <div class="grid">
            <div class="col-12 font-italic pt-4 text-left">
                Excludes: Financial Accounting & Reporting Update for Corporate Accountants; SEC Accounting & Reporting: A Two-Day Boot Camp; and all Conferences and Boot Camps.
            </div>
            <div class="col-12 pt-6 pl-6 pr-6 font-bold"><span class="text-red-500">20% OFF Sitewide!</span> Use Ref Code 20SUMMER</div>
            <div class="col-12 pl-6 pr-6 font-bold">(Discount not valid on specials, or in combination with any other discounts. Some exclusions apply.)</div>
            <div class="col-12 pl-6 pr-6">Your Annual Pass will automatically renew upon expiration of your current Pass. See <a href="">Terms & Conditions</a>for details on how to manage your Annual Pass and opt out of auto-renewal.</div>
        </div>
    </div>
</div>