<div class="cpe-container" id="courseStateList">
    <div class="cpe-wrapper">
        <div class="grid">
            <div class="col-12">
                <p class="w-full font-bold text-justify">Find out why so many successful financial professionals choose CPE Inc. for their continuing professional education needs.</p>
                <p class="w-full text-justify">From state-specific ethics courses to the latest accounting rules and regulations, CPE Inc. has been the leader in helping accounting and financial professionals obtain their CPE credits and fulfill their CPE requirements for over three decades.</p>
                <p class="w-full text-justify">Led by the best instructors in the business, CPE Inc. offers the widest selection of NASBA-approved CPE courses for tax, accounting, finance and auditing specialists. Whether you’re a beginner or an expert, or just need to catch up on your CPE credit requirements, you’ll find the topic and skill set that’s right for you.</p>
                <p class="w-full text-justify">Live Webinars, Self-Study Courses, Conferences and Seminars—choose the format you prefer and get the most informative training available anywhere.</p>
                <p class="w-full text-justify">Click on your state below and start learning today…</p>
            </div>
            <div class="col-12">
                <h1>CPE Courses By State</h1>
                <p *ngFor="let state of stateList">
                    <a pButton [label]="state.linkName" class="font-bold p-0 border-none " icon="pi pi-angle-right" iconPos="right" [routerLink]="[state.url]"></a>
                </p>
            </div>
        </div>
    </div>
</div>
