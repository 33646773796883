import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(data: Array<any>, searchTxt: string | boolean, searchKey: string): Array<any> {
        return data ? data.filter((value, index) => {
            if(!searchTxt) {
                return data[index];
            }
            if(typeof searchTxt == "boolean") {
                if (value[searchKey] == searchTxt) {
                    return data[index];
                }
            } else {
                if (value[searchKey].toUpperCase().indexOf(searchTxt.toUpperCase()) > -1) {
                    return data[index];
                }
            }
        }) : [];
    }
}