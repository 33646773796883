import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;
  private siteKey = '6Le18gsTAAAAAF9si16eca8mzy4wajYrHyRgHsEn';

  constructor(private fb: FormBuilder, private http: HttpClient) {}

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required],
      recaptcha: [null, Validators.required],
    });
  }

  get getSiteKey() {
    return this.siteKey;
  }

  onSubmit() {
    // TODO: Submit form to contact us API
    const url = environment.jsonApiUrl + '/contact-us';
    const payload = this.contactForm.value;
    this.http.post(url, payload);
  }
}
