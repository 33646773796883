import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from 'src/app/_services/cart.service';
import { ShoppingCart } from 'src/shopping-cart.model';
import {isPlatformServer} from '@angular/common';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-account-registration-email-template',
  templateUrl: './account-registration-email-template.component.html',
  styleUrls: ['./account-registration-email-template.component.scss'],
})
export class AccountRegistrationEmailTemplateComponent implements OnInit {

  isServer = isPlatformServer(this.platformId);

  @Input() id: number | string;

  billingAddress = {
    name: 'Joe Smith',
    company: 'Test Company',
    address: '123 Test Street',
    city: 'Test City',
    state: 'NJ',
    zipcode: '12345',
    officePhone: '1234567890',
    cellPhone: '0987654321',
    homePhone: '1029384756',
    email: 'billing@address.com',
  };

  homeAddress = {
    name: 'Joe Smith',
    company: 'Test Company',
    address: '123 Test Street',
    city: 'Test City',
    state: 'NJ',
    zipcode: '12345',
    email: 'home@address.com',
  };

  workAddress = {
    name: 'Joe Smith',
    company: 'Test Company',
    address: '123 Test Street',
    city: 'Test City',
    state: 'NJ',
    zipcode: '12345',
    employeeID: 'A123',
    email: 'work@address.com',
  };

  subtotal = '$396.00';
  registrationGrandTotal = '$396.00';
  paymentMethod = 'Credit Card';

  registrationInfo = {
    registrationNum: '99900002',
    registrationDate: '12/07/2022 1:25 PM',
    programsSubtotal: '$1,396.00',
    manualDollarAdjustments: '-$1,000.00',
    customerRequestedDiscount: '$0.00',
    registrationTotal: '$396.00',
  };

  passes = [
    {
      name: '20 Hour E&W Webinar Pass',
      referenceCode: 'ABC12331',
      description: 'Here is the description of the pass.',
      attendees: [
        { firstName: 'Joe', lastName: 'Smith', email: 'joesmith@email.com' },
        { firstName: 'Joe', lastName: 'Smith', email: 'joesmith@email.com' },
      ],
    },
  ];

  programsRegistered = [
    {
      count: 2,
      name: 'A Valuation Primer for Financial Reporting Webinar',
      totalCost: '$598.00',
      individualCost: '$299.00',
      courseID: 'WA38938562XSAI',
      courseDate: '01/01/2023 8:30 AM',
      location: 'Building 305',
      attendees: [
        { firstName: 'Joe', lastName: 'Smith', email: 'joesmith@email.com' },
        { firstName: 'Joe', lastName: 'Smith', email: 'joesmith@email.com' },
      ],
    },
    {
      count: 2,
      name: 'A Valuation Primer for Financial Reporting Webinar',
      totalCost: '$598.00',
      individualCost: '$299.00',
      courseID: 'WA38938562XSAI',
      courseDate: '01/01/2023 8:30 AM',
      location: 'Building 305',
      attendees: [
        { firstName: 'Joe', lastName: 'Smith', email: 'joesmith@email.com' },
        { firstName: 'Joe', lastName: 'Smith', email: 'joesmith@email.com' },
      ],
    },
  ];

  currentRoute: string;
  orderDetails: ShoppingCart = undefined;
  orderDetailsLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cartService: CartService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {
    this.currentRoute = this.router.url;
    let orderId: number = this.id ? this.id : this.route.snapshot.queryParams['id'];

    this.orderDetailsLoading = true;

    console.log(`this.cartService.getOrderDetailsUsingRender generating for order ${orderId}`);

    this.cartService.getOrderDetailsUsingRender(orderId).subscribe( {
      next: (cartData: ShoppingCart) => {
        this.getOrderDetailsHandler(cartData);
        this.orderDetailsLoading = false;
      },
      error: (error) => {
        // console.log(`this.cartService.getOrderDetailsUsingRender error`, error);

      },
      complete: () => {
        // console.log(`this.cartService.getOrderDetailsUsingRender complete`);
      }

    });


  }

  getOrderDetailsHandler(cartData: ShoppingCart) {
    {
      console.log(`account-registration getOrderDetails() processing`);

      this.orderDetails = cartData;
      this.orderDetails.orderDate = this.formatRegistrationSummaryDate(this.orderDetails.orderDate);
      this.orderDetails.lineItems.forEach((lineItem, lineItemIndex) => {
        let dateTimestamp: number = +lineItem.courseDate;
        let format = 'ccc, MMM dd, yyyy • t';
        let fromDateformatted = DateTime.fromSeconds(dateTimestamp).setLocale('en-US').toFormat(format);
        this.orderDetails.lineItems[lineItemIndex]['formattedDate'] = fromDateformatted;
      });

      this.orderDetailsLoading = false;
    }
  }

  formatRegistrationSummaryDate(input: string): string {
    // Split the input string by dashes and commas to extract date components
    console.log(`formatRegistrationSummaryDate: input ${input}`);
    try {
      const parts = input.split(/-|, /); // ['2024', '02', 'Feb 5', '2024']
      if(parts.length == 4) {
        const month = parts[1]; // '02'
        const day = parts[2].split(' ')[1]; // Split 'Feb 5' to get '5'
        const year = parts[0]; // '2024'
  
        // Construct a new date object
        const date = new Date(`${year}-${month}-${day}`);
  
        // Format the date as MM/DD/YYYY
        const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
        return formattedDate;
      }
      return input;
    } catch (error) {
      console.error(`formatRegistrationSummaryDate: invalid date ${input}`);
      return '';
    }

  }

}
