import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessData, SessionService } from '../_services/session.service';
import { Subscription } from 'rxjs';
import { TopicService } from '../_services/topic.service';

@Component({
  selector: 'app-course-state-list',
  templateUrl: './course-state-list.component.html',
  styleUrls: ['./course-state-list.component.scss']
})
export class CourseStateListComponent implements OnInit, OnDestroy {
  
  private subs: Subscription[] = [];
  public sessData: SessData | null;
  public stateList: any[] = [];

  constructor(
    private sessionService: SessionService,
    private topicService: TopicService
  ) { }

  ngOnInit(): void {
    // Get current session data
    // this.sessionService.sessData['currentPage']['heading'] = 'CPE Courses For Your State Requirements';
    // this.sessionService.setSessData(this.sessData)
    this.getCourseStateList();
  }

  private getCourseStateList(): void {
    this.topicService.getCourseStateList().subscribe((data) => {
      if(Object.keys(data).length) {
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            this.stateList.push(data[key]);
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
    for (let i = 0; i < this.subs.length; i++) {
      this.subs.pop();
    }
  }
}
