<!-- Test URL -->
<!-- http://localhost:4200/render-api/registration-email?id=2 -->

<div style="width: 95%; margin: auto;background: #ffffff;border: solid 1px #006699;">
  <table style="width: 99%;margin: auto;" *ngIf="orderDetails">
    <tr>
      <td colspan="3">
        <div style="color: white; background-color: #006699">
          <h2 style="padding-left: 5px;">Registration Information</h2>
        </div>
      </td>
    </tr>
    <tr>
      <td style="width: 33%;">
          <p>
            <strong>Billing Address ({{ orderDetails.billingAddress.addressPurpose }}):</strong><br />
            {{ orderDetails.billingAddress.firstName }} {{ orderDetails.billingAddress.lastName }}<br />
            {{ orderDetails.billingAddress.company }}<br />
            {{ orderDetails.billingAddress.street1 }} {{ orderDetails.billingAddress.street2 }}<br />
            {{ orderDetails.billingAddress.city }}, {{ orderDetails.billingAddress.state }}
            {{ orderDetails.billingAddress.zip }}, {{ orderDetails.billingAddress.country }}
          </p>
          <p>
            <strong>Phone:</strong> {{ orderDetails.billingAddress.phone }} ({{ orderDetails.billingAddress.phonePurpose }})<br />
          </p>
      </td>
      <td style="width: 33%;">
          <p>
            <strong>Contact Address:</strong><br />
            {{ orderDetails.contactAddress.firstName }} {{ orderDetails.contactAddress.lastName }}<br />
            {{ orderDetails.contactAddress.company }}<br />
            {{ orderDetails.contactAddress.street1 }} {{ orderDetails.contactAddress.street2 }}<br />
            {{ orderDetails.contactAddress.city }}, {{ orderDetails.contactAddress.state }}
            {{ orderDetails.contactAddress.zip }}, {{ orderDetails.contactAddress.country }}
          </p>
          <p><strong>Email Address:</strong> {{ orderDetails.contactAddress.email }}</p>
      </td>
      <td style="width: 33%;vertical-align: top;">
          <table style="width: 100%;">
            <tr>
              <td style="width: 50%;"><strong>Registration Total:</strong></td>
              <td style="width: 50%;">{{ orderDetails.totalDue | currency: 'USD':'symbol':'1.2-2'}}</td>
            </tr>
            <tr>
              <td style="width: 50%;"><strong>Payment Method:</strong></td>
              <td style="width: 50%;">{{ orderDetails?.paymentMethod?.displayName }}</td>
            </tr>
          </table>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <div style="color: white; background-color: #006699">
          <h2 style="padding-left: 5px;">Registration Summary</h2>
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <p>
          <strong>Web Order ID#: </strong>{{ orderDetails.orderId }}
          <br />
          <strong>Registration Date: </strong>{{ orderDetails.orderDate }}
          <br />
          <strong>Programs Subtotal: </strong>{{ orderDetails.extTotal  | currency: 'USD':'symbol':'1.2-2' }}
        </p>

        <span style="width: 20%;display: inline-block;">
          <hr />
        </span>

        <p>
          <strong>Total for this Registration:</strong> {{ orderDetails.totalDue | currency: 'USD':'symbol':'1.2-2' }}
        </p>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <h4>Programs on Registration</h4>
        <ul style="list-style: none;">
          <li *ngFor="let cartItem of orderDetails?.lineItems; let i = index">
            <table style="width: 100%;">
              <tr>
                <td style="width: 10%;vertical-align: top;padding-top: 1rem;">
                  <strong>{{ cartItem.isAttending ? (cartItem.attendees?.length + 1) : cartItem.attendees?.length }} x</strong>
                </td>
                <td style="width: 90%; text-align: left;">
                  <p>
                    <strong>{{ cartItem.name }} - {{ cartItem.totalCost | currency: 'USD':'symbol':'1.2-2'}}</strong>
                    ({{ cartItem.cost | currency: 'USD':'symbol':'1.2-2'}} each)<br />
                    Course ID: {{ cartItem.courseID }}<br />
                    Course Type: {{ cartItem?.courseType }}<br />
                    <span *ngIf="cartItem?.courseType == 'seminar' || (cartItem?.courseType?.indexOf('webinar') >= 0)">
                          Course Date: {{ cartItem['formattedDate'] | CPEFormattedDateOnly }} <span style="font-weight: 800;">&middot;</span> {{ cartItem['formattedDate'] | CPEFormattedTimeOnly }} <br />
                    </span>
                    <span *ngIf="cartItem?.courseType == 'seminar'">Location: {{ cartItem.location }}<br /></span>
                  </p>
                  <p>Attendees:</p>
                  <ul>
                    <li *ngIf="cartItem.isAttending">{{ orderDetails.contactAddress?.firstName ? (orderDetails.contactAddress?.firstName +" "+ orderDetails.contactAddress?.lastName) : 'You' }}</li>
                    <li *ngFor="let attendee of cartItem.attendees">
                      {{ attendee.firstName }} {{ attendee.lastName }} ({{attendee.email}})
                      <span *ngIf="attendee?.licenseInformation?.stateLicensedCPA">
                        <br>
                        {{ attendee.licenseInformation.state }} licensed CPA until {{ attendee.licenseInformation.expiration }}
                        <span *ngIf="attendee.licenseInformation.state == 'NJ'">
                          <br>
                          NJ State License Number : {{ attendee.licenseInformation.njStateLicenseNumber}}
                        </span>
                      </span>
                    </li>
                  </ul>
                </td>
              </tr>
            </table>
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <hr />
        <ng-template *ngTemplateOutlet="terms"></ng-template>
      </td>
    </tr>
  </table>
</div>

<ng-template #terms>
  <p>
    <i>
      We will determine final discounts when we process your
      registration. Therefore, actual discounts may be greater than
      or less than the amounts listed here.
    </i>
    <br />
    <i>
      Registration totals are for informational purposes only. If
      you are using a credit card to make your purchase, your card
      will be charged separately for each item in your registration.
    </i>
    <br />
    <i>
      If you need assistance, please call Customer Service at
      1-800-544-1114 or email us at info&#64;cpeonline.com.
    </i>
  </p>
</ng-template>
