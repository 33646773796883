import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, merge, Observable, ReplaySubject, Subject, throwError} from 'rxjs';
import {tap, map, catchError, switchMap, scan} from 'rxjs/operators';
import {TopicResource, TopicsResource, SimpleTopic} from '../../topic';
import {SimpleCourse, CoursesResource, CourseResource, CourseDetail} from '../../course';
import {environment} from "../../environments/environment";

/**
 * Describes what type a topic is, the category it belongs to,
 * and what the description of that category is.
 */
export interface CategoryEntry {
  type: string;
  category: string;
  description: string;
  descriptionWithCategory: string;
}

export interface Format {
  type: string;
  title: string;
  uuid: string;
  field_canonical_url?: string;
}

export interface Review {
  author: string;
  location: string;
  nid: string;
  rating: string;
  text: string;
}

export interface Date {
  label: string,
  date: string,
  // This is an optional date sometimes included for troubleshooting
  dateYYYYMMDD?: string,
  uuid: string,
  dateEnd: string,
  ew?: string
  location?: string
  courseID?: string
  location_details?: CourseLocation
}

export interface CourseLocation {
  field_address: string,
  field_city: string,
  field_country_code: string,
  field_fax: string,
  field_location_name: string,
  field_phone: string,
  field_state: string,
  field_zip: string
}
export interface CourseDetails {
  formats: Format[];
  entity_type: string;
  dates: Date[];
  reviews: Review[];
  entity: CourseDetail;
  course: SimpleCourse;
  topic: SimpleTopic;
  passes: string[];
  related: [SimpleCourse];
  locations?: CourseLocation[]
}

export interface Section {
  key: string;
  label: string;
  cards: any;
  categories?: any[];
}

export interface InDemandCourses {
  rawCount: number;
  sectionKeys: any[];
  sections: Section[];
  categories: any[];
  cards: any;
}
export interface UpcomingConferences {
  rawCount: number;
  cards: any[];
}

export interface HomeData {
  inDemand: InDemandCourses;
  upcomingConferences: UpcomingConferences;
}

function Identity<T>(value: T) {
  return value;
}

function reload(selector: Function = Identity) {
  return scan((oldValue, currentValue) => {
    if (!oldValue && currentValue)
      throw new Error(`Reload can't run before initial load`);

    return selector(currentValue || oldValue);
  });
}

export function combineReload<T>(
  valueObs: Observable<T>,
  reloadObs: Observable<void>,
  selector: Function = Identity
): Observable<T> {
  return merge(valueObs, reloadObs).pipe(
    reload(selector),
    map((value: any) => value as T)
  );
}

@Injectable({ providedIn: 'root' })
export class TopicService {
  topicsResource: TopicsResource | null = null;
  topicResource: TopicResource | null = null;
  topics: any;
  topic: any;
  public reload$ = new Subject<void>();
  public entity$ = new ReplaySubject<string>(1);
  courseDetails!: CourseDetails;

  private courseDetailsSource = new BehaviorSubject(this.courseDetails);
  currentCourseDetails = this.courseDetailsSource.asObservable();

  // A cached array of objects which contain the topics and their different types.
  // Generated on first use by CodesAndCategories()
  private cachedCodesAndCategories: CategoryEntry[];

  detailObs: Observable<CourseDetails> = combineReload<string>(
    this.entity$,
    this.reload$
  ).pipe(
    switchMap((entity: string) => {
      return this.getCourseDetail(entity);
    })
  );
  constructor(
    private http: HttpClient
  ) {}

  setEntity(uuid: string): void {
    this.entity$.next(uuid);
  }
  reload(): void {
    this.reload$.next(undefined);
  }

  getTopic(uuid: string) {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/vnd.api+json");

    this.http.get<any>(environment.jsonApiUrl + '/node/course/' + uuid, {headers}).subscribe((data) => {
      const normalizedTopic = this.normalizeTopic(data['data'])
      //this.topic = normalizedTopic;
      return normalizedTopic;
    });
  }
  getTopics() {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/vnd.api+json");

    return this.http.get<TopicsResource>(environment.jsonApiUrl + '/node/topic', {headers})
      /*
      .subscribe((data) => {
      this.topicsResource = data;
      for (let i = 0; i < this.topicsResource.data.length; i++) {
        var title = this.normalizeTitle(this.topicsResource.data[i].attributes.title);
        this.topicsResource.data[i].attributes.title = title;
        this.topics[i] = this.normalizeTopic(this.topicsResource.data[i]);
      }
      return this.topics;
          });

       */
    .pipe(
      map((item: TopicsResource) => {
        return this.normalizeTopics(item.data);
      })
    );
  }

  getAllCourseCodes(page: number, searchParams: any) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/vnd.api+json'
    );
    // const url = environment.jsonApiUrl + `/${userID}` + '/course-codes';
    const url = environment.baseUrl + '/cpe-api/admin/course-codes';
    let params = {page: page};
    if(searchParams['search_topic']){
      params['topic'] = searchParams['search_topic'];
    }
    if(searchParams['search_name']){
      params['name'] = searchParams['search_name'];
    }
    if(searchParams['search_email']){
      params['email'] = searchParams['search_email'];
    }
    return this.http.get(url, {headers: headers, params: params});
  }

  getCourseCodes(userID: string) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/vnd.api+json'
    );
    const url = environment.jsonApiUrl + `/${userID}` + '/course-codes';
    return this.http.get(url, {headers});
  }

  getCourseDetail(uuid: string) {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/vnd.api+json");

    return this.http.get<CourseDetails>(environment.baseUrl + '/cpe_api/course_detail?uri=' + uuid, {headers})
    /*
    .subscribe((data) => {
      //const normalizedTopic = this.normalizeTopic(data['data']['entity']);
      //this.topic = normalizedTopic;
      return data['data'];
    }) */
    .pipe(
      map((item: any) => {

        //If there is no related courses then API returns object {"content": "[]","headers": {"X-Sub-Content-Type": null} } so lets check length
        if(!item?.related?.length) {
          item.related = [];
        }
        if(item?.related?.length) {
          item.related
          item.related = item.related.sort((a, b) => {
            let [aTitle, aFormat] = [a.field_canonical_title, a.field_course_type];
            let [bTitle, bFormat] = [b.field_canonical_title, b.field_course_type];

            return (aTitle+" "+aFormat).localeCompare(bTitle+" "+bFormat);
          });

          for (let index = 0; index < item.related.length; index++) {
            const course = item.related[index];
            if(course['field_hours']) {
              item.related[index]['field_hours'] = this.normalizeHours(course.field_hours);
            }
            if(item.related[index]['field_price']) {
              item.related[index]['field_price'] = Number(course.field_price);
            }
            if(item.related[index]['field_canonical_url']) {
              item.related[index]['field_canonical_url'] = item.related[index]['field_canonical_url'].split("https://www.cpeonline.com")[1]
            }
          }
        }
        if(item?.formats?.length) {
          for (let formatIndex = 0; formatIndex < item.formats.length; formatIndex++) {
            if(item.formats[formatIndex]['field_canonical_url']) {
              item.formats[formatIndex]['field_canonical_url'] = item.formats[formatIndex]['field_canonical_url'].split("https://www.cpeonline.com")[1]
            }
          }
        }
        return item;
      })
    );
  }
  setCourseDetailsSource(courseDetails: CourseDetails): void {
    this.courseDetailsSource.next(courseDetails);
  }

  getHomeData() {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/vnd.api+json");

    return this.http.get<HomeData>(environment.baseUrl + '/cpe_api/home_page', {headers})
      .pipe(
        tap(res => {
          // console.log('topic.service:getHomeData(): ', res);
        }),
      )
      .pipe(
        map((rawData: HomeData) => {
          let processed: any = Object;

          // Process In-Demand Courses data
          let inDemand = JSON.parse(JSON.stringify(rawData.inDemand));
          processed.inDemand = inDemand;
          let normalized: SimpleTopic | SimpleCourse;
          let sectionKeys = Object.keys(inDemand.sections);
          let sectionValues: Section[] = Object.values(inDemand.sections);
          processed.inDemand.sections = [];

          for(const s_key in sectionValues) {
            let cards = [];
            let sectionObj = sectionValues[s_key];
            if(sectionObj.cards && sectionObj.cards.length > 0) {

            for (const c_key in sectionObj.cards) {
              let cardObj = sectionObj.cards[c_key];
              if (cardObj.hasOwnProperty('field_startdatetime')) {
                // This is a course
                normalized = this.normalizeCourse(cardObj);
                //normalized.field_section_tag = item.field_section_tag;
                cards.push(normalized);
              } else if (cardObj.hasOwnProperty('field_canonical_title')) {
                // This be a topic

                // Need to include the field_section_tag
                normalized = this.normalizeTopic(cardObj);
                //normalized.field_section_tag = cardObj.field_section_tag;
                cards.push(normalized);
              } else {
                // Error?
                console.error('Card is not a course or topic: ', cardObj);
              }

            };// foreach.cards
            sectionObj.cards = cards;

            // Filter the categories
            // Need to filter the local array of categories by the ones present in the data.
            sectionObj.categories = this.codesAndCategories().filter((category) => {
              return sectionObj.categories.includes(category.category);
            });

            processed.inDemand.sections.push(sectionObj);
            processed.inDemand.sectionKeys = sectionKeys;

            } else {
              // There are no cards for this section

            }
          }; // foreach.sections
          // Process Upcoming Conferences data
          processed.upcomingConferences = rawData.upcomingConferences;
          let cards = [];
          // console.log('Conferences ', rawData.upcomingConferences.cards)
          for(const c_key in rawData.upcomingConferences.cards) {
            let cardObj = rawData.upcomingConferences.cards[c_key];
            normalized = this.normalizeCourse(cardObj);
            cards.push(normalized);
          }
          processed.upcomingConferences.cards = cards;
          return processed;

        }),
      )
  }

  getCourseStateList() {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/vnd.api+json'
    );
    const url = environment.cpeApiUrl + '/states-courses';
    return this.http.get(url, {headers});
  }

  getCoursesByState(state_name: string) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/vnd.api+json'
    );
    const url = environment.cpeApiUrl + '/states-courses/'+state_name;
    return this.http.get(url, {headers});
  }

  getGeneralPageContent(page_name: string) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/vnd.api+json'
    );
    const params = {
      "filter[canonical][path]": "field_canonical_url",
      "filter[canonical][value]": "/"+page_name,
      "filter[canonical][operator]": "=",
    }
    const url = environment.jsonApiUrl + '/node/page';
    return this.http.get(url, {headers: headers, params: params }).pipe(
      map((item: TopicsResource) => {
        return this.normalizeTopics(item.data);
      })
    );;
  }

  getCourseType(type: string) {
    switch(type){
      case "webcast":
      case "webinar":
        return "WEBINAR"
      case "self-study webcast":
      case "self-study webinar":
        return "SELF-STUDY WEBINAR"
      case "workshop":
        return "WORKSHOP";
      case "seminar":
        return "SEMINAR";
      case "conference":
        return "CONFERENCE";
      case "self-study":
        return "SELF-STUDY"
      case "self-study conference":
        return "SELF-STUDY CONFERENCE"
      default:
        return "COURSE OR TOPIC";
    }
  }
  /*
  We don't need everything that the JSON:API resource returns in the TopicResource or CourseResource objects.
  I'd rather simplify them down to just what we need for our displays
   */
  normalizeTopic(resource) {
    /*
    We have 2 types of objects coming from the server.
    1. JSON:API data comes from the JSON:API
    2. The custom REST resources return a simplified node object based on Drupal\Core\Field\FieldDefinitionInterface[]
     */
    let adaptedTopic: SimpleTopic;
    if(resource.hasOwnProperty('attributes')) {
      // Must be from JSON:API
      adaptedTopic = {
        type: resource.type,
        id: resource.id,
        field_nid: resource.attributes.field_nid,
        title: this.normalizeUrl(resource.attributes.title),
        field_canonical_title: resource.attributes.field_canonical_title,
        field_canonical_url: this.normalizeCanonicalUrl(resource.attributes?.field_canonical_url),
        field_emphasis: resource.attributes.field_emphasis,
        field_objective: resource.attributes.field_objective,
        field_knowledge_level: resource.field_knowledge_level,
        field_overview: resource.attributes.field_overview,
        field_category_ids: resource.attributes.field_category_ids,
        field_exclude_from_passes: this.getBoolean(resource.attributes.field_exclude_from_passes),
        field_hours: this.normalizeHours(resource.attributes.field_hours),
        field_price: resource.attributes.field_price,
        field_short_description: resource.attributes.field_short_description,
        field_topic_id: resource.attributes.field_topic_id,
        field_nasba_category_name: resource.attributes.field_nasba_category_name,
        field_section_tag: resource.field_section_tag,
        field_course_type: null,
        field_learning_objectives: resource?.field_learning_objectives || null,
        field_detailed_objectives: resource?.field_detailed_objectives || null,
        field_prerequisite: resource?.field_prerequisite || null,
        body: resource?.attributes.body
      }
    } else {
      // Must be coming from REST
      adaptedTopic = {
        type: 'node--topic',
        id: resource.uuid,
        field_nid: resource.nid,
        title: this.normalizeUrl(resource.title),
        field_canonical_title: resource.field_canonical_title,
        field_canonical_url: this.normalizeCanonicalUrl(resource?.field_canonical_url),
        field_emphasis: resource.field_emphasis,
        field_knowledge_level: resource.field_knowledge_level,
        field_objective: resource.field_objective,
        field_overview: resource.field_overview,
        field_category_ids: resource.field_category_ids,
        field_exclude_from_passes: this.getBoolean(resource.field_exclude_from_passes),
        field_hours: this.normalizeHours(resource.field_hours),
        field_price: resource.field_price,
        field_short_description: resource.field_short_description,
        field_topic_id: resource.field_topic_id,
        field_nasba_category_name: resource.field_nasba_category_name,
        field_section_tag: resource.field_section_tag,
        field_course_type: null,
        field_learning_objectives: resource?.field_learning_objectives || null,
        field_detailed_objectives: resource?.field_detailed_objectives || null,
        field_prerequisite: resource?.field_prerequisite || null
      }
    }

    return adaptedTopic;
  }
  normalizeTopics(resourceData: any) {
    const topics = resourceData;
    let adaptedTopics: SimpleTopic[] = [];
    for (let i = 0; i < topics.length; i++) {
      let adaptedTopic: SimpleTopic = this.normalizeTopic(topics[i]);
      adaptedTopics.push(adaptedTopic);
    }

    return adaptedTopics;
  }

  normalizeCourse(resource) {
    /*
    We have 2 types of objects coming from the server.
    1. JSON:API data comes from the JSON:API
    2. The custom REST resources return a simplified node object based on Drupal\Core\Field\FieldDefinitionInterface[]
     */
    let adaptedCourse: SimpleCourse;
    if(resource.hasOwnProperty('attributes')) {
      // Must be from JSON:API
      adaptedCourse = {
        type: resource.type,
        id: resource.id,
        field_nid: resource.attributes.field_nid,
        title: this.normalizeUrl(resource.attributes.title),
        field_course_type: resource.attributes.field_course_type,
        field_timezone: resource.attributes.field_timezone,
        field_startdatetime: this.normalizeDateTime(resource.attributes.field_startdatetime),
        field_enddatetime: this.normalizeDateTime(resource.attributes.field_enddatetime),
        field_eveningsweekends: resource.attributes.field_eveningsweekends,
        field_id: resource.attributes.field_id,
        field_hours: this.normalizeHours(resource.attributes.field_hours),
        field_price: resource.attributes.field_price,
        field_topic_id: resource.attributes.field_topic_id,
        field_category_ids: '', // This property isn't actually in the node, it's sometimes added in the REST endpoint though.
        field_section_tag: resource.field_section_tag,
        field_canonical_url: this.normalizeCanonicalUrl(resource?.field_canonical_url)
      }
    } else {
      // Must be coming from REST
      adaptedCourse = {
        type: 'node--course',
        id: resource.uuid,
        field_nid: resource.nid,
        title: this.normalizeUrl(resource.title),
        field_course_type: resource.field_course_type,
        field_timezone: resource.field_timezone,
        field_startdatetime: this.normalizeDateTime(resource.field_startdatetime),
        field_enddatetime: this.normalizeDateTime(resource.field_enddatetime),
        field_eveningsweekends: resource.field_eveningsweekends,
        field_id: resource.field_id,
        field_hours: this.normalizeHours(resource.field_hours),
        field_price: resource.field_price,
        field_topic_id: resource.field_topic_id,
        field_category_ids: resource.field_category_ids,
        field_section_tag: resource.field_section_tag,
        card_image_url: resource?.card_image_url,
        field_canonical_url: this.normalizeCanonicalUrl(resource?.field_canonical_url)
      }
    }

    return adaptedCourse;
  }

  // Might not need this, but can use it to strip and modify the resource URL
  normalizeCanonicalUrl(url: string) {
    return url?.split("https://www.cpeonline.com")[1] || "";
  }

  // Might not need this, but can use it to strip and modify the resource URL
  normalizeUrl(url: string) {
    return url;
  }

  /*
  Titles sometimes include timestamps for some reason.
  Example Course Title: A Valuation Primer for Financial Reporting Webinar 2022-11-23 08:30 EST
 */
  normalizeTitle(title: string, method = 'default') {
    switch(method) {
      case 'length':
        // Can check word length and do it that way.
        let title_array = title.split(" ");
        if(title_array.length > 3) {
          // We might have a dateTime in the title, so remove it.
          title_array.length = title_array.length - 3;
          return title_array.join(" ");

        } else {
          return title;
        }
        break;
      default:
        // Can search for common indications that the title has a timestamp
        if(title.includes('-') && title.includes(':') && title.includes('EST')) {
          let title_array = title.split(" ");
          // We might have a dateTime in the title, so remove it.
          title_array.length = title_array.length - 3;
          return title_array.join(" ");
        } else {
          return title;
        }
        break;
    }

  }
  normalizeDateTime(timestamp: string) {
    //console.log('Converting timestamp with length ', timestamp.length);
    if(timestamp.length == 10) {
      return parseInt(timestamp) * 1000;
    } else {
      return parseInt(timestamp);
    }
  }
  normalizeHours(hours: string = '') {

    // Can search for common indications that the title has a timestamp
    if(hours.includes('.')) {
      const h = hours.split('.');
      if(h[1] == '00') {
        return h[0];
      } else if(h[1] == '50') {
        return h[0] + '.5';
      } else {
        return hours;
      }
    } else {
      return hours;
    }
  }
  getBoolean(value){
    switch(value){
      case true:
      case "true":
      case 1:
      case "1":
      case "on":
      case "yes":
        return true;
      default:
        return false;
    }
  }

  /**
   * Return the list of various topic types and corresponding codes.
   *
   */
  codesAndCategories() {

    if (this.cachedCodesAndCategories) {
      // This has already been calculated so return the object.
      return this.cachedCodesAndCategories;
    }

    // Not previously calculate so build the object now from a hardcoded list.

    //TODO embed this as a long cached REST response.
    const codeBlock = `
AA|FA|Financial Accounting|
AA|SEC|SEC Reporting|
AA|FA-I|International|
AA|AU|Auditing|
AA|NPGAA|Not-for-Profit & Governmental Accounting & Auditing|
AA|PPC|Profit Planning & Controlling|
AA|SI|Specific Industries|
ETHICS|ETHICS|State-Specific Ethics|
TAX|TAX|Taxation|none
TAX|TAX.IND|Individual|Individual Taxation
TAX|TAX.CORP|Corporation|Corporation Taxation
TAX|TAX.P|Partnership|Partnership Taxation
TAX|TAX.ET|Estates & Trusts|Estates & Trusts Taxation
TAX|TAX.ML|Multistate & Local|Multistate & Local Taxation
TAX|TAX.INT|International|International Taxation
TAX|TAX.SP|Special|Specialized Taxation
OTHER|COVID|COVID-19|
OTHER|BAP|Business Analysis & Planning|
OTHER|MA|Mergers & Acquisitions|
OTHER|TOF|Treasury Operation & Finance|
OTHER|MAS|Management Advisory Services|
OTHER|RE|Real Estate|
OTHER|PFPIM|Personal Financial Planning & Investment Management|
OTHER|MD|Management Development|
`;

    // 2023-06-09 - pas - Modified the split separater to 2 new lines to accomodate
    // line items that have long names by putting a convenient new line within the description.
    // This will only apply to html tags with the "white-space: pre-line;" style.
    // Convert the RAW string into an array for parsing
    let codeArray = codeBlock.split('\n');
    let codes: CategoryEntry[] = [];

    // Loop over the array and parse each entry into an array of objects
    codeArray.forEach((v) => {
      if (v.trim() !== '') {
        let parts = v.split(`|`);
        let categoryCode: CategoryEntry = {
          type: parts[0],
          category: parts[1],
          description: parts[2],
          descriptionWithCategory: parts[3]
        };

        // If there was no value then default to the description value
        if (categoryCode.descriptionWithCategory === '') {
          categoryCode.descriptionWithCategory = categoryCode.description
        }

        codes.push(categoryCode);
      }
    });

    this.cachedCodesAndCategories = codes;
    return this.cachedCodesAndCategories;
  }

  emailSubscribe(params:any) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json'
    );
    const url = environment.baseUrl + '/cpe-api/email-subscription?_format=json';
    return this.http.post(url, params, {headers});
  }
}
