<div id="cpe-footer" class="cpe-dark-blue-background cpe-container">
  <div class="cpe-wrapper">
    <div class="grid pt-5 pb-5 border-top-1">
      <div class="pt-0 lg:pr-5 xl:pr-5 pb-5 sm:col-6 md:col-6 lg:col-3 xl:col-3">
        <img src="assets/cpe-logo-footer-white.png" alt="Footer logo" height="140" class="">
        <br/><br/>
        <span class="font-medium">
          370 Reed Road<br/>
          Suite 227<br/>
          Broomall, PA 19008<br/><br/>
          P: 800-544-1114<br/>
          E: cs&#64;cpeincmail.com
        </span>
      </div>
      <div class="pt-0 lg:pl-5 lg:pr-5 xl:pl-5 xl:pr-5 pb-5 sm:col-6 md:col-6 lg:col-3 xl:col-3">
        <h1>COURSES</h1>
        <div class="w-full">
          <a href="" class="pb-3 inline-block w-full font-medium">Live Webinars</a>
          <a href="" class="pb-3 inline-block w-full font-medium">Self-Study</a>
          <a href="" class="pb-3 inline-block w-full font-medium">Virtual Conferences</a>
          <a href="" class="pb-3 inline-block w-full font-medium">In Person Conferences</a>
          <a href="" class="pb-3 inline-block w-full font-medium">All Courses</a>
        </div>
      </div>
      <div class="pt-0 lg:pl-5 lg:pr-5 xl:pl-5 xl:pr-5 pb-5 sm:col-6 md:col-6 lg:col-3 xl:col-3">
        <h1>RESOURCES</h1>
        <div class="w-full">
          <a href="" class="pb-3 inline-block w-full font-medium">Support</a>
          <a href="" class="pb-3 inline-block w-full font-medium">FAQs</a>
          <a href="" class="pb-3 inline-block w-full font-medium">Money-Back Guarantee</a>
          <a href="" class="pb-3 inline-block w-full font-medium">NASBA & State Sponsor Information</a>
        </div>
      </div>
      <div class="pt-0 lg:pl-5 lg:pr-5 xl:pl-5 xl:pr-5 pb-5 sm:col-6 md:col-6 lg:col-3 xl:col-3">
        <h1>COMPANY</h1>
        <div class="w-full">
          <a href="" class="pb-3 inline-block w-full font-medium">About Us</a>
          <a href="" class="pb-3 inline-block w-full font-medium">News/Blog</a>
          <a href="" class="pb-3 inline-block w-full font-medium">Contact</a>
          <img src="assets/cpe-footer-badges.png" alt="Footer badge" height="50" width="150" class="mt-2">
        </div>
      </div>
    </div>
  </div>

</div>

<div class="cpe-container">
  <div class="cpe-wrapper">
    <div class="grid border-top-1 copyrights-footer">
      <div class="col-12">
        <div class="grid">
          <div class="col-1"></div>
          <div class="col-12 text-md p-2 sm:p-4 sm:col-4 md:col-4 lg:col-4 xl:col-4">
            CPE INC. &#64; 2024 All Rights Reserved.<br>Build {{buildID}}
          </div>
          <div class="col-12 text-md p-2 sm:p-4 sm:col-4 md:col-4 lg:col-4 xl:col-4">
            <a routerLink="/policies">Policies, Terms & Conditions</a>
          </div>
          <div class="col-12 text-md p-2 sm:p-4 sm:col-2 md:col-2 lg:col-2 xl:col-2">
            <a href="">Site Map</a>
          </div>
          <div class="col-1"></div>
        </div>
      </div>
    </div>
  </div>
</div>
