<p-accordion [multiple]="true">
  <p-accordionTab header="Format" [selected]="showAllFilterExpanded || queryData?.formats?.length">
    <div *ngFor="let format of formats" class="field-checkbox">
      <p-checkbox name="format" [value]="format.key"  [(ngModel)]="selectedFormats" [inputId]="format.key"></p-checkbox>
      <label [for]="format.key">{{format.name}}</label>
    </div>
  </p-accordionTab>
  <p-accordionTab header="Date" [selected]="showAllFilterExpanded || queryData?.startDate">
    <div class="field-checkbox">
      <p-checkbox name="date" value="today" inputId="today" [(ngModel)]="selectedDates" (onChange)="setStartDates()"></p-checkbox>
      <label for="today">Today</label>
    </div>
    <div class="field-checkbox">
      <p-checkbox name="date" value="tomorrow" inputId="tomorrow" [(ngModel)]="selectedDates" (onChange)="setStartDates()"></p-checkbox>
      <label for="tomorrow">Tomorrow</label>
    </div>
    <div class="field-checkbox">
      <p-checkbox name="date" value="this-week" inputId="this-week" [(ngModel)]="selectedDates" (onChange)="setStartDates()"></p-checkbox>
      <label for="this-week">This Week</label>
    </div>
    <div class="field-checkbox">
      <p-checkbox name="date" value="this-month" inputId="this-month" [(ngModel)]="selectedDates" (onChange)="setStartDates()"></p-checkbox>
      <label for="this-month">This Month</label>
    </div>
    <div class="field-checkbox">
      <p-checkbox name="date" value="next-month" inputId="next-month" [(ngModel)]="selectedDates" (onChange)="setStartDates()"></p-checkbox>
      <label for="next-month">Next Month</label>
    </div>
    <div class="field-checkbox">
      <p-checkbox name="date" value="custom" inputId="custom-dates" [(ngModel)]="selectedDates" (onChange)="customDateChanged($event)"></p-checkbox>
      <label for="custom-dates">Custom</label>
    </div>
    <div *ngIf="showCustomDates" id="customDateRange" class="field">
      <label for="customDateRange" style="display: block">Date Range</label>
      <p-calendar [(ngModel)]="customDateRange" selectionMode="range" [readonlyInput]="true" inputId="dateRange" (onSelect)="setStartDates()"></p-calendar>
    </div>
  </p-accordionTab>
  <p-accordionTab header="Start Time ({{currentTimeZoneName}})" [selected]="showAllFilterExpanded || queryData?.['start-time-earliest'] || queryData?.['start-time-latest']">
    <div class="grid p-fluid">
      <div class="field sm:col-12 md:col-12 lg:col-6">
        <label for="earlyStart" style="display: block">Earliest</label>
        <p-calendar inputId="earlyStart" [defaultDate]="defaultDateEarlyStart" (ngModelChange)="modelEarlyStartChange()" [(ngModel)]="earlyStart" [showIcon]=true [showTime]=true [timeOnly]=true [stepMinute]=15 hourFormat="12" (onSelect)="setStartTime()"></p-calendar>
      </div>
      <div class="field sm:col-12 md:col-12 lg:col-6">
        <label for="lateStart" style="display: block">Latest</label>
        <p-calendar inputId="lateStart" [defaultDate]="defaultDateLateStart" (ngModelChange)="modelLatestStartChange()" [(ngModel)]="lateStart" [showIcon]=true [showTime]=true [timeOnly]=true [stepMinute]=15 hourFormat="12" (onSelect)="setStartTime()"></p-calendar>
      </div>
    </div>
  </p-accordionTab>
  <p-accordionTab header="Credits" [selected]="showAllFilterExpanded || queryData?.searchCredits">
    <div class="field-checkbox" *ngFor="let creditHour of creditHours">
      <p-checkbox name="hours" [value]="creditHour.key" [inputId]="creditHour.key" [(ngModel)]="selectedCredits"></p-checkbox>
      <label [for]="creditHour.key">{{creditHour.name}}</label>
    </div>
    <div class="field-checkbox">
      <p-checkbox name="hours" value="custom" inputId="custom-hours" [(ngModel)]="selectedCredits" (onChange)="customCreditHoursChanged($event)"></p-checkbox>
      <label for="custom-hours">Custom</label>
    </div>
    <div *ngIf="showCustomCredits" id="creditRange" class="grid p-fluid">
      <div class="field sm:col-12 md:col-12 lg:col-6">
        <label for="creditRangeMin" style="display: block">Minimum</label>
        <p-inputNumber [(ngModel)]="creditRangeMin" [showButtons]="true" [min]="0" [max]="500" [step]="1" buttonLayout="vertical" spinnerMode="vertical" inputId="creditRangeMin"
           decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus">
        </p-inputNumber>
      </div>
      <div class="field sm:col-12 md:col-12 lg:col-6">
        <label for="creditRangeMax" style="display: block">Maximum</label>
        <p-inputNumber [(ngModel)]="creditRangeMax" [showButtons]="true" [min]="0" [max]="500" [step]="1" buttonLayout="vertical" spinnerMode="vertical" inputId="creditRangeMax"
           decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus">
        </p-inputNumber>
      </div>
    </div>
  </p-accordionTab>
  <p-accordionTab header="Price Range" [selected]="showAllFilterExpanded || queryData?.priceRange">
    <div class="field-checkbox" *ngFor="let priceRange of priceRanges">
      <p-checkbox name="price" [value]="priceRange.key" [inputId]="priceRange.key" [(ngModel)]="selectedCost"></p-checkbox>
      <label [for]="priceRange.key">{{priceRange.name}}</label>
    </div>
    <div class="field-checkbox">
      <p-checkbox name="price" value="custom" inputId="custom-price" [(ngModel)]="selectedCost" (onChange)="customPriceRangeChanged($event)"></p-checkbox>
      <label for="custom-price">Custom</label>
    </div>
    <div *ngIf="showCustomPrice" id="priceRange" class="grid p-fluid">
      <div class="field sm:col-12 md:col-12 lg:col-6">
        <label for="priceRangeMin" style="display: block">Minimum</label>
        <p-inputNumber [(ngModel)]="priceRangeMin" [showButtons]="true" [min]="0" [max]="10000" [step]="1" buttonLayout="vertical" spinnerMode="vertical" inputId="priceRangeMin"
                       decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus">
        </p-inputNumber>
      </div>
      <div class="field sm:col-12 md:col-12 lg:col-6">
        <label for="priceRangeMax" style="display: block">Maximum</label>
        <p-inputNumber [(ngModel)]="priceRangeMax" [showButtons]="true" [min]="0" [max]="10000" [step]="1" buttonLayout="vertical" spinnerMode="vertical" inputId="priceRangeMax"
                       decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus">
        </p-inputNumber>
      </div>
    </div>
  </p-accordionTab>
  <p-accordionTab header="Topic" [selected]="showAllFilterExpanded || queryData?.topics" class="topic-accordion">
    <p-scrollPanel [style]="{width: '100%', height: '200px'}" styleClass="blue topic-search-container">
      <div *ngFor="let topic of searchTopicsList | filter:searchTopics:'descriptionWithCategory'" class="field-checkbox">
        <p-checkbox name="topic" [value]="topic.category"  [(ngModel)]="selectedTopics" [inputId]="topic.category"></p-checkbox>
        <label [for]="topic.category">{{ topic.descriptionWithCategory }}</label>
      </div>
    </p-scrollPanel>
    <span class="p-input-icon-left mt-3">
      <i class="pi pi-search"></i>
      <input type="text" pInputText [(ngModel)]="searchTopics" placeholder="Search">
      <span style="margin-left:-30px;" *ngIf="searchTopics">
        <button (click)="searchTopics = ''" class="cursor-pointer border-none outline-none relative bg-white">
            <i class="pi pi-times"></i>
        </button>
      </span>
    </span>
  </p-accordionTab>
  <p-accordionTab header="Level of Knowledge" [selected]="showAllFilterExpanded || queryData?.['knowledge-level']?.length">
    <div class="field-checkbox">
      <p-checkbox name="knowledge" value="overview" label="Overview" [(ngModel)]="selectedLevel"></p-checkbox>
    </div>
    <div class="field-checkbox">
      <p-checkbox name="knowledge" value="update" label="Update" [(ngModel)]="selectedLevel"></p-checkbox>
    </div>
    <div class="field-checkbox">
      <p-checkbox name="knowledge" value="basic" label="Basic" [(ngModel)]="selectedLevel"></p-checkbox>
    </div>
    <div class="field-checkbox">
      <p-checkbox name="knowledge" value="intermediate" label="Intermediate" [(ngModel)]="selectedLevel"></p-checkbox>
    </div>
    <div class="field-checkbox">
      <p-checkbox name="knowledge" value="advanced" label="Advanced" [(ngModel)]="selectedLevel"></p-checkbox>
    </div>
  </p-accordionTab>
  <p-accordionTab [selected]="showAllFilterExpanded || queryData?.pass?.length">
    <ng-template pTemplate="header">
      <span class="w-full">
        <span>Applicable Passes</span>
        <span [pTooltip]="applicablePassesTooltip"
        [tooltipEvent]="'focus'"
        [tabindex]="0"
        style="outline: 0;float: right"
        [escape]="false"
        [hideDelay]="200"
        #tooltipContainer
        (click)="$event.stopPropagation(); tooltipContainer.focus()"
        tooltipPosition="left"
        ><i class="fas fa-info-circle"></i></span>
      </span>
    </ng-template>

    <div *ngFor="let pass of passes" class="field-checkbox">
      <p-checkbox name="pass" [value]="pass.key"  [(ngModel)]="selectedPasses" [inputId]="pass.key" [disabled]="pass.key === 'disabled'"></p-checkbox>
      <label [for]="pass.key">{{pass.name}}</label>
    </div>
  </p-accordionTab>
  <p-accordionTab header="Location" [selected]="showAllFilterExpanded || queryData?.city || queryData?.state">
    <div class="px-3 pb-3 p-fluid">
      <label for="locationCity" style="display: block">Cities and States</label>
      <p-multiSelect [selectionLimit]="1" [virtualScroll]="true" [virtualScrollItemSize]="30"
      [options]="citiesAndStates" [(ngModel)]="selectedCities" optionLabel="name"
      optionValue="code" inputId="locationCity" [resetFilterOnHide]="true"></p-multiSelect>
    </div>
  </p-accordionTab>
</p-accordion>
<p-toolbar styleClass="sticky bottom-0 z-1">
  <p-button styleClass="mb-2 mr-2" label="Clear Filters" icon="pi pi-times p-button-danger" (click)="clearFilters()"></p-button>
  <p-button styleClass="mb-2 "label="Search" icon="pi pi-search p-button-success" (click)="search()"></p-button>
</p-toolbar>
