<div class="flex grid align-items-center mx-3">
  <button
    pButton
    pRipple
    type="button"
    icon="pi pi-home"
    class="p-button-rounded m-2"
    (click)="backToUserScreen()"
  ></button>
  <h1 class="text-5xl col">
    My Account <span *ngIf="viewAccountProfile">- Account Profile</span>
    <span *ngIf="viewCourseList">- Course Codes</span>
    <span *ngIf="viewPurchasesList">- Purchases and Registrations</span>
  </h1>
</div>
<div
  *ngIf="
    viewAccountProfile === false &&
    viewCourseList === false &&
    viewPurchasesList === false
  "
>
  <div class="m-4">
    <div class="grid m-3">
      <div class="col p-fluid">
        <button
          pButton
          pRipple
          type="button"
          label="Registrations"
          class="p-button-raised p-button-text p-button-plain p-button-outlined justify-content-center p-5 m-0 font-bold"
          style="background-color: white; font-size: xx-large; height: 200px"
          (click)="viewPurchasesList = true"
        ></button>
      </div>
      <div class="col p-fluid">
        <button
          pButton
          pRipple
          type="button"
          label="Course Codes"
          class="p-button-raised p-button-text p-button-plain p-button-outlined justify-content-center p-5 m-0 font-bold"
          style="background-color: white; font-size: xx-large; height: 200px"
          (click)="viewCourseList = true"
        ></button>
      </div>
      <div class="col p-fluid">
        <button
          pButton
          pRipple
          type="button"
          label="Account Profile"
          class="p-button-raised p-button-text p-button-plain p-button-outlined justify-content-center p-5 m-0 font-bold"
          style="background-color: white; font-size: xx-large; height: 200px"
          (click)="viewAccountProfile = true"
        ></button>
      </div>
    </div>
  </div>
  <p-card header="Current Passes" styleClass="mx-4">
    <p-table [value]="currentPasses">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="purchaseDate">
            Purchase Date <p-sortIcon field="purchaseDate"></p-sortIcon>
          </th>
          <th pSortableColumn="creditHours">
            Credits <p-sortIcon field="creditHours"></p-sortIcon>
          </th>
          <th pSortableColumn="remainingHours">
            Remaining Hours <p-sortIcon field="remainingHours"></p-sortIcon>
          </th>
          <th pSortableColumn="passCode">
            Pass Code <p-sortIcon field="passCode"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-course>
        <tr>
          <td>{{ course.purchaseDate }}</td>
          <td>{{ course.creditHours }}</td>
          <td>{{ course.remainingHours }}</td>
          <td>{{ course.passCode }}</td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</div>

<!-- Purchases and Registrations List Screen -->
<div *ngIf="viewPurchasesList === true">
  <p-card header="Purchases and Registrations" styleClass="m-3">
    <p-table [value]="payments">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="date">
            Date <p-sortIcon field="date"></p-sortIcon>
          </th>
          <th pSortableColumn="registrationNumber">
            Web Order ID# <p-sortIcon field="registrationNumber"></p-sortIcon>
          </th>
          <th pSortableColumn="status">
            Status <p-sortIcon field="status"></p-sortIcon>
          </th>
          <th pSortableColumn="total">
            Total <p-sortIcon field="total"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-purchase>
        <tr>
          <td>{{ purchase.date }}</td>
          <td>{{ purchase.registrationNumber }}</td>
          <td>{{ purchase.status }}</td>
          <td>{{ purchase.total }}</td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</div>

<!-- Course List Screen -->
<div *ngIf="viewCourseList === true">
  <div class="m-3">
    <app-course-codes></app-course-codes>
  </div>
</div>

<!-- Account Profile Screen -->
<div *ngIf="viewAccountProfile === true">
  <div class="m-3">
    <h1>Work In Progress</h1>
  </div>
</div>
