import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  buildID = environment.buildId;

  constructor() { }

  ngOnInit(): void {
  }
  subscribe() {
    //TODO: Flesh out the subscribe function
    return;
  }
}
