import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Subscription } from 'rxjs';
import { SessData, SessionService } from '../_services/session.service';
import { ActivatedRoute } from '@angular/router';
import { SearchService } from '../_services/search.service';
import { ListBoxOption } from '../_types/ui-types';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-ethics-courses',
  templateUrl: './ethics-courses.component.html',
  styleUrls: ['./ethics-courses.component.scss']
})
export class EthicsCoursesComponent implements OnInit {

  private subs: Subscription[] = [];
  returnedCourses: any[];
  featuredEthicCourses: any[];
  rawResponse: any[];
  loading: boolean = false;
  errorMsg: string;
  paginator: boolean = false;
  sessData: SessData | null = null;

  states: any[] = [];
  selectedState: string = undefined;

  formats: any[] = [
    {
      key: 'webinar',
      name: 'Live Webinar',
    },
    {
      key: 'self-study',
      name: 'Self-Study',
    },
    {
      key: 'virtual+conference',
      name: 'Virtual Conference',
    },
    {
      key: 'conference',
      name: 'In-Person Conference',
    },
    {
      key: 'seminar',
      name: 'In-Person Seminar',
    }
  ];
  selectedFormat: string = undefined;
  showSearchResults: boolean = false;

  displayResponsiveTableView: boolean = false;
  private isServer = isPlatformServer(this.platformId);
  
  constructor(
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private searchService: SearchService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {

    // Get current session data
    this.subs.push(
      this.sessionService.currentData.subscribe((data) => {
        this.sessData = data;
        this.sessData.currentPage.heading = 'Ethics Courses & Webcasts';
      })
    );
    this.searchEthics();
    this.subs.push(
      this.route.queryParams.subscribe(queryParams => {
        // Clear response data
        this.reset();
        if (!this.loading) {
          this.searchEthics();
        }
      })
    );
    this.getCitiesAndStates();
  }

  ngAfterViewInit(): void {
    if(!this.isServer) {
      if (window.innerWidth <= 960) {
        this.displayResponsiveTableView = true;
      }
      window.addEventListener('resize', () => {
        this.displayResponsiveTableView = (window.innerWidth <= 960);
      });
    }
  }

  reset(): void {
    this.errorMsg = null;
    this.returnedCourses = null;
    this.featuredEthicCourses = null;
  }

  searchEthics(): void {
    this.loading = true;

    this.searchService
      .fulltextSearch("")
      .subscribe({
        next: (data) => {
          // Array of custom response objects, should be either custom objects or an error object.
          if (data.length > 25) {
            this.paginator = true;
            this.rawResponse = data;
          } else {
            this.rawResponse = data;
          }
        },
        error: (error) => {
          console.error(
            'search-results:search(): Error has occurred'
          );
          this.errorMsg = 'Unable to get search results, please try again later'
          this.loading = false;
        },
        complete: () => {
          this.returnedCourses = this.rawResponse;
          this.featuredEthicCourses = this.rawResponse.filter((course: any, index) => index < 5)
          this.loading = false;
          this.sessionService.setSessData(this.sessData);
        },
      });
  }

  searchEthicCourses(): void {
    this.errorMsg = "";
    let queryData = {
      state: this.selectedState,
      formats: this.selectedFormat
    }

    this.searchService.searchWithCriteria(queryData)
      .subscribe({
        next: (data) => {
          // Array of custom response objects, should be either custom objects or an error object.
          if(data.length > 25) {
            this.paginator = true;
            this.rawResponse = data;
          } else {
            this.rawResponse = data;
          }
        },
        error: (error) => {
          this.errorMsg = 'Unable to get search results, please try again later'
          this.showSearchResults = true;
          this.loading = false;
        },
        complete: () => {
          this.returnedCourses = this.rawResponse;
          if(!this.returnedCourses?.length || (this.returnedCourses?.length && this.returnedCourses[0]?.["Error"])) {
            this.errorMsg = 'No courses to display. Please use the filter courses options to find a topic of interest.'
            if(this.returnedCourses?.length && this.returnedCourses[0]?.["Error"]) {
              this.errorMsg = "Unable to get search results, please try again later";
            }
            this.returnedCourses = undefined
          }
          this.showSearchResults = true;
          this.loading = false;
        },
      });
  }

  private getCitiesAndStates(): void {
    this.searchService.getCriteriaOptions().subscribe((items) => {
      if (items?.states?.length) {
        let states: ListBoxOption[] = [];

        items.states.forEach((value => {
          states.push({
            name: value,
            code: value
          })
        }));

        this.states = states;
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
    for (let i = 0; i < this.subs.length; i++) {
      this.subs.pop();
    }
  }

}
