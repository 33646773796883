<div class="cpe-container search-results">
  <div class="sidebar" *ngIf="displayResponsiveSidebar">
    <ul>
      <li><span (click)="sidebarVisible = true">Filter Courses</span></li>
    </ul>
  </div>
  <div class="cpe-wrapper pb-6">
    <div class="grid min-w-full">
      <div class="col-12 text-xl font-bold" style="display: none;">
        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut</p>
      </div>
      <div class="col-12 lg:col-4 left">

        <p-sidebar [(visible)]="sidebarVisible" position="left" styleClass="w-21rem">
          <h3>Filter Courses</h3>
          <app-search-widget [queryData]="queryData" style="position: relative;"></app-search-widget>
        </p-sidebar>
        <!-- <p-button (click)="sidebarVisible = true" *ngIf="displayResponsiveSidebar" label="Filter Courses" styleClass="mt-3 filter-courses"></p-button> -->

        <div *ngIf="!displayResponsiveSidebar" class="column-heading cpe-dark-blue-background align-content-center pl-3">
          <h1>Filter Courses</h1>
        </div>
        <app-search-widget
          *ngIf="!displayResponsiveSidebar"
          [queryData]="queryData"
          style="position: relative;"
        ></app-search-widget>
      </div>
      <!--col-3-->
      <div class="col-12 lg:col-8 right ">
        <div class="column-heading cpe-dark-blue-background align-content-center pl-3" *ngIf="!displayResponsiveSidebar"><h1>Search Results</h1></div>
          <div *ngIf="errorMsg" class="alert alert-danger mt-3 mb-0">{{errorMsg}}</div>

        <!-- THIS IS THE MOBILE VERSION -->
        <!-- THIS IS THE MOBILE VERSION -->
        <!-- THIS IS THE MOBILE VERSION -->
        <!-- THIS IS THE MOBILE VERSION -->

            <p-table #searchResultTable [value]="returnedTopics" [paginator]="paginator && !loading" [rows]="25" [loading]="loading"
            *ngIf="!errorMsg && searchGridToShow ==='topics'" [class.responsive-search-result-table]="displayResponsiveTableView"
            [class.h-full-loading]="loading">
              <ng-template pTemplate="header">
                <tr *ngIf="!displayResponsiveTableView">
                  <th pSortableColumn="field_canonical_title">Course Title <p-sortIcon field="field_canonical_title"></p-sortIcon></th>
                  <th pSortableColumn="field_hours">Credits <p-sortIcon field="field_hours"></p-sortIcon></th>
                  <th style="min-width: 110px;">Format</th>
                  <th pSortableColumn="field_price">Price <p-sortIcon field="field_price"></p-sortIcon></th>
                  <th>&nbsp;</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-topic>


                <!-- SMALLEST POSSIBLE RESPONSIVE SCREEN SIZE -->
                <tr *ngIf="displayResponsiveTableView">
                  <td>
                    <span class="p-column-title search-result-course-title" style="min-width: 100px;display: block;">{{ topic.field_canonical_title}}</span>
                  </td>
                  <td colspan="4">
                    <span class="p-column-title" style="display: block;">
                      <p>{{ topic?.field_course_type ? topic.field_course_type : '' }}</p>
                      <p>{{ topic?.field_hours ? topic.field_hours : '' }} {{ topic.field_hours === 1.00 ? 'Credit' : 'Credits' }} |
                        <span [class.line-through]="utm_source_discount_data?.topic_ids?.length && utm_source_discount_data?.topic_ids?.indexOf(topic.field_topic_id) > -1">
                          <span [class.mb-0]="utm_source_discount_data?.topic_ids?.length && utm_source_discount_data?.topic_ids?.indexOf(topic.field_topic_id) > -1">${{ topic.field_price ? (topic.field_price) : '' }}</span>
                        </span>
                        <span *ngIf="utm_source_discount_data?.topic_ids?.length && utm_source_discount_data?.topic_ids?.indexOf(topic.field_topic_id) > -1">
                          <span class="ml-1">${{ topic.field_price - (topic.field_price * utm_source_discount_data.discount) }}</span>
                        </span>
                      </p>
                    </span>
                    <span class="p-column-title" style="display: block;">
                      &nbsp;
                      <!-- <p>{{ topic?.field_course_type ? topic.field_course_type : '' }}</p> -->
                    </span>
                    <span class="p-column-title" style="display: block;">
                      &nbsp;
                    </span>
                    <span class="p-column-title" style="display: block;">
                      <p class="mt-0">
                        <a pButton pRipple label="View Details" id="course-{{topic.uuid}}"
                           class="view-pass-details-btn font-bold white-space-nowrap" icon="pi pi-angle-right" iconPos="right"
                           [routerLink]="['/'+topic.field_canonical_url]"></a>
                      </p>
                    </span>
                  </td>
                </tr>

                <!-- Medium responsive screen size. Small screen size but not mobile small -->
                <tr *ngIf="!displayResponsiveTableView">
                  <td style="text-wrap: balance">
                    <span class="p-column-title search-result-course-title" style="min-width: 100px;">{{ topic.field_canonical_title}}</span>
                    {{ !displayResponsiveTableView ? topic.field_canonical_title : '' }}
                  </td>
                  <td colspan="4" *ngIf="displayResponsiveTableView">
                    <span class="p-column-title">
                      <p>{{ topic?.field_course_type ? topic.field_course_type : '' }}</p>
                      <p>{{ topic?.field_hours ? topic.field_hours : '' }} {{ topic.field_hours === 1.00 ? 'Credit' : 'Credits' }} |
                        <span [class.line-through]="utm_source_discount_data?.topic_ids?.length && utm_source_discount_data?.topic_ids?.indexOf(topic.field_topic_id) > -1">
                          <span [class.mb-0]="utm_source_discount_data?.topic_ids?.length && utm_source_discount_data?.topic_ids?.indexOf(topic.field_topic_id) > -1">${{ topic.field_price ? (topic.field_price) : '' }}</span>
                        </span>
                        <span *ngIf="utm_source_discount_data?.topic_ids?.length && utm_source_discount_data?.topic_ids?.indexOf(topic.field_topic_id) > -1">
                          <span class="ml-1">${{ topic.field_price - (topic.field_price * utm_source_discount_data.discount) }}</span>
                        </span>
                      </p>
                    </span>
                    <span class="p-column-title">
                      &nbsp;
                      <!-- <p>{{ topic?.field_course_type ? topic.field_course_type : '' }}</p> -->
                    </span>
                    <span class="p-column-title">
                      &nbsp;
                    </span>
                    <span class="p-column-title">
                      <p class="mt-0">
                        <a pButton pRipple label="View Details" id="course-{{topic.uuid}}"
                        class="view-pass-details-btn font-bold white-space-nowrap" icon="pi pi-angle-right" iconPos="right"
                        [routerLink]="['/'+topic.field_canonical_url]"></a>
                      </p>
                    </span>
                  </td>
                  <td *ngIf="!displayResponsiveTableView" class="search-col-hours">
                    {{ topic?.field_hours ? (topic.field_hours | number : '1.1-1') : '' }}
                  </td>
                  <td *ngIf="!displayResponsiveTableView">
                    {{ topic?.field_course_type ? topic.field_course_type : '' }}
                  </td>
                  <td *ngIf="!displayResponsiveTableView" class="search-col-price">
                    <span [class.line-through]="utm_source_discount_data?.topic_ids?.length && utm_source_discount_data?.topic_ids?.indexOf(topic.field_topic_id) > -1">
                      {{ topic.field_price ? ('$'+topic.field_price) : '' }}
                    </span>
                    <span *ngIf="utm_source_discount_data?.topic_ids?.length && utm_source_discount_data?.topic_ids?.indexOf(topic.field_topic_id) > -1">
                      {{ '$'+(topic.field_price - (topic.field_price * utm_source_discount_data.discount)) }}
                    </span>
                  </td>
                  <td *ngIf="!displayResponsiveTableView">
                    <a pButton pRipple label="View Details" id="course-{{topic.uuid}}" class="font-bold white-space-nowrap text-white" [routerLink]="['/'+topic.field_canonical_url]"></a>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <!-- if returnedTopics -->

        <!-- DESKTOP RESULTS TABLE -->
        <!-- DESKTOP RESULTS TABLE -->
        <!-- DESKTOP RESULTS TABLE -->
        <!-- DESKTOP RESULTS TABLE -->

            <p-table #searchResultTable [value]="returnedCourses" [paginator]="paginator && !loading" [rows]="25" [loading]="loading"
                     *ngIf="!errorMsg && searchGridToShow ==='courses'" [class.responsive-search-result-table]="displayResponsiveTableView" [class.h-full-loading]="loading">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="field_canonical_title">Course Title <p-sortIcon field="field_canonical_title"></p-sortIcon></th>
                  <th pSortableColumn="field_hours">Credits <p-sortIcon field="field_hours"></p-sortIcon></th>
                  <th style="min-width: 110px;">Format</th>
                  <th pSortableColumn="field_price">Price <p-sortIcon field="field_price"></p-sortIcon></th>
                  <th>&nbsp;</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-course>
                <tr>
                  <td style="text-wrap: balance">
                    <span class="p-column-title search-result-course-title" style="min-width: 100px;">{{ course?.field_canonical_title ? course.field_canonical_title : course?.title}}</span>
                    {{ !displayResponsiveTableView ? (course?.field_canonical_title ? course.field_canonical_title : course?.title) : '' }}
                  </td>
                  <td colspan="4" *ngIf="displayResponsiveTableView">
                    <span class="p-column-title">
                      <p>{{ course.field_course_type ? course.field_course_type : '' }}</p>
                      <p>{{ course?.field_hours ? course.field_hours : '' }} {{ course.field_hours  === 1.00 ? 'Credit' : 'Credits' }} |
                        <span [class.line-through]="utm_source_discount_data?.topic_ids?.length && utm_source_discount_data?.topic_ids?.indexOf(course.field_topic_id) > -1">
                          <span [class.mb-0]="utm_source_discount_data?.topic_ids?.length && utm_source_discount_data?.topic_ids?.indexOf(course.field_topic_id) > -1">${{ course.field_price ? course.field_price : '' }}</span>
                        </span>
                        <span *ngIf="utm_source_discount_data?.topic_ids?.length && utm_source_discount_data?.topic_ids?.indexOf(course.field_topic_id) > -1">
                          <span class="ml-1">${{ course.field_price - (course.field_price * utm_source_discount_data.discount) }}</span>
                        </span>
                      </p>
                    </span>
                    <span class="p-column-title">
                      &nbsp;
                    </span>
                    <span class="p-column-title">
                      &nbsp;
                    </span>
                    <span class="p-column-title">
                      <p>
                        <a pButton pRipple label="View Details" id="course-{{course.uuid}}"
                        class="view-pass-details-btn font-bold white-space-nowrap" icon="pi pi-angle-right" iconPos="right"
                        [routerLink]="['/'+course.field_canonical_url]"></a>
                      </p>
                    </span>
                  </td>
                  <td *ngIf="!displayResponsiveTableView">
                    <span class="p-column-title">Credits</span>
                    {{ course?.field_hours ? (course.field_hours | number : '1.1-1') : '' }}
                  </td>
                  <td *ngIf="!displayResponsiveTableView">
                    <span class="p-column-title">Format</span>
                    {{ course.field_course_type ? course.field_course_type : '' }}
                  </td>
                  <td *ngIf="!displayResponsiveTableView">
                    <span class="p-column-title">Price</span>
                    <span [class.line-through]="utm_source_discount_data?.topic_ids?.length && utm_source_discount_data?.topic_ids?.indexOf(course.field_topic_id) > -1">
                      {{ course.field_price ? ('$'+course.field_price) : '' }}
                    </span>
                    <span *ngIf="utm_source_discount_data?.topic_ids?.length && utm_source_discount_data?.topic_ids?.indexOf(course.field_topic_id) > -1">
                      {{ '$'+(course.field_price - (course.field_price * utm_source_discount_data.discount)) }}
                    </span>
                  </td>
                  <td *ngIf="!displayResponsiveTableView">
                    <a pButton pRipple label="View Details" id="course-{{course.uuid}}" class="font-bold white-space-nowrap text-white" [routerLink]="['/'+course.field_canonical_url]"></a>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <!-- if returnedTopics -->
      </div>
    </div><!--grid-->
  </div><!--cpe-wrapper-->
</div><!--cpe-container-->
