import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss'],
})
export class UserAccountComponent implements OnInit, OnDestroy {
  // List of subscriptions to unsubscribe on destroy
  private subs: Subscription[];

  // Flags to enable/disable different views
  viewAccountProfile: boolean = false;
  viewCourseList: boolean = false;
  viewPurchasesList: boolean = false;

  // Lists for table views
  currentPasses: any[];
  payments: any[];

  constructor() {
    this.subs = [];
  }

  ngOnInit(): void {
    // Test list of current passes
    this.currentPasses = [
      {
        purchaseDate: 'MM/DD/YY',
        creditHours: 40,
        remainingHours: 35,
        passCode: '**********123 <*>'
      }
    ];

    // Test list of payments
    this.payments = [
      {
        date: 'MM/DD/YY',
        registrationNumber: '123456789',
        status: 'completed',
        total: '$1,000.00',
      },
      {
        date: 'MM/DD/YY',
        registrationNumber: '987654321',
        status: 'pending',
        total: '$1,300.00',
      },
      {
        date: 'MM/DD/YY',
        registrationNumber: '192837465',
        status: 'pending',
        total: '$1,500.00',
      },
      {
        date: 'MM/DD/YY',
        registrationNumber: '564738291',
        status: 'completed',
        total: '$1,400.00',
      },
    ];
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
    for (let i = 0; i < this.subs.length; i++) {
      this.subs.pop();
    }
  }

  backToUserScreen() {
    this.viewAccountProfile = false;
    this.viewCourseList = false;
    this.viewPurchasesList = false;
  }
}
