import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { isPlatformServer } from '@angular/common';
import { BehaviorSubject, of, Subject, ReplaySubject, Observable } from 'rxjs';

import {BannerResource, BannersResource} from "../../banner";

import { environment } from 'src/environments/environment';
import {User} from "../../user.model";
import {concatMap} from 'rxjs/operators';


export interface SessData {
  user: User | null;
  banners: BannersResource | null;
  currentPage: {
    heading: string | '';
    message: string | '';
    entity: string | '';
    path: string | '';
  };
}

export interface StorageChange {
  key: string;
  value: string;
  storageArea: "localStorage" | "sessionStorage";
}

export interface StorageGetItem {
  key: string;
  storageArea: "localStorage" | "sessionStorage";
}

const ACCESS_TOKEN = 'access_token';
const LOGOUT_TOKEN = 'logout_token';
const USER_DATA = 'user_data';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private isAuthenticated = false;

  static isBrowser = new BehaviorSubject<boolean>(null);

  public sessData: SessData = null;
  public activeBanners: BannersResource;
  private sessSource = new BehaviorSubject(this.sessData);
  currentData = this.sessSource.asObservable();
  public storageChange$: ReplaySubject<StorageChange> = new ReplaySubject();

  // Observable string sources
  private missionAnnouncedSource = new Subject<string>();
  private missionConfirmedSource = new Subject<string>();

  // Observable string streams
  missionAnnounced$ = this.missionAnnouncedSource.asObservable();
  missionConfirmed$ = this.missionConfirmedSource.asObservable();

  private isLoggedinStatusChangedSource$ = new Subject<string>();
  isLoggedinStatusChanged$ = this.isLoggedinStatusChangedSource$.asObservable();


  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
    SessionService.isBrowser.next(isPlatformBrowser(platformId));
    if(isPlatformBrowser(platformId)) {

      // console.log(`session.service: Constructor fired`);

      // Set up session, either fresh or recovered from storage
      const session = this.getStorageItem({key: 'sessData', storageArea: 'sessionStorage'});
      if(session && session.hasOwnProperty('currentPage')) {
        this.setSessData(session);
      } else {
        console.error('Rebuilding session state!');
        this.setSessData(this.createSession());
      }
      // console.log('Session service has been reloaded:', this.sessData);
    } else {
      this.setSessData(this.createSession());
    }
  }
  fullyLoaded = false;
  private createSession(): SessData {
    return this.sessData = {
      user: null,
      banners: null,
      currentPage: {
        heading: '',
        message: '',
        entity: '',
        path: ''
      }
    };
  }
  get isLoggedIn(): boolean {

    if(!isPlatformBrowser(this.platformId)) {
      // Only check if logged in on server.
      return false;
    }

    console.log(`session.service:isLoggedIn()`);
      // Check if token is set
      const token = this.getAccessToken();

      // If there is no token, logout and return
      if (token === null || token.length === 0) {
        console.log(`session.service:isLoggedIn() invalid token. Logging out.`);
        this.logout();
        return this.isAuthenticated;
      } else {
        console.log(`session.service:isLoggedIn() token is valid.`);
      }

      this.isAuthenticated = true;

      return this.isAuthenticated;
  }

  public getStorageItem(getItem: StorageGetItem): any {
    //return  JSON.parse(sessionStorage.getItem('sessData'));
    // console.log('getStorageItem:', getItem);
    //const item = window[getItem.storageArea].getItem(getItem.key); // We're getting errors when using SSR, so disable for now.
    const item = sessionStorage.getItem(getItem.key);
    if(getItem.key === 'sessData') {
      return JSON.parse(item);
    } else {
      return item;
    }
  }

  // If we need more granular control, can enable this
  // This would allow us to switch different storage types for SSR
  public setStorageItem(change: StorageChange): void {
    //sessionStorage.setItem('sessData', JSON.stringify(data));
    console.log('session.service->setStorageItem:',change);
    if(typeof change.value === 'object') {
      change.value = JSON.stringify(change.value);
    }
    //sessionStorage.setItem(change.key, change.value);
    window[change.storageArea].setItem(change.key, change.value);
    //this.sessSource.next(change.value);
    this.storageChange$.next(change);
  }

  public setSessData(data: SessData): void {
    // console.log('setSessData:',data);
    this.sessData = data;
    const s = JSON.stringify(data);
    if (isPlatformBrowser(this.platformId)) {
      // We're using the browser (not SSR)
      window['sessionStorage'].setItem('sessData', s);
      // console.log('Not SSR; Using window to access sessionStorage');
    }
    //window['sessionStorage'].setItem('sessData', s); // We're getting errors when using SSR, so disable for now.
    this.sessSource.next(data);
  }

  getAccessTokenAndRetrieveIfNeeded(): Observable<string> {
    // console.log(`getAccessTokenAndRetrieveIfNeeded() entered`);

    if(isPlatformBrowser(this.platformId)) {
      let currentToken = localStorage.getItem(ACCESS_TOKEN);
      // console.log('Access token is ' + currentToken);

      if(!currentToken){
        // console.log(`Going to get access token`);
        // go get it

        const headers = new HttpHeaders()
          .set("Accept", "text/plain")
          .set("Content-Type", "text/plain");

        const tokenObservable = this.http.get(`${environment.baseUrl}/session/token`, {
          headers: headers,
          responseType: 'text'
        });

        return tokenObservable.pipe(concatMap(accessToken => {
          // console.log(`getAccessTokenAndRetrieveIfNeeded: ${accessToken}`);

          // Save the access token and then pass it as an observable to the observable that initiated the check
          this.saveAccessToken(accessToken);
          return of(accessToken);
        }));

      } else {
        // return the current token
        return of('');
      }


    } else {
      return of('');
    }

  }

  getAccessToken(): string {
    if(isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(ACCESS_TOKEN) || "";
    } else {
      return '';
    }
  }

  getUserData(): string {
    if(isPlatformBrowser(this.platformId)) {
      return sessionStorage.getItem(USER_DATA) || "";
    } else {
      return '';
    }
  }

  saveUserData(userData: string): void {
    sessionStorage.setItem(USER_DATA, userData);
  }

  removeUserData(): void {
    if(isPlatformBrowser(this.platformId)) {
      sessionStorage.removeItem(USER_DATA);
    }
  }

  saveAccessToken(token: string): void {
    console.log(`session.service:saveAccessToken() new token ${token}`);
    localStorage.setItem(ACCESS_TOKEN, token);
    this.isLoggedinStatusChangedSource$.next("logged-in");
  }

  removeAccessToken(): void {
    if(isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(ACCESS_TOKEN);
    }
  }

  getLogoutToken(): string {
    if(isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(LOGOUT_TOKEN);
    } else {
      return '';
    }
  }

  saveLogoutToken(token: string): void {
    localStorage.setItem(LOGOUT_TOKEN, token);
  }

  removeLogoutToken(): void {
    if(isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(LOGOUT_TOKEN);
    }
  }

  logout() {
    this.isAuthenticated = false;
    this.removeAccessToken();
    this.removeLogoutToken();
    this.removeUserData();
    this.isLoggedinStatusChangedSource$.next("logged-out");
  }

  getUrl(url: string) {
    let segment = '';

    switch (url) {
      case 'login': {
        segment = '/user/login';
        break;
      }
      case 'logout': {
        segment = '/cpe_api/logout';
        break;
      }
      case 'login-status': {
        segment = '/user/login_status';
        break;
      }
    }

    return segment;
  }

  public getIpClient() {
    return this.http
      .get<any>('https://geolocation-db.com/json/').subscribe({
        next: (data) => {
          console.log('session.service: getIpClient Success');
          if(data.IPv4) {
            return data.IPv4;
          } else {
            console.error('Something went wrong with getIpClient');
            return 'Unable to resolve';
          }
        },
        error: (error) => {
          // console.error('submitAcct returned false.');
          try {
            throw error;
          } catch (error) {
            console.error('getCommerceCartById returned false: ', error);
            // We should override the state to negate what we tried to do.
            //return this.cart;
          }
        }
      }
    );
  }

  // Service message commands
  announceMission(mission: string) {
    this.missionAnnouncedSource.next(mission);
  }

  confirmMission(astronaut: string) {
    this.missionConfirmedSource.next(astronaut);
  }
}
