import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { TopicsResource } from "../../topic";
import { CoursesResource, } from "../../course";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from "../../environments/environment";
import { CategoryEntry, TopicService } from '../_services/topic.service';
import { DateTime } from 'luxon';
import {isPlatformServer} from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isServer = isPlatformServer(this.platformId);

  // Development flag to hide sections that are not finished
  showWorkInProgressSections = true;
  inDemandCourseDataLoaded: boolean = false;
  upcomingConferencesDataLoaded: boolean = false;
  query: any;
  events: CoursesResource | null = null;
  topics: TopicsResource | null = null;
  courses: CoursesResource | null = null;
  searchCoursesQuery: string = "";
  upcomingConferencesData: any;
  inDemandCourseData: any = {
    sections: [
      {
        'key': 'new',
        'label': 'New'
      },
      {
        'key': 'trending',
        'label': 'Trending'
      },
      {
        'key': 'popular',
        'label': 'Popular'
      },
      {
        'key': 'upcoming',
        'label': 'Upcoming'
      },
      {
        'key': 'special',
        'label': 'Special Promos'
      },
    ],
    categories: [
      {
        type: 'Financial Accounting',
        category: 'Financial Accounting',
        description: 'Financial Accounting',
      },
      {
        type: 'Individual Taxation',
        category: 'Individual Taxation',
        description: 'Individual Taxation',
      },
      {
        type: 'SEC Reporting',
        category: 'SEC Reporting',
        description: 'SEC Reporting',
      },
      {
        type: 'State-Specific Ethics',
        category: 'State-Specific Ethics',
        description: 'State-Specific Ethics',
      },
      {
        type: 'Not-for-Profit Accounting & Auditing',
        category: 'Not-for-Profit Accounting & Auditing',
        description: 'Not-for-Profit Accounting & Auditing',
      }
    ],
    cards: []
  };

  popularCourses: any[] = [
    {
      title: "Financial Accounting",
      count: 10,
      image: "financial-accounting.png",
      category: "PFPIM"
    },
    {
      title: "Individual Taxation",
      count: 10,
      image: "individual-taxation.png",
      category: "TAX.IND"
    },
    {
      title: "SEC Reporting",
      count: 10,
      image: "sec-reporting.png",
      category: "SEC"
    },
    {
      title: "State-Specific Ethics",
      count: 10,
      image: "state-specific-ethics.png",
      category: "ETHICS"
    },
    {
      title: "Corporate Taxation",
      count: 10,
      image: "corporate-taxation.png",
      category: "TAX.CORP"
    },
    {
      title: "Partnership Taxation",
      count: 10,
      image: "partnership-taxation.png",
      category: "TAX.P"
    }
  ]
  responsiveOptions = [
    {
        breakpoint: '1400px',
        numVisible: 5,
        numScroll: 1
    },
    {
        breakpoint: '1200px',
        numVisible: 4,
        numScroll: 1
    },
    {
        breakpoint: '990px',
        numVisible: 3,
        numScroll: 1
    },
    {
        breakpoint: '700px',
        numVisible: 2,
        numScroll: 1
    },
    {
        breakpoint: '575px',
        numVisible: 1,
        numScroll: 1
    }
  ];

  testimonials = [
    {
      rating: 5,
      title: "Test name",
      description: "description of testimonials "
    },
    {
      rating: 4,
      title: "Test name",
      description: "description of testimonials description of testimonials description of testimonials description of testimonials"
    },
    {
      rating: 3,
      title: "Test name",
      description: "description of testimonials"
    },
    {
      rating: 2,
      title: "Test name",
      description: "description of testimonials"
    },
    {
      rating: 1,
      title: "Test name",
      description: "description of testimonials"
    }
  ]
  topicCategories: CategoryEntry[];
  weekDates: string = DateTime.now().setLocale('en-US').toFormat('yyyy-MM-dd') + "," + DateTime.now().setLocale('en-US').plus({ days: 7 }).toFormat('yyyy-MM-dd');

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private topicService: TopicService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  searchCourses() {
    //TODO: Flesh out the header search function
    return;
  }

  // This function will retrieve any required data.
  getData() {
    this.getSliderData();
    this.topicCategories = this.topicService.codesAndCategories();
    this.getHomeData();
  }
  // PHP / SQL timestamps are 10-digit. JS uses 13-digit
  normalizeDateTime(timestamp: string) {
    if(timestamp.length == 10) {
      return parseInt(timestamp) * 1000;
    } else {
      return parseInt(timestamp);
    }
  }
  /*
    Titles sometimes include timestamps for some reason.
    Example Course Title: A Valuation Primer for Financial Reporting Webinar 2022-11-23 08:30 EST
   */
  normalizeTitle(title: string, method = 'default') {
    switch(method) {
      case 'length':
        // Can check word length and do it that way.
        var title_array = title.split(" ");
        if(title_array.length > 3) {
          // We might have a dateTime in the title, so remove it.
          title_array.length = title_array.length - 3;
          return title_array.join(" ");

        } else {
          return title;
        }
        break;
      default:
        // Can search for common indications that the title has a timestamp
        if(title.includes('-') && title.includes(':') && title.includes('EST')) {
          var title_array = title.split(" ");
          // We might have a dateTime in the title, so remove it.
          title_array.length = title_array.length - 3;
          return title_array.join(" ");
        } else {
          return title;
        }
        break;
    }

  }

  getHomeData() {
    this.topicService.getHomeData().subscribe( {
      next: (data) => {
        this.inDemandCourseData = data.inDemand;
        //console.log('home.component->getHomeData->data: ', data);

        // Process Upcoming Courses data
        this.upcomingConferencesData = data.upcomingConferences;
      },
      complete: () => {
        // console.log('home.component->getHomeData() complete. inDemandCourseData: ', this.inDemandCourseData);
        // console.log('in-demand-courses.widget->Filtered Categories: ', this.inDemandCourseData.categories.length);

        this.inDemandCourseDataLoaded = true;
        this.upcomingConferencesDataLoaded = true;
      }
    });
  }
  getSliderData() {
    // Jared says that we will most likely tag content to show up in the view.
    const headers = new HttpHeaders()
      .set("Content-Type", "application/vnd.api+json");

    this.http.get<CoursesResource>(environment.jsonApiUrl + '/views/front_page_slider_content/page_1', {headers}).subscribe((data) => {
      this.events = data;
      for (let i = 0; i < this.events.data.length; i++) {

        var title = this.normalizeTitle(this.events.data[i].attributes.title);

        //var startDateObj = new Date(this.normalizeDateTime(this.events.data[i].attributes.field_startdatetime));
        //var endDateObj = new Date(this.normalizeDateTime(this.events.data[i].attributes.field_enddatetime));

        this.events.data[i].attributes.title = title;
        //this.events.data[i].attributes.field_startdatetime = startDateObj;
        //this.events.data[i].attributes.field_enddatetime = endDateObj;

      }
    });
    this.http.get<TopicsResource>(environment.jsonApiUrl + '/views/front_page_slider_content/page_2', {headers}).subscribe((data) => {
      this.topics = data;
      for (let i = 0; i < this.topics.data.length; i++) {
        var title = this.normalizeTitle(this.topics.data[i].attributes.title);
        this.topics.data[i].attributes.title = title;
      }
    });
    this.http.get<CoursesResource>(environment.jsonApiUrl + '/views/front_page_slider_content/page_3', {headers}).subscribe((data) => {
      this.courses = data;
      for (let i = 0; i < this.courses.data.length; i++) {
        var title = this.normalizeTitle(this.courses.data[i].attributes.title);
        this.courses.data[i].attributes.title = title;
      }
    });
  }

  search(): void {
    this.router.navigate(['/search-results'], {
      queryParams: { textSearch: this.searchCoursesQuery },
    });
  }

}
