<div class="cpe-container search-results">
    <div class="sidebar" *ngIf="displayResponsiveSidebar">
        <ul>
            <li><span (click)="sidebarVisible = true">Filter Courses</span></li>
        </ul>
    </div>
    <div class="cpe-wrapper pb-6">
        <div class="grid">
            <div class="col-12 lg:col-4 left">

                <p-sidebar [(visible)]="sidebarVisible" position="left" styleClass="w-21rem">
                    <h3>Filter Courses</h3>
                    <app-search-widget [queryData]="queryData" style="position: relative;"></app-search-widget>
                </p-sidebar>
                <!-- <p-button (click)="sidebarVisible = true" *ngIf="displayResponsiveSidebar" label="Filter Courses" styleClass="mt-3 filter-courses"></p-button> -->
                
                <div *ngIf="!displayResponsiveSidebar" class="column-heading cpe-dark-blue-background align-content-center pl-3">
                    <h1>Filter Courses</h1>
                </div>
                <app-search-widget *ngIf="!displayResponsiveSidebar" [queryData]="queryData" style="position: relative;" ></app-search-widget>
            </div>
            <div class="col-12 lg:col-8 right">
                <div class="column-heading cpe-dark-blue-background align-content-center pl-3 pr-3" *ngIf="!displayResponsiveSidebar">
                    <h1>{{ categoryDetails['description'] }}</h1>
                </div>
                <div *ngIf="errorMsg" class="alert alert-danger mt-3 mb-0">{{errorMsg}}</div>

                <p-table [value]="returnedCourses" [paginator]="paginator && !loading" [rows]="25" [loading]="loading" *ngIf="!errorMsg" 
                class="search-result-table" [class.responsive-search-result-table]="displayResponsiveTableView"
                [class.h-full-loading]="loading">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="field_canonical_title">Course Title<p-sortIcon field="field_canonical_title"></p-sortIcon></th>
                            <th pSortableColumn="field_hours">Credits <p-sortIcon field="field_hours"></p-sortIcon></th>
                            <th>Format</th>
                            <th pSortableColumn="field_price">Price <p-sortIcon field="field_price"></p-sortIcon></th>
                            <th>&nbsp;</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-course>
                        <tr>
                            <td style="text-wrap: balance">
                                <span class="p-column-title" style="min-width: 100px; font-size: 20px;">{{ course.field_canonical_title}}</span>
                                {{ !displayResponsiveTableView ? course.field_canonical_title : '' }}
                            </td>
                            <td colspan="4" *ngIf="displayResponsiveTableView">
                                <span class="p-column-title">
                                  <p>{{ course.field_course_type ? course.field_course_type : '' }}</p>
                                  <p>{{ course?.field_hours ? course.field_hours : '' }} {{ course.field_hours  === 1.00 ? 'Credit' : 'Credits' }} |
                                    <span [class.line-through]="utm_source_discount_data?.topic_ids?.length && utm_source_discount_data?.topic_ids?.indexOf(course.field_topic_id) > -1">
                                      <span [class.mb-0]="utm_source_discount_data?.topic_ids?.length && utm_source_discount_data?.topic_ids?.indexOf(course.field_topic_id) > -1">${{ course.field_price ? course.field_price : '' }}</span>
                                    </span>
                                    <span *ngIf="utm_source_discount_data?.topic_ids?.length && utm_source_discount_data?.topic_ids?.indexOf(course.field_topic_id) > -1">
                                      <span class="ml-1">${{ course.field_price - (course.field_price * utm_source_discount_data.discount) }}</span>
                                    </span>
                                  </p>
                                </span>
                                <span class="p-column-title">
                                  &nbsp;
                                </span>
                                <span class="p-column-title">
                                  &nbsp;
                                </span>
                                <span class="p-column-title">
                                  <p>
                                    <a pButton pRipple label="View Details" id="course-{{course.uuid}}" 
                                    class="view-pass-details-btn font-bold white-space-nowrap" icon="pi pi-angle-right" iconPos="right"
                                    [routerLink]="['/'+course.field_canonical_url]"></a>
                                  </p>
                                </span>
                              </td>
                            <td *ngIf="!displayResponsiveTableView">
                                <span class="p-column-title">Credits</span>
                                {{ course?.field_hours ? (course.field_hours | number : '1.1-1') : '' }}
                            </td>
                            <td *ngIf="!displayResponsiveTableView">
                                <span class="p-column-title">Format</span>
                                {{ course.field_course_type ? course.field_course_type : '' }}
                            </td>
                            <td *ngIf="!displayResponsiveTableView">
                                <span class="p-column-title">Price</span>
                                <span [class.line-through]="utm_source_discount_data?.topic_ids?.length && utm_source_discount_data?.topic_ids?.indexOf(course.field_topic_id) > -1">
                                    {{ course.field_price ? ('$'+course.field_price) : '' }}
                                </span>
                                <span *ngIf="utm_source_discount_data?.topic_ids?.length && utm_source_discount_data?.topic_ids?.indexOf(course.field_topic_id) > -1">
                                    {{ ('$'+(course.field_price - (course.field_price * utm_source_discount_data.discount))) }}
                                </span>
                            </td>
                            <td *ngIf="!displayResponsiveTableView">
                                <a pButton pRipple label="View Details" id="course-{{course.uuid}}" class="font-bold white-space-nowrap text-white" [routerLink]="['/'+course.field_canonical_url]"></a>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

            </div>
        </div>
    </div>
</div>