import { Component, OnDestroy, OnInit } from '@angular/core';
import { TopicService } from '../_services/topic.service';
import { Subject, takeUntil } from 'rxjs';
import { SessionService } from '../_services/session.service';

@Component({
  selector: 'app-course-codes',
  templateUrl: './course-codes.component.html',
  styleUrls: ['./course-codes.component.scss'],
})
export class CourseCodesComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  courses: any[];
  selectedCourse: any;
  selectedCourseText: string;
  isTestData = true;

  constructor(
    private topicService: TopicService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    // TODO: Use the session service to get the user account information needed
    this.getCourseCodes();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  getCourseCodes() {
    if (this.isTestData) {
      // Right now, API is not setup, so just return a static list
      this.courses = [
        {
          course: 'Course Title',
          email: 'email@email.com',
          name: 'Good Student',
          courseType: 'Webinar',
          loginCode: 'ABC123',
        },
        {
          course: 'Course Title',
          email: 'email@email.com',
          name: 'Good Student',
          courseType: 'Self-Study',
          loginCode: '123ABC',
        },
        {
          course: 'Course Title',
          email: 'email@email.com',
          name: 'Good Student',
          courseType: 'Webinar',
          loginCode: 'XYZ987',
        },
      ];
    } else {
      this.topicService
        .getCourseCodes('1')
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data) => {},
          error: (err) => {
            console.error(err);
          },
          complete: () => {},
        });
    }
  }

  selectCourse(event: any) {
    this.selectedCourseText = `You selected ${event.data.name} in the ${event.data.courseType} "${event.data.course}" with login code ${event.data.loginCode}`;
    console.log(this.selectedCourseText);
  }
}
