import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../_services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private sessionService: SessionService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let url: string = state.url;
      return this.checkLogin(url);
    }

    checkLogin(url: string): boolean {
      let isLoggedIn: boolean = this.sessionService.isLoggedIn;
      console.log(`auth.guard:checkingLogin() is logged in ${isLoggedIn}`);

      if(!isLoggedIn){
        if(url == "/login") {
          return true;
        }
          this.router.navigateByUrl('');
          return false;
      } else {
        if(url == "/login") {
          this.router.navigateByUrl('');
          return false;
        }
         return true;
      }
   }
}
