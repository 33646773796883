<p-table
  [value]="courses"
  [paginator]="true"
  [rows]="5"
  [(selection)]="selectedCourse"
  (onRowSelect)="selectCourse($event)"
  selectionMode="single"
  responsiveLayout="scroll"
  styleClass="p-datatable-striped p-datatable-gridlines"
>
  <!-- <ng-template pTemplate="caption">
    <div class="table-header">
      <h1>Course Codes</h1>
    </div>
  </ng-template> -->
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="course" class="text-center">
        Course <p-sortIcon field="course"></p-sortIcon>
      </th>
      <th pSortableColumn="email" class="text-center">
        Email <p-sortIcon field="email"></p-sortIcon>
      </th>
      <th pSortableColumn="name" class="text-center">
        Name <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th pSortableColumn="courseType" class="text-center">
        Course Type <p-sortIcon field="courseType"></p-sortIcon>
      </th>
      <th pSortableColumn="loginCode" class="text-center">
        Login Code <p-sortIcon field="loginCode"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-course>
    <tr [pSelectableRow]="course">
      <td class="text-center">{{ course.course }}</td>
      <td class="text-center">{{ course.email }}</td>
      <td class="text-center">{{ course.name }}</td>
      <td class="text-center">{{ course.courseType }}</td>
      <td class="text-center">{{ course.loginCode }}</td>
    </tr>
  </ng-template>
</p-table>