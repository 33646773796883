<div id="cpe-top-content" class="cpe-container cpe-top-content">
  <div class="cpe-wrapper">
    <div class="grid flex w-full flex-wrap align-items-center justify-content-center">
      <div class="col-12 md:col-9 lg:col-9 xl:col-9 vertical-align-middle">
        <h1 class="cpe-dark-blue-text">The fastest path to great CPE.</h1>
        <h3>Find your course from our extensive CPE library, more copy...</h3>
        <span class="p-input-icon-left w-full md:w-10 lg:w-10 xl:w-10">
          <i class="pi pi-search" (click)="search()"></i>
          <input type="text" class="w-full mt-3 mb-5" pInputText placeholder="Search our 300+ courses" [(ngModel)]="searchCoursesQuery" (keydown.enter)="search()"/>
        </span>

        <div class="w-full search-people-action-buttons">
          <div class="grid">
            <div class="col-12 md:col-4 lg:col-4 xl:col-4">
              <a pButton label="Happening This Week" class="w-full font-bold" [routerLink]="['/search-results']" [queryParams]='{startDate: weekDates}' *ngIf="!isServer"></a>
              <p-button label="Happening This Week" class="w-full font-bold" [routerLink]="['/search-results']" [queryParams]='{startDate: weekDates}' *ngIf="isServer"></p-button>
            </div>
            <div class="col-12 md:col-4 lg:col-4 xl:col-4">
              <a pButton label="Buy a Pass" class="w-full font-bold" [routerLink]="['/passes']" *ngIf="!isServer"></a>
              <p-button label="Buy a Pass" class="w-full font-bold" [routerLink]="['/passes']" *ngIf="isServer"></p-button>
            </div>
            <div class="col-12 md:col-4 lg:col-4 xl:col-4">
              <a pButton label="Browse All Courses" class="w-full font-bold" [routerLink]="['/search-results']" *ngIf="!isServer"></a>
              <p-button label="Browse All Courses" class="w-full font-bold" [routerLink]="['/search-results']" *ngIf="isServer"></p-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 banner-img-col pt-0 pb-0">
        <div class="banner-img">
          <img src="../assets/cpe-hero.png" alt="">
        </div>
      </div>
    </div>
  </div>
</div>
<div id="explore-in-demand-courses" *ngIf="showWorkInProgressSections && inDemandCourseDataLoaded">
  <app-in-demand-courses-widget [inDemandCourseData]="inDemandCourseData"></app-in-demand-courses-widget>
</div>

<!-- #cpe-browse-courses -->
<div id="cpe-annual-cpe-passes" class="cpe-annual-cpe-passes pb-6">
  <div class="grid cpe-dark-blue-background relative m-0">
    <div class="cpe-container m-auto">
      <div class="cpe-wrapper annual-list">
        <div class="col-12 sm:col-12 md:col-4 flex align-items-center justify-content-start">
          <h1 class="p-0 m-0">ANNUAL<br/>CPE PASSES
            <div class="divider mb-0 pb-0 mt-2">
              <hr class="my-0 py-0">
            </div>
          </h1>
        </div>
        <div class="col-12 sm:col-12 md:col-5 flex align-items-center justify-content-center">
          <h3 class="py-0 my-0">
            Streamline your CPE needs<br/>
            with savings that last for a year.
          </h3>
        </div>
        <div class="col-12 sm:col-12 md:col-3 flex align-items-center justify-content-end">
          <a pButton label="View All passes" class="view-all-passes-btn" [routerLink]="['/courses/']" *ngIf="!isServer"></a>
          <p-button label="View All passes" class="view-all-passes-btn" [routerLink]="['/courses/']" *ngIf="isServer"></p-button>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="cpe-most-popular-courses" class="cpe-container pb-6 cpe-most-popular-courses">
  <div class="cpe-wrapper wrapper-full-width">
    <div class="grid container-full-width">
      <div class="col-12 md:col-6 p-3 card-box">
        <div class="grid p-3 border-1 course-offering-card">
          <div class="col-6">
            <h1 class="cpe-dark-blue-text-home">12,000+ <span class="d-block">COURSE OFFERINGS</span>
            </h1>
            <p>With the most course offerings in the industry, you'll find the course you want when you want it!</p>
            <a pButton label="View All Courses" class="font-medium p-0 border-none" icon="pi pi-angle-right" iconPos="right" [routerLink]="['/courses/']" *ngIf="!isServer"></a>
            <p-button label="View All Courses" class="font-medium p-0 border-none" icon="pi pi-angle-right" iconPos="right" [routerLink]="['/courses/']" *ngIf="isServer"></p-button>
          </div>
          <div class="col-6 course-box">
            <div class="img-box">
            <img src="assets/course-offerings-count.jpg" />
          </div>
        </div>
        </div>
      </div>
      <div class="col-12 md:col-6 p-3 card-box">
        <div class="grid p-3 border-1 course-guarantee-card">
          <div class="col-6">
            <h1 class="cpe-dark-blue-text-home">OUR <span class="d-block">ULTIMATE</span> <span class="d-block">GUARANTEE</span></h1>
            <p>With the most course offerings in the industry, you'll find the course you want when you want it!</p>
            <a pButton label="Learn More" class="font-medium p-0 border-none" icon="pi pi-angle-right" iconPos="right" [routerLink]="['/courses/']" *ngIf="!isServer"></a>
            <p-button label="Learn More" class="font-medium p-0 border-none" icon="pi pi-angle-right" iconPos="right" [routerLink]="['/courses/']" *ngIf="isServer"></p-button>
          </div>
          <div class="col-6 course-box">
            <div class="img-box">
            <img src="assets/guarantee.png" />
          </div>
        </div>
        </div>
      </div>
      <div class="col-12 popular-card">
        <h1>Our most popular CPE topics</h1>
        <h3>Relevant, impactful topics for CPAs presented by our network of industry experts</h3>
      </div>
      <div class="col-12" *ngIf="!isServer">
        <p-carousel styleClass="slider-container" [value]="popularCourses" [numVisible]="5" [numScroll]="1" [showIndicators]="false" [responsiveOptions]="responsiveOptions">
          <ng-template let-course pTemplate="item">
            <div class="product-item">
              <div class="mb-3 product-img-box">
                <a routerLink="/seminars/alltopics/{{course.category}}">
                  <img src="{{ 'assets/'+course.image }}" [alt]="course.title" class="product-image" />
                </a>
              </div>
              <div>
                  <h3 class="mb-1 course-title">{{ course.title }}</h3>
                  <h4 class="mt-0 mb-3 course-count">{{ course.count }} Courses</h4>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </div>
      <div class="col-12 flex justify-content-center flex-wrap">
        <a pButton label="View All Courses" class="view-all-courses-btn" [routerLink]="['/courses/']" *ngIf="!isServer"></a>
        <p-button label="View All Courses" class="view-all-courses-btn" [routerLink]="['/courses/']" *ngIf="isServer"></p-button>
      </div>
    </div>
  </div>
</div>
<div id="cpe-ethics-courses-selection" class="cpe-ethics-courses-selection">
  <div class="grid  cpe-ethics-background relative m-0">
    <div class="cpe-container m-auto text-center lg:pt-5 xl:pt-5 pb-5">
        <div class="cpe-wrapper">
          <div class="col-12 ">
            <h1>Ethics courses for all 50 states</h1>
            <p>Whether you prefer Live Webinars or Self-Study programs, we have an Ethics course that meets
              your state-specific needs. And with more dates and times than any other provider, we make it
              easy to satisfy your mandatory Ethics requirement in the way that is most convenient for you. </p>
            <a pButton label="Find Your Ethics Course" class="find-your-ethics-course-btn" [routerLink]="['/seminars/ethics']" *ngIf="!isServer"></a>
              <p-button label="Find Your Ethics Course" class="find-your-ethics-course-btn" [routerLink]="['/seminars/ethics']" *ngIf="isServer"></p-button>
          </div>
        </div>
    </div>
  </div>
</div>
<div *ngIf="showWorkInProgressSections && upcomingConferencesDataLoaded">
  <div id="cpe-take-a-seat" class="p-0 md:pt-5 lg:pt-5 xl:pt-5 cpe-container cpe-take-a-seat grey-background">
    <div class="cpe-wrapper ">
      <div class="grid flex flex-wrap">
        <div class="col-12 md:col-6 lg:col-6 xl-col-6">
          <h1>Take a seat at our expert-led Conferences</h1>
          <h3>Test test testing tested test test testing tested test
          test testing tested test test testing tested test test
          testing tested test test testing.</h3>
          <div class="w-full take-a-seat-action-buttons">
            <div class="grid">
              <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
                <a pButton label="Virtual Conferences" class="virtual-conf-btn font-bold" [routerLink]="['/courses']" *ngIf="!isServer"></a>
                <p-button label="Virtual Conferences" class="virtual-conf-btn font-bold" [routerLink]="['/courses']" *ngIf="isServer"></p-button>
              </div>
              <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
                <a pButton label="In-Person Conferences" class="in-person-conf-btn font-bold" [routerLink]="['/courses']" *ngIf="!isServer"></a>
                <p-button label="In-Person Conferences" class="in-person-conf-btn font-bold" [routerLink]="['/courses']" *ngIf="isServer"></p-button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 banner-img-col pt-0 pb-0 col-offset-2">
          <div class="banner-img">
            <img src="../assets/take-a-seat-section-image.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-upcoming-conferences-widget [upcomingConferencesData]="upcomingConferencesData"></app-upcoming-conferences-widget>
</div>

<app-explore-all-topics-widget></app-explore-all-topics-widget>

<div id="cpe-combo-pass" class="cpe-container cpe-combo-pass mb-3">
  <div class="cpe-wrapper">
    <div class="grid cobo-pass-bg">
      <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 combo-pass-title">
        <h1>CPE Combo Pass</h1>
        <h3>The most flexible annual pass option</h3>
      </div>
      <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 combo-pass-desc">
        <p>Access an extensive library of Webinars,
          Self-Study courses and Ethics programs
          to easily earn credits.
        </p>
        <p-button label="Learn More" styleClass="combo-pass-learn-more-btn font-bold p-0 border-none" icon="pi pi-angle-right" iconPos="right" routerLink="/courses"></p-button>
      </div>
    </div>
  </div>
</div>
<div id="cpe-by-the-numbers" class="cpe-by-the-numbers cpe-container relative cpe-banner-number mb-3">
  <div class="cpe-wrapper">
    <div class="grid">
      <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 flex align-items-center justify-content-center middle-content">
        <div class="grid">
          <div class="col-12">
            <h1 class="p-0 m-0">CPE INC. by the numbers</h1>
            <div class="divider">
              <hr>
            </div>
          </div>
          <div class="col-12">
            <p class="p-0 m-0 pb-3">Delivering impactful CPE courses for
              accounting and financial professionals
              nationwide since 1984</p>
          </div>
          <div class="col-12">
            <a pButton label="About us" class="about-us-btn" [routerLink]="['/courses']" *ngIf="!isServer"></a>
            <p-button label="About us" class="about-us-btn" [routerLink]="['/courses']" *ngIf="isServer"></p-button>
          </div>
        </div>
      </div>
      <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 flex align-items-center justify-content-center number-info">
        <div class="grid">
          <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
            <h1 class="cpe-dark-blue-text-home flex align-items-center justify-content-center">39</h1>
            <p class="m-0 flex align-items-center justify-content-center">Years of Excellence</p>
          </div>
          <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
            <h1 class="cpe-dark-blue-text-home flex align-items-center justify-content-center">12,000</h1>
            <p class="m-0 flex align-items-center justify-content-center">Courses Scheduled</p>
          </div>
          <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
            <h1 class="cpe-dark-blue-text-home flex align-items-center justify-content-center">50 States</h1>
            <p class="m-0 flex align-items-center justify-content-center">Our programs meet all state-specific requirements</p>
          </div>
          <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
            <h1 class="cpe-dark-blue-text-home flex align-items-center justify-content-center">390+</h1>
            <p class="m-0 flex align-items-center justify-content-center">Programs Available</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="cpe-testimonials" class="cpe-container cpe-testimonials pb-6 grey-background">
  <div class="cpe-wrapper wrapper-full-width">
    <div class="grid container-full-width">
      <div class="col-12 text-center p-6 cpe-testimonials-title">
        <h1>The go-to CPE source for CPAs since 1984</h1>
      </div>
      <div class="col-12 testimonials-slider" *ngIf="!isServer">
        <p-carousel styleClass="slider-container" [value]="testimonials" [numVisible]="2" [numScroll]="2" [showIndicators]="false">
          <ng-template let-testimonial pTemplate="item">
              <div class="testimonial">
                  <div class="testimonial-content">
                      <div class="mb-3 testimonial-rating">
                        <p-rating [(ngModel)]="testimonial.rating" [readonly]="true" [cancel]="false"></p-rating>
                      </div>
                      <div>
                          <h3 class="mb-1 testimonial-title cpe-dark-blue-text-home">{{ testimonial.title }}</h3>
                          <p class="mt-0 mb-3 testimonial-desc cpe-dark-blue-text-home">{{ testimonial.description }}</p>
                      </div>
                  </div>
              </div>
          </ng-template>
      </p-carousel>
      </div>
    </div>
  </div>
</div>
<div class="cpe-container">
  <div id="contactForm" class="cpe-wrapper pt-5">
    <app-stay-in-touch-contact-form></app-stay-in-touch-contact-form>
  </div>
</div>
