import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute, NavigationStart} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { MenuItem } from 'primeng/api';

import { SessionService, SessData } from './_services/session.service';
import {TopicResource, TopicsResource} from '../topic';
import { CoursesResource } from '../course';

import { environment } from '../environments/environment';
import {CartService} from "./_services/cart.service";
import { SEOService } from './_services/seo.service';
import { CourseDetails, TopicService } from './_services/topic.service';
import MetaTags from '../assets/meta-tags.json';
import { SearchService } from './_services/search.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [SessionService]
})
export class AppComponent implements OnInit {
  // Detect if this is IE
  //isIE = /msie\s|trident\//i.test(window.navigator.userAgent);//This caused an error:ERROR ReferenceError: window is not defined
  isIE = false;
  path = '';
  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private cart: CartService,
    private topicService: TopicService,
    private seoService: SEOService,
    public router: Router,
    public route: ActivatedRoute,
    private searchService: SearchService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {}

  heroHeading: string;
  heroMessage: string;
  sessData: SessData;
  menuData: MenuItem[] | null = null;
  topics: TopicsResource | null = null;
  topic: TopicResource | null = null;
  courses: CoursesResource | null = null;
  course: CoursesResource | null = null;
  courseDetails: CourseDetails;
  displayAdminMenu: boolean = false;
  adminMenuOptions: any[] = [{
    title: "Edit Topic",
    link: ""
  }]
  ngOnInit() {

    // For testing - never start with an access token
    // this.sessionService.saveAccessToken('');
    // console.log(`Cleared access token for testing`);

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.path = e.url;
        // console.log(this.path);
        this.displayAdminMenu = false;
        if(this.path === '/') {
          this.heroHeading = 'Quality Education';
          this.heroMessage = 'That Will Give You The Edge You Need To Succeed.';
          this.seoService.updateTitle(this.heroHeading);

          this.seoService.removeTag("name='description'");
          this.seoService.removeTag("name='keywords'");
          this.seoService.removeCanonicalUrl();

        } else if(this.route?.['_routerState']?.snapshot?.['_root']?.children[0]?.value?.params?.query){
          let matchMetaData = undefined;
          let query = this.route?.['_routerState']?.snapshot?.['_root']?.children[0]?.value?.params?.query;
          if(MetaTags[query]) {
            matchMetaData = MetaTags[query];
          } else {
            let keys = Object.keys(MetaTags);
            let matchIndex = keys.findIndex((key: string) => MetaTags[key]['id'] == query);

            if(matchIndex !== -1) {
              matchMetaData = MetaTags[keys[matchIndex]];
            } else {
              // Now Here we need to put logic to call an API of course details to get meta data
            }
          }
          console.log("matchMetaData", matchMetaData)
          if (matchMetaData) {
            this.seoService.updateDescription(matchMetaData.field_meta_description);
            this.seoService.updateKeywords(matchMetaData.field_meta_keywords);
            this.seoService.updateCanonicalUrl(matchMetaData.field_canonical_url);
          }
        } else {

          this.seoService.removeTag("name='description'");
          this.seoService.removeTag("name='keywords'");
          this.seoService.removeCanonicalUrl();

        }
      }
    });

    this.sessionService.currentData.subscribe((data) => {
      if (isPlatformBrowser(this.platformId)) {
        setTimeout(() => {
          if (!data || data.currentPage.heading == '') {
            // We don't have a valid page title yet.
            return;
          }

          // We heard a valid message
          this.sessData = data;
          // console.log('app.component->sessData: ', data);

          // React to the message
          if(data.currentPage.heading?.split(" | ")?.length > 1) {
            this.heroHeading = data.currentPage.heading?.split(" | ")[0]
          } else {
            this.heroHeading = data.currentPage.heading;
          }
          this.heroMessage = data.currentPage.message;

          this.seoService.updateTitle(data.currentPage.heading);
        });
      }
    });

    this.topicService.currentCourseDetails.subscribe({
      next: (data) => {
        if(data?.entity === undefined || data?.topic?.field_canonical_title === null) {
          this.displayAdminMenu = false;
          return;
        }
        this.courseDetails = data;
        let user_data:any = this.sessionService.getUserData() ? JSON.parse(this.sessionService.getUserData()) : "";
        this.displayAdminMenu = false;
        let has_administator_role =  user_data?.roles?.findIndex((role: string) => role == "administrator");
        if(has_administator_role !== -1 && has_administator_role !== undefined) {
          this.adminMenuOptions[0]['link'] = environment.baseUrl+"/node/"+this.courseDetails.topic.nid+"/edit"
          this.displayAdminMenu = true;
        }
      }
    });
    //this.sessData = this.session.getSessData();
    //this.heroHeading = this.sessData.current_page.title;
    //this.heroHeading = this.sessionService.getStorageItem({storageArea: 'sessionStorage', key: 'page_title'});
    //this.getTopics();
    //this.getCourses();
    this.getMenu();
  }

  getTopics() {

    const headers = new HttpHeaders()
      .set("Content-Type", "application/vnd.api+json");

    this.http.get<TopicsResource>(environment.jsonApiUrl + '/node/topic', {headers}).subscribe((data) => {
      this.topics = data;
    })
  }
  getTopic(entity_id:string) {

    const headers = new HttpHeaders()
      .set("Content-Type", "application/vnd.api+json");

    this.http.get<TopicResource>(environment.jsonApiUrl + '/node/topic/' + entity_id, {headers}).subscribe((data) => {
      this.topic = data;
    })
  }
  getCourses() {

    const headers = new HttpHeaders()
      .set("Content-Type", "application/vnd.api+json");

    this.http.get<CoursesResource>(environment.jsonApiUrl + '/node/course', {headers}).subscribe((data) => {
      this.courses = data;
    })
  }
  getCourse(entity_id:string) {

    const headers = new HttpHeaders()
      .set("Content-Type", "application/vnd.api+json");

    this.http.get<TopicsResource>(environment.jsonApiUrl + '/node/course/' + entity_id, {headers}).subscribe((data) => {
      this.topics = data;
    })
  }
  getMenu(): void {
    let topicCategoryList = this.topicService.codesAndCategories().map((cat:any) => {
      return {label: cat.description, command: e => this.topicCategoryDetails(cat) }
    });
    this.menuData = [
      {
        label: 'Courses',
        items: [
          {
            label: 'Topics',
            items: [
              {
                label: 'List of Topics',
                items: topicCategoryList
              },
              {
                label: 'All Topics',
                routerLink: 'topics'
              },
            ]
          },
          {
            label: 'Format',
            items: [
              {
                label: 'Live Webinars',
                command: e => this.router.navigateByUrl("/search-results?formats=webinar")
              },
              {
                label: 'Self-Study',
                command: e => this.router.navigateByUrl("/search-results?formats=self-study")
              },
              {
                label: 'Virtual Conferences',
                command: e => this.router.navigateByUrl("/search-results?formats=virtual conference")
              },
              {
                label: 'In-Person Conferences',
                command: e => this.router.navigateByUrl("/search-results?formats=conference")
              },
              {
                label: 'In-Person Seminars',
                command: e => this.router.navigateByUrl("/search-results?formats=seminar")
              },
            ]
          }
        ]
      },
      {
        label: 'Ethics',
        routerLink: 'seminars/ethics'
      },
      {
        label: 'Passes',
        items: [
          {
            label: 'Evening & Weekend',
            command: e => this.router.navigateByUrl("/search-results?pass=ew")
          },
          {
            label: 'Anytime',
            command: e => this.router.navigateByUrl("/search-results?pass=anytime")
          },
          {
            label: 'Self-Study',
            command: e => this.router.navigateByUrl("/search-results?pass=ss")
          },
          {
            label: 'Combo',
            command: e => this.router.navigateByUrl("/search-results?pass=combo")
          }

        ]
      },
      {label: 'Resources'},
    ];
  }

  private topicCategoryDetails(categoryData): void {
    this.searchService.searchSubject.next(false);
    this.router.navigateByUrl("/seminars/alltopics/"+categoryData.category)
  }
}
