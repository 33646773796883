import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LuxonModule } from 'luxon-angular';
import { HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { BadgeModule } from "primeng/badge";
import { ToolbarModule } from 'primeng/toolbar';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PasswordModule } from 'primeng/password';
import { AccordionModule } from 'primeng/accordion';
import { CarouselModule } from 'primeng/carousel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { RatingModule } from 'primeng/rating';
import { TooltipModule } from 'primeng/tooltip';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { TagModule } from 'primeng/tag';
import { ListboxModule } from 'primeng/listbox';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { SelectButtonModule } from 'primeng/selectbutton';
import { MainLayoutModule } from './main-layout/main-layout.module';
import { HomeComponent } from './home/home.component';
import { TopicsComponent } from './topics/topics.component';
import { WebinarsComponent } from './webinars/webinars.component';
import { UserAccountComponent } from './user-account/user-account.component';

import { SearchService } from './_services/search.service';
import { SearchResultsComponent } from './search-results/search-results.component';
import { DropdownModule } from 'primeng/dropdown';
import { EmailTemplatesModule } from './email-templates/email-templates.module';
import { CommonModule } from '@angular/common';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { StepsModule } from 'primeng/steps';
import { InputMaskModule } from 'primeng/inputmask';
import { ToastModule } from 'primeng/toast';
import { SidebarModule } from 'primeng/sidebar';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';

import { interceptors } from './_helpers/interceptors';
import { TopicsByCategoryComponent } from './topics-by-category/topics-by-category.component';
import { EthicsCoursesComponent } from './ethics-courses/ethics-courses.component';
import { CartDevComponent } from './cart-dev/cart-dev.component';
import { CourseCodesComponent } from './course-codes/course-codes.component';
import { PassesComponent } from './passes/passes.component';
import { CourseCodesListComponent } from './admin/course-codes-list/course-codes-list.component';
import { CourseStateListComponent } from './course-state-list/course-state-list.component';
import { CoursesByStateComponent } from './courses-by-state/courses-by-state.component';
import { ContentPageComponent } from './content-page/content-page.component';

import * as Sentry from "@sentry/angular-ivy";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TopicsComponent,
    WebinarsComponent,
    UserAccountComponent,
    SearchResultsComponent,
    ContactUsComponent,
    TopicsByCategoryComponent,
    EthicsCoursesComponent,
    CartDevComponent,
    CourseCodesComponent,
    PassesComponent,
    CourseCodesListComponent,
    CourseStateListComponent,
    CoursesByStateComponent,
    ContentPageComponent
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    LuxonModule,
    HttpClientModule,
    MenubarModule,
    BadgeModule,
    ToolbarModule,
    SelectButtonModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    PasswordModule,
    AccordionModule,
    ListboxModule,
    CalendarModule,
    MultiSelectModule,
    PanelModule,
    CarouselModule,
    ProgressSpinnerModule,
    ScrollPanelModule,
    TableModule,
    TabViewModule,
    ButtonModule,
    CardModule,
    TagModule,
    CheckboxModule,
    RatingModule,
    TooltipModule,
    RadioButtonModule,
    StepsModule,
    MainLayoutModule,
    EmailTemplatesModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    InputMaskModule,
    ToastModule,
    SidebarModule,
    DialogModule,
    InputSwitchModule
  ],
  providers: [
    SearchService,
    interceptors,
    MessageService,
    [
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: true,
        }),
      },
    ],
    provideClientHydration(),
    provideHttpClient(withFetch())
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
