<div class="grid">
    <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
      <h2 class="email-subscribe-title">Stay in touch with CPE I<span class="small-text">NC</span>.!</h2>
      <div class="divider">
        <hr>
      </div>
      <h3 class="contact-form-subheader">
        <span class="high-light-text">Sign up</span> for our emails and be the <span class="high-light-text">first one</span> to know
        about <span class="high-light-text">new courses</span>, <span class="high-light-text">special offers</span>,
        and more.
      </h3>
    </div>
    <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6 pt-6 cpe-subscribe-inputs-container">
      <form [formGroup]="stayInTouchForm" autocomplete="off">
        <div class="field lg:px-8 xl:px-8">
          <input formControlName="email" type="text" class="w-full" pInputText placeholder="Email Address"/>
        </div>
        <div class="field lg:px-8 xl:px-8">
          <p-calendar  *ngIf="!isServer" formControlName="licenseExpiration" styleClass="w-full" dateFormat="mm/dd/yy"
            dataType="string" [showIcon]="true" placeholder="CPA License Expiration (MM/DD/YYYY)" dateFormat="mm/dd/yy"
            appendTo = 'body'></p-calendar>
        </div>
        <div class="field lg:px-8 xl:px-8 p-fluid align-content-center">
          <p-multiSelect formControlName="areaOfInterest" name="areaOfInterest" [options]="interest_items" optionLabel="label"
      optionValue="value" [showHeader]="false" [showClear]="true" placeholder="Select Area(s) of Interest"
      [filter]="false" [resetFilterOnHide]="true" (onClear)="stayInTouchForm.controls['areaOfInterest'].setValue([])"
      styleClass="p-dropdown"></p-multiSelect>
        </div>
        <div class="flex justify-content-center">
          <div class="flex p-3">
            <p-button styleClass="p-button-lg subscribe-btn" label="Subscribe to Email" (click)="subscribe()"></p-button>
          </div>
        </div>
      </form>
    </div>
  </div>
