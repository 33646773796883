<span class="preheader">{{ headerText }}</span>
<table
  class="email-wrapper"
  width="100%"
  cellpadding="0"
  cellspacing="0"
  role="presentation"
>
  <tr>
    <td align="center">
      <table
        class="email-content"
        width="100%"
        cellpadding="0"
        cellspacing="0"
        role="presentation"
      >
        <tr>
          <td class="email-masthead">
            <table>
              <tr>
                <td>
                  <img
                    src="../../../assets/cpelogo.svg"
                    width="120"
                    alt="CPE Logo"
                    border="0"
                    style="
                      height: auto;
                      background: #dddddd;
                      font-family: sans-serif;
                      font-size: 15px;
                      line-height: 15px;
                      color: #555555;
                    "
                  />
                </td>
                <td>
                  <h1 class="my-0 py-0">CPE INC.</h1>
                  <br />
                  <h2>America's Leader in Continuing Professional Education</h2>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <!-- Email Body -->
        <tr>
          <td class="email-body" width="100%" cellpadding="0" cellspacing="0">
            <table
              class="email-body_inner"
              align="center"
              style="width: 100%"
              cellpadding="0"
              cellspacing="0"
              role="presentation"
            >
              <!-- Body content -->
              <tr>
                <td class="content-cell">
                  <div class="f-fallback">
                    <!-- {{body}} -->
                    <div>
                      <app-account-registration></app-account-registration>
                    </div>
                    <!-- Sub copy -->
                    <table class="body-sub" role="presentation">
                      <tr>
                        <td>
                          <p class="f-fallback sub">
                            Please note: This email message is an automated notification. Please do not reply to this message.
                          </p>
                          <!-- <p class="f-fallback sub">action_url</p> -->
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <div *ngIf="emailFooter">
              <table
                class="email-footer"
                align="center"
                width="570"
                cellpadding="0"
                cellspacing="0"
                role="presentation"
              >
                <tr>
                  <td class="content-cell" align="center">
                    <p class="f-fallback sub align-center">
                      &copy; 2022 CPE, Inc. All rights reserved.
                    </p>
                    <p class="f-fallback sub align-center">
                      <a href="https://cpeonline.com">CPE, Inc.</a>
                      <br />1234 Street <br />City, State 12345
                      <br />1.800.544.1114
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
