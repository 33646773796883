import {HostListener, Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, NavigationStart, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { CheckoutComponent} from './checkout/checkout.component';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuardGuard  {

  lastNavigationTrigger = '';

  // public popstateHappened() {
  //   console.log('popstate called function popstateHappened()');
  //   this.lastNavigationTrigger = 'popstate';
  // }

  constructor(
    public router: Router,
    ) {
    console.log('can deactivate constructor');

    // this.lastNavigationTrigger = 'na';

    // window.addEventListener('popstate', function(){
    //   console.log("User clicked the browser buttons popstate");
    // });

    // window.addEventListener('popstate', this.popstateHappened);

    // router.events
    //   .pipe()
    //   .subscribe(
    //     ( event: NavigationStart ) => {
    //
    //       console.group( "NavigationStart Event" );
    //       // Every navigation sequence is given a unique ID. Even "popstate"
    //       // navigations are really just "roll forward" navigations that get
    //       // a new, unique ID.
    //       console.log( "navigation id:", event.id );
    //       console.log( "route:", event.url );
    //       // The "navigationTrigger" will be one of:
    //       // --
    //       // - imperative (ie, user clicked a link).
    //       // - popstate (ie, browser controlled change such as Back button).
    //       // - hashchange
    //       // --
    //       // NOTE: I am not sure what triggers the "hashchange" type.
    //       console.log( "trigger:", event.navigationTrigger );
    //       // if(this.lastNavigationTrigger === '') {
    //       //   this.lastNavigationTrigger = event.navigationTrigger;
    //       // }
    //
    //
    //       // This "restoredState" property is defined when the navigation
    //       // event is triggered by a "popstate" event (ex, back / forward
    //       // buttons). It will contain the ID of the earlier navigation event
    //       // to which the browser is returning.
    //       // --
    //       // CAUTION: This ID may not be part of the current page rendering.
    //       // This value is pulled out of the browser; and, may exist across
    //       // page refreshes.
    //       if ( event.restoredState ) {
    //
    //         console.warn(
    //           "restoring navigation id:",
    //           event.restoredState.navigationId
    //         );
    //
    //       }
    //
    //       console.groupEnd();
    //
    //     }
    //   );


  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;

  }

  // @HostListener("window:beforeunload") aboutToUnload() {
  //   console.log(`We're going to unload`);
  // }
  // @HostListener('window:popstate') onPopState(event: any) {
  //   console.log('Event', event);
  // }

  canDeactivate(
    component: CheckoutComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    // This function is not needed but the scaffolding was put in place so we're going to leave it for now
    // This function can prevent navigation away from the component from either the back button or a link
    return true;

    //
    // Conceptionally this worked to block leaving the component but it couldn't differentiate between when a link
    // as clicked and when a popstate back button event occurred. I had attempted to have the component report
    // what navigation event occurred but by then I found that using pushstate and popstate state worked just as well
    // without the complication of a guard
    //

    /*
    let nextURL = nextState.url;

    let canDeactivateCondition = false;

    // if (component.lastNavigationTrigger !== 'popstate') {
    //   component.lastNavigationTrigger = '';
    //   return true;
    // }

    if(component.activeCheckoutStepIndex === 0) {
      debugger;
      canDeactivateCondition = true;
    }

    if(component.activeCheckoutStepIndex === 1) {
      console.log(`Going back to showCHeckoutInfo`);
      component.showCheckoutInfo();
    }

    if(component.activeCheckoutStepIndex === 2) {
      console.log(`Going back to showContractInfo`);
      component.showContactInfo();
    }

    if(component.activeCheckoutStepIndex === 3) {
      console.log(`Going back to showBillingInfo`);
      component.showBillingInfo();
    }

    if(canDeactivateCondition) {
      // component.lastNavigationTrigger = '';
      return true;
    } else {

      const currentUrl = currentState.url;
      if (window.location.href.endsWith('/checkout')) {
        // https://github.com/angular/angular/issues/13586
        this.router.navigate([currentUrl], { skipLocationChange: true });
      } else {
        // A browser button has been clicked or location.back()/forward() invoked. Restore browser history
        console.log(`Calling pushState`);
        history.pushState(null, null, location.href);
      }

      return false;
    }

   */
  }

}
