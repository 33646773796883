import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { TopicService } from 'src/app/_services/topic.service';
import {isPlatformServer} from '@angular/common';

@Component({
  selector: 'app-stay-in-touch-contact-form',
  templateUrl: './stay-in-touch-contact-form.component.html',
  styleUrls: ['./stay-in-touch-contact-form.component.scss']
})
export class StayInTouchContactFormComponent implements OnInit {
  email: string | null = null;
  license_expire: string | null = null;
  interest: string | null = null;
  interest_items: Array<any> = [
    {
      label: 'Auditing',
      value: 'Auditing'
    },
    {
      label: 'Financial Accounting',
      value: 'Financial Accounting'
    },
    {
      label: 'Individual & Corporate Taxation',
      value: 'Individual & Corporate Taxation'
    },
    {
      label: 'International',
      value: 'International'
    },
    {
      label: 'Management Advisory Services',
      value: 'Management Advisory Services'
    },
    {
      label: 'Multistate & Local Taxation',
      value: 'Multistate & Local Taxation'
    },
    {
      label: 'Not-for-Profit & Governmental Accounting & Auditing',
      value: 'Not-for-Profit & Governmental Accounting & Auditing'
    },
    {
      label: 'Partnership Taxation',
      value: 'Partnership Taxation'
    },
    {
      label: 'SEC Reporting',
      value: 'SEC Reporting'
    }
  ];

  stayInTouchForm: FormGroup;

  isServer = isPlatformServer(this.platformId);

  constructor(
    private fb: FormBuilder,
    private topicService: TopicService,
    private messageService: MessageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    )
     { }

  ngOnInit(): void {
    this.stayInTouchForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      licenseExpiration: [''],
      areaOfInterest: [[]]
    });
  }

  subscribe(): void {
    if(this.stayInTouchForm.valid) {
      this.topicService.emailSubscribe(this.stayInTouchForm.value).subscribe({
        next: (data) => {
          console.log(data)
          this.messageService.add({
            severity: "success",
            detail: "Thanks for stay in touch with CPE.",
          });
        },
        error: (error) => {
          this.messageService.add({
            severity: "error",
            detail: "Something went wrong please try again.",
          });
        },
        complete: () => {
          this.stayInTouchForm.reset();
        }
      })
      console.log(this.stayInTouchForm.value)
    } else {
      this.messageService.add({
        severity: "error",
        detail: "Please fill valid data and try again.",
      });
    }
  }
}
