import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SessData, SessionService } from '../_services/session.service';
import { TopicService } from '../_services/topic.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-courses-by-state',
  templateUrl: './courses-by-state.component.html',
  styleUrls: ['./courses-by-state.component.scss']
})
export class CoursesByStateComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  public sessData: SessData | null;
  
  public courses_list_data: any = undefined;

  constructor(
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private topicService: TopicService
  ) { }

  ngOnInit(): void {
    // this.sessionService.sessData['currentPage']['heading'] = 'CPE Courses For Your State Requirements';
    // this.sessionService.setSessData(this.sessData)

    this.subs.push(
      this.route.params.subscribe((queryParams) => {
        console.log(queryParams)
        let state_slug = queryParams['state_slug']
        let state_slug_array = state_slug.split("-");
        let state_name = state_slug_array[state_slug_array.length - 1]
        // this.sessData.currentPage.heading = this.categoryDetails['description'];
        this.getCourseByState(state_name);
      })
    );
  }
  
  private getCourseByState(state_name: string): void {
    this.topicService.getCoursesByState(state_name).subscribe((data) => {
      if(data) {
        this.courses_list_data = data;
      }
    });
  }

  ngOnDestroy(): void {
    // this.subs.unsubscribe();
  }

}
