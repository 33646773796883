import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from './filter.pipe';
import {
  CPEFormattedDateOnlyPipe,
  CPEFormattedTimeOnlyPipe,
  CpeHtmlEntitiesEncodePipe,
} from './cpe-html-entities-encode.pipe';

@NgModule({
  declarations: [
    FilterPipe,
    CpeHtmlEntitiesEncodePipe,
    CPEFormattedDateOnlyPipe,
    CPEFormattedTimeOnlyPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    FilterPipe,
    CpeHtmlEntitiesEncodePipe,
    CPEFormattedDateOnlyPipe,
    CPEFormattedTimeOnlyPipe,
  ]
})
export class PipesModule {}
