import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SessionService } from 'src/app/_services/session.service';
import { TopicService } from 'src/app/_services/topic.service';


@Component({
  selector: 'app-course-codes-list',
  templateUrl: './course-codes-list.component.html',
  styleUrls: ['./course-codes-list.component.scss']
})

export class CourseCodesListComponent implements OnInit, OnDestroy {

  searchTopic:string = "";
  searchName:string = "";
  searchEmail:string = "";
  currentPage: number = 1;
  private destroy$ = new Subject<void>();
  courses: any[];
  loading: boolean = false;

  constructor(
    private topicService: TopicService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.sessionService.sessData.currentPage.heading = "Admin Course Codes"
    // TODO: Use the session service to get the user account information needed
    this.getCourseCodes();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  getCourseCodes(): void {
    this.loading = true;

    let searchParams = {
      search_topic : this.searchTopic,
      search_name: this.searchName,
      search_email: this.searchEmail
    }

    this.topicService
      .getAllCourseCodes(this.currentPage, searchParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any[]) => {
          data.forEach((item) => {
            item.full_name = `${item.first_name} ${item.last_name}`;
          });
          this.courses = data;
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
          console.error(err);
        },
        complete: () => {},
      });
  }

  searchCourseCode(): void {
    this.currentPage = 1;
    this.getCourseCodes();
  }
  clear(): void {
    this.currentPage = 1;
    this.searchTopic = "";
    this.searchName = "";
    this.searchEmail = "";
    this.getCourseCodes();
  }
}
