<div id="cpe-explore-all-topics" class="pt-6 pb-6 cpe-container cpe-explore-all-topics">
    <div class="cpe-wrapper">
      <div class="grid">
        <div class="col-12 heading-card">
          <h1 class="cpe-dark-blue-text-home">Explore all of our topics</h1>
          <h3>Relevant, impactful topics for CPAs presented by our network of industry experts</h3>
        </div>
        <div class="col-12 topic-categories">
          <div class="grid">
            <div class="sm:col-4 md:col-4 lg:col-4 xl:col-4 col-6">
              <h4>Accounting & Auditing <i class="pi pi-angle-right"></i></h4>
              <div class="w-full">
                <a routerLink="/seminars/alltopics/{{category.category}}" class="pb-2 w-full cursor-pointer" *ngFor="let category of topicCategories | filter:'AA':'type'" [class.hidden]="category.type == category.category" [class.inline-block]="category.type != category.category">{{ category.description }}</a>
              </div>
            </div>
            <div class="sm:col-4 md:col-4 lg:col-4 xl:col-4 col-6">
              <h4>State-Specific Ethics <i class="pi pi-angle-right"></i></h4>
              <div class="w-full">
                <a routerLink="/seminars/alltopics/{{category.category}}" class="pb-2 w-full cursor-pointer" *ngFor="let category of topicCategories | filter:'ETHICS':'type'" [class.hidden]="category.type == category.category" [class.inline-block]="category.type != category.category">{{ category.description }}</a>
              </div>
              <h4>Taxation <i class="pi pi-angle-right"></i></h4>
              <div class="w-full">
                <a routerLink="/seminars/alltopics/{{category.category}}" class="pb-2 w-full cursor-pointer" *ngFor="let category of topicCategories | filter:'TAX':'type'" [class.hidden]="category.type == category.category" [class.inline-block]="category.type != category.category">{{ category.description }}</a>
              </div>
            </div>
            <div class="sm:col-4 md:col-4 lg:col-4 xl:col-4 col-12">
              <h4>Other <i class="pi pi-angle-right"></i></h4>
              <div class="w-full">
                <a routerLink="/seminars/alltopics/{{category.category}}" class="pb-2 w-full cursor-pointer" *ngFor="let category of topicCategories | filter:'OTHER':'type'" [class.hidden]="category.type == category.category" [class.inline-block]="category.type != category.category">{{ category.description }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>