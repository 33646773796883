import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, map, switchMap, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';

@Injectable()
export class DevInterceptor implements HttpInterceptor {
  constructor(private messageService: MessageService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (environment.production === false) {
      // Add the withCredentials so localhost development works with login sessions
      const devRequest = request.clone({
        withCredentials: true
      });

      return next.handle(devRequest).pipe(
        map((event: HttpEvent<any>) => {
          if(event instanceof HttpResponse && (
            event.url == `${environment.baseUrl}/cart/get?_format=json` ||
            event.url == `${environment.baseUrl}/cart/add?_format=json`
            )) {

            // If the cart response is empty and contains an object with no keys then an error occurred.
            if(Object.keys(event).length == 0) {
              this.handleError(new HttpErrorResponse({error: {message: "Something went wrong, Please try again."}}))
            }
          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      )
    } else {
      // Do not add withCredentials to production environment because
      // everything happens on the same domain.
      return next.handle(request).pipe(map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log(event, "event")
            event = event.clone({body: event.body});
        }
        return event;
    }), catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      }))
    }
  }

  handleError(error: HttpErrorResponse) {
    let errorMsg = '';
    if (error?.error?.message) {
      // console.log('This is client side error');
      errorMsg = `Error: ${error.error.message}`;
    } else {
      // console.log('This is server side error');
      errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
    }
    if(errorMsg !== "Error: This route can only be accessed by anonymous users.") {
      this.messageService.add({
        severity: "error",
        detail: errorMsg,
      });
    }
    return throwError(errorMsg);
  }
}
