import { Component, OnInit } from '@angular/core';
import { SessData, SessionService } from '../_services/session.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-passes',
  templateUrl: './passes.component.html',
  styleUrls: ['./passes.component.scss']
})
export class PassesComponent implements OnInit {
  
  private subs: Subscription[];
  public sessData: SessData | null = null;
  
  constructor(
    private sessionService: SessionService
  ) {
    this.subs = [];
  }

  ngOnInit(): void {
    // Get current session data
    this.subs.push(
      this.sessionService.currentData.subscribe((data) => {
        this.sessData = data;
        if(this.sessData.currentPage.heading != 'Passes') {
          this.sessData.currentPage.heading = 'Passes';
          this.sessionService.setSessData(this.sessData)
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
    for (let i = 0; i < this.subs.length; i++) {
      this.subs.pop();
    }
  }
}
