<div *ngIf="!orderDetailsLoading else spinner">
  <!-- Purchasing Information. This displays at the last step of the checkout process. -->
  <!-- can be viewed http://localhost:4200/account-email-test?id=19 -->

<!--  <pre> {{orderDetails | json}}</pre>-->

  <p-card>
    <ng-template pTemplate="header">
      <div style="color: white; background-color: #006699">
        <h2 class="m-0 p-0">Registration Information</h2>
      </div>
    </ng-template>
    <div class="grid">
      <div class="col-4">

          <strong>Billing Address ({{ orderDetails.billingAddress.addressPurpose }}):</strong><br />
          {{ orderDetails.billingAddress.firstName }} {{ orderDetails.billingAddress.lastName }}<br />
          {{ orderDetails.billingAddress.company }}<br />
          {{ orderDetails.billingAddress.street1 }} {{ orderDetails.billingAddress.street2 }}<br />
          {{ orderDetails.billingAddress.city }}, {{ orderDetails.billingAddress.state }}
          {{ orderDetails.billingAddress.zip }}, {{ orderDetails.billingAddress.country }}

        <p>
          <strong>Phone:</strong> {{ orderDetails.billingAddress.phone }} ({{ orderDetails.billingAddress.phonePurpose }})<br />
<!--          <strong>Email Address:</strong> {{ orderDetails.billingAddress.email }}-->
        </p>
      </div>
      <div class="col-4">
          <strong>Contact Address:</strong><br />
          {{ orderDetails.contactAddress.firstName }} {{ orderDetails.contactAddress.lastName }}<br />
          {{ orderDetails.contactAddress.company }}<br />
          {{ orderDetails.contactAddress.street1 }} {{ orderDetails.contactAddress.street2 }}<br />
          {{ orderDetails.contactAddress.city }}, {{ orderDetails.contactAddress.state }}
          {{ orderDetails.contactAddress.zip }}, {{ orderDetails.contactAddress.country }}
        <p><strong>Email Address:</strong> {{ orderDetails.contactAddress.email }}</p>
      </div>
      <!-- <div class="col-4">
        <p>
          <strong>Work Address:</strong><br />
          {{ workAddress.name }}<br />
          {{ workAddress.company }}<br />
          {{ workAddress.address }}<br />
          {{ workAddress.city }}, {{ workAddress.state }}
          {{ workAddress.zipcode }}
        </p>
        <p>
          <strong>Employee ID:</strong> {{ workAddress.employeeID }}<br />
          <strong>Email Address:</strong> {{ workAddress.email }}
        </p>
      </div> -->
      <div class="col-4">
        <div class="grid grid-nogutter">
          <!-- <div class="col-6 grid-nogutter">
            <p class="m-0 p-0 my-1"><strong>Subtotal: </strong></p>
          </div>
          <div class="col-6 grid-nogutter">
            <p class="m-0 p-0 my-1">
              {{ orderDetails.extTotal | currency: 'USD':'symbol':'1.2-2' }}
            </p>
          </div> -->
          <div class="col-6 grid-nogutter">
            <p class="m-0 p-0 my-1">
              <strong>Registration Total: </strong>
            </p>
          </div>
          <div class="col-6 grid-nogutter">
            <p class="m-0 p-0 my-1">
              {{ orderDetails.totalDue | currency: 'USD':'symbol':'1.2-2'}}
            </p>
          </div>
          <div class="col-6 grid-nogutter">
            <p class="m-0 p-0 my-1"><strong>Payment Method: </strong></p>
          </div>
          <div class="col-6 grid-nogutter">
            <p class="m-0 p-0 my-1">{{ orderDetails?.paymentMethod?.displayName }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Requested to be turned off since already shown basic information in top right corner -->

<!--    <div class="grid">-->
<!--      <div class="col-4">-->
<!--        <strong>Payment Information:</strong><br />-->

<!--        <div *ngIf="orderDetails?.paymentMethod?.type === 'bill_me'">-->

<!--          <div class="grid grid-nogutter">-->
<!--            <div class="col-4 grid-nogutter">-->
<!--              <p class="m-0 p-0 my-1"><strong>Paying by:</strong></p>-->
<!--            </div>-->
<!--            <div class="col-8 grid-nogutter">-->
<!--              <p class="m-0 p-0 my-1">-->
<!--                Bill Me-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="grid grid-nogutter">-->
<!--            <div class="col-4 grid-nogutter">-->
<!--              <p class="m-0 p-0 my-1"><strong>Mail To:</strong></p>-->
<!--            </div>-->
<!--            <div class="col-8 grid-nogutter">-->
<!--              <p class="m-0 p-0 my-1">-->
<!--                CPE INC.<br/>-->
<!--                370 Reed Road, Suite 227<br/>-->
<!--                Broomall, PA 19008-4098<br/>-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->

<!--        <div *ngIf="orderDetails?.paymentMethod?.type === 'credit_card'">-->

<!--          <div class="grid grid-nogutter">-->
<!--            <div class="col-6 grid-nogutter">-->
<!--              <p class="m-0 p-0 my-1"><strong>Paying by:</strong></p>-->
<!--            </div>-->
<!--            <div class="col-6 grid-nogutter">-->
<!--              <p class="m-0 p-0 my-1">-->
<!--                {{ orderDetails?.paymentMethod?.displayName}}-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->

<!--      </div>-->
<!--    </div>-->

  </p-card>

  <!-- Registration Summary -->
  <p-card>
    <ng-template pTemplate="header">
      <div style="color: white; background-color: #006699">
        <h2 class="m-0 p-0">Registration Summary</h2>
      </div>
    </ng-template>

    <!-- Registration Summary -->
    <div class="grid grid-nogutter">
      <div class="col-6">
        <div class="grid grid-nogutter">
          <div class="col-6"><strong>Web Order ID#:</strong></div>
          <div class="col-6">{{ orderDetails.orderId }}</div>
          <div class="col-6"><strong>Registration Date:</strong></div>
          <div class="col-6">{{ orderDetails.orderDate }}</div>
          <div class="col-6">Programs Subtotal:</div>
          <div class="col-6">{{ orderDetails.extTotal  | currency: 'USD':'symbol':'1.2-2' }}</div>
<!--          <div class="col-6">Manual Dollar Adjustments:</div>-->
<!--          <div class="col-6">-->
<!--            {{ (orderDetails?.manualDollarAdjustments || 0) | currency: 'USD':'symbol':'1.2-2' }}-->
<!--          </div>-->
<!--          <div class="col-6">Customer Requested Discount:</div>-->
<!--          <div class="col-6">-->
<!--            {{ (orderDetails?.totalDiscounts || 0) | currency: 'USD':'symbol':'1.2-2' }}-->
<!--          </div>-->
          <div class="col-12 text-left">----------</div>
          <div class="col-6"><strong>Total for this Registration:</strong></div>
          <div class="col-6">{{ orderDetails.totalDue | currency: 'USD':'symbol':'1.2-2' }}</div>
        </div>
      </div>
    </div>

    <!-- Pass List -->
    <!-- <div class="my-5">
      <h4>Pass:</h4>
      <ul>
        <li *ngFor="let pass of passes">
          <p>
            <strong>{{ pass.name }}</strong
            ><br />
            PASS REFERENCE CODE: {{ pass.referenceCode }}
          </p>
          <p>{{ pass.description }}</p>

          <p class="m-0 p-0">Attendees:</p>
          <ul>
            <li *ngFor="let attendee of pass.attendees">
              {{ attendee.firstName }} {{ attendee.firstName }} ({{
                attendee.email
              }})
            </li>
          </ul>
        </li>
      </ul>
    </div> -->

    <!-- Programs on Registration -->
    <div>
      <h4>Programs on Registration</h4>
      <ul>
        <li *ngFor="let cartItem of orderDetails?.lineItems; let i = index">
          <div class="grid grid-nogutter">
            <div class="col-1 grid-nogutter">
              <p>
                <strong>{{ cartItem.isAttending ? (cartItem.attendees?.length + 1) : cartItem.attendees?.length }} x</strong>
              </p>
            </div>
            <div class="col-11 grid-nogutter">
              <p>
                <strong>{{ cartItem.name }} - {{ cartItem.totalCost | currency: 'USD':'symbol':'1.2-2'}}</strong>
                ({{ cartItem.cost | currency: 'USD':'symbol':'1.2-2'}} each)<br />
                Course ID: {{ cartItem.courseID }}<br />
                Course Type: {{ cartItem?.courseType }}<br />
                <span *ngIf="cartItem?.courseType == 'seminar' || (cartItem?.courseType?.indexOf('webinar') >= 0)">Course Date: {{ cartItem['formattedDate'] }}<br /></span>
                <span *ngIf="cartItem?.courseType == 'seminar'">Location: {{ cartItem.location }}<br /></span>
              </p>
              <p class="m-0 p-0">Attendees:</p>
              <ul>
                <li *ngIf="cartItem.isAttending">{{ orderDetails.contactAddress?.firstName ? (orderDetails.contactAddress?.firstName +" "+ orderDetails.contactAddress?.lastName) : 'You' }}</li>
                <li *ngFor="let attendee of cartItem.attendees">
                  {{ attendee.firstName }} {{ attendee.lastName }} ({{attendee.email}})
                  <span *ngIf="attendee?.licenseInformation?.stateLicensedCPA">
                    <br>
                    {{ attendee.licenseInformation.state }} licensed CPA until {{ attendee.licenseInformation.expiration }}
                    <span *ngIf="attendee.licenseInformation.state == 'NJ'">
                      <br>
                      NJ State License Number : {{ attendee.licenseInformation.njStateLicenseNumber}}
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
      <div class="mt-5" *ngIf="currentRoute.includes('/checkout')">
        <div><p>Thank you for registering at <a href="/" title="www.cpeonline.com">www.cpeonline.com</a>.</p>

          <div *ngIf="extraOrderRegistrationData?.newAccount">
            <p>Login to your new account use the following information:</p>
            <p>Username: {{extraOrderRegistrationData.newAccount.username}}</p>
            <p>Password:{{extraOrderRegistrationData.newAccount.password}}</p>
          </div>

          <p><b><span style="font-size: 15px;">PLEASE NOTE:</span></b><span style="font-size: 15px;"> <b>For specific <a href="/policies">cancellation and refund policy</a> information, please visit our <a href="/policies">Policies</a> page.</b></span></p>
          <p>SEMINAR, CONFERENCE OR LIVE WEBINAR REGISTRANTS:</p>
          <p>An acknowledgement of your registration will be emailed and/or mailed to you within the next few days. The starting time of your program(s) will be provided on your registration notification.</p>
          <p>Programs will be confirmed by email, phone or mail within one week prior to the date of the event. If your program is within the next week, please call Customer Service at 1-800-544-1114 to receive confirmation of the program. Live webinar registrants: On the day prior to the webinar, you will receive an email containing login instructions.</p>
          <p>SELF-STUDY WEBINAR REGISTRANTS:</p>
          <p>An acknowledgement of your registration and access instructions to your Self-Study Webinar will be emailed to you momentarily. Access will be available for one year only from the date of purchase.</p>
          <p>ALL REGISTRANTS:</p>
          <p>If you need assistance, please call Customer Service at 1-800-544-1114 or email us at <a href="mailto:info@cpeonline.com">info&#64;cpeonline.com</a>.</p>
          <p>When you register for a CPE INC. program, you are agreeing to receive email communications from us. These emails contain relevant information for CPAs and Financial Professionals and include course information, special offers, and news and expert insights.</p>
          <p>If you prefer not to receive email communications from us, follow the opt-out directions located at the bottom of any CPE INC. email. </p>
          <p>For print mailings, please be sure to provide us with the exact name, mailing address, and/or email address as it appears on the printed piece, so that we can be sure to identify you correctly. All mail removal requests may require up to a month to take effect.</p>
          <p><a href="/">Return to the home page</a></p>

        </div>
      </div>



    </div>
  </p-card>
</div>

<ng-template #spinner>
  <p-progressSpinner></p-progressSpinner>
</ng-template>
