import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CourseResource, CoursesResource} from "../../course";
import {TopicResource, TopicsResource} from "../../topic";

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-webinars',
  templateUrl: './webinars.component.html',
  styleUrls: ['./webinars.component.scss']
})
export class WebinarsComponent implements OnInit {
  topics: TopicsResource | null = null;
  topic: TopicResource | null = null;
  courses: CoursesResource | null = null;
  course: CourseResource | null = null;
  queryData: any;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.getCourses();
  }

  getCourses() {

    // const url = "http://dev.cpeonline.local/jsonapi/node/course";
    // const url = "https://dcdev-direct.cpeonline.com/jsonapi/node/course";
    // const url = "https://jsonplaceholder.typicode.com/todos/1";

    const headers = new HttpHeaders()
      .set("Content-Type", "application/vnd.api+json");

    this.http.get<CoursesResource>(environment.jsonApiUrl + '/node/course/', {headers}).subscribe((data) => {
      this.courses = data;
    })
  }
}
