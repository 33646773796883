import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TopicService } from '../_services/topic.service';
import { Subject, takeUntil } from 'rxjs';
import { SessionService } from '../_services/session.service';

@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
  styleUrls: ['./content-page.component.scss']
})
export class ContentPageComponent implements OnInit, OnDestroy {
  
  href: string = undefined;
  pageContent: string = "";
  loading: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router, 
    private topicService: TopicService,
    private sessionService: SessionService
    ) { }

  ngOnInit(): void {
    this.loading = true;
    this.href = this.router.url;
    this.getContent();
  }
  getContent(): void {
    let page_name: string = this.href.split("/").pop()
    this.topicService
    .getGeneralPageContent(page_name)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (data) => {        
        if(data?.length) {
          this.pageContent = data[0].body.value;
          this.sessionService.sessData.currentPage.heading = data[0].title;
          this.sessionService.setSessData(this.sessionService.sessData)
        }
        console.log(data)
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

}
