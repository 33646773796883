import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {CourseResource, CoursesResource} from "../../course";
import {environment} from "../../environments/environment";
import {SearchCriteriaOptions} from "../../search-types";

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  courses: CoursesResource | null = null;
  course: CourseResource | null = null;
  public searchQuery: string = null;

  searchSubject = new BehaviorSubject(false);
  searchObservable = this.searchSubject.asObservable();

  constructor(
    private http: HttpClient,
  ) {

  }

  fulltextSearch(query: string) {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/vnd.api+json");

    return this.http.get<any>(environment.baseUrl + '/fulltextsearch' + '?text=' + query, {headers})
      .pipe(
        map((items: any[]) => {
          // We need to filter out the 'Error' objects that are there for debugging.
          const filteredTopics = [];
          for (const item of items) {
            if (item.hasOwnProperty('Error')) {
              // Skip, this is for debugging the search service
            } else {
              item['field_canonical_title'] = (item['field_canonical_title'] ? item['field_canonical_title'] : item['title']);
              
              if(item['field_hours']) {
                item['field_hours'] = this.normalizeHours(String(item['field_hours']));
              }
              if(item['field_price']) {
                item['field_price'] = this.normalizeHours(String(item['field_price']))
              }
              if(item['field_canonical_url']) {
                item['field_canonical_url'] = item['field_canonical_url'].split("https://www.cpeonline.com")[1]
              }
              filteredTopics.push(item);
            }
          }
          return filteredTopics;
        })
      );
  }
  searchWithCriteria(criteria) {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/vnd.api+json");
    let query = '';
    if(criteria.priceRange && criteria.priceRange !== '') {
      query += (!query ? "?" : '&') + 'priceRange=' + criteria.priceRange;
    }

    if(criteria.searchCredits && criteria.searchCredits !== '') {
      query += (!query ? "?" : '&') + 'hoursRange=' + criteria.searchCredits;
    }

    if(criteria.startDate && criteria.startDate !== '') {
      query += (!query ? "?" : '&') + 'startDate=' + criteria.startDate;
    }

    if(criteria.formats && criteria.formats != '') {
      query += (!query ? "?" : '&') + 'format=' + criteria.formats;
    }

    if(criteria['knowledge-level'] && criteria['knowledge-level'] != '') {
      query += (!query ? "?" : '&') + 'knowledge-level=' + criteria['knowledge-level'];
    }

    if(criteria.textSearch && criteria.textSearch !== '') {
      query += (!query ? "?" : '&') + 'textSearch=' + criteria.textSearch;
    }

    if(criteria['start-time-earliest'] || criteria['start-time-latest']) {
      query += (!query ? "?" : '&') + 'time-zone=' + criteria['time-zone'];
      if(criteria['start-time-earliest']) {
        query += '&start-time-earliest=' + criteria['start-time-earliest'];
      }
      if(criteria['start-time-latest']) {
        query += '&start-time-latest=' + criteria['start-time-latest'];
      }
    }

    if(criteria.topics && criteria.topics != '') {
      query += (!query ? "?" : '&') + 'topics=' + criteria.topics;
    }

    if(criteria.pass && criteria.pass != '') {
      query += (!query ? "?" : '&') + 'pass=' + criteria.pass;
    }

    // Add the city criteria as a URL parameter for querying the API
    if(criteria.city && criteria.city != '') {
      query += (!query ? "?" : '&') + 'city=' + criteria.city;
    }

    // Add the state criteria as a URL parameter for querying the API
    if(criteria.state && criteria.state != '') {
      query += (!query ? "?" : '&') + 'state=' + criteria.state;
    }

    return this.http.get<any>(environment.baseUrl + '/courses/criteria' + query, {headers})
      .pipe(
        map((items: any[]) => {
          // We need to filter out the 'Error' objects that are there for debugging.
          const filteredTopics = [];
          for (const item of items) {
            if (item.hasOwnProperty('Error')) {
              // Skip, this is for debugging the search service
            } else {
              
              item['field_canonical_title'] = (item['field_canonical_title'] ? item['field_canonical_title'] : item['title']);

              if(item['field_hours']) {
                item['field_hours'] = this.normalizeHours(String(item['field_hours']))
              }
              if(item['field_price']) {
                item['field_price'] = this.normalizeHours(String(item['field_price']))
              }
              if(item['field_canonical_url']) {
                item['field_canonical_url'] = item['field_canonical_url'].split("https://www.cpeonline.com")[1]
              }
              filteredTopics.push(item);
            }
          }
          return items;
        })
      );
  }

  /**
   * Get the field criteria that should be available for certain fields in the search forms.
   */
  getCriteriaOptions() {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/vnd.api+json");

    return this.http.get<SearchCriteriaOptions>(environment.baseUrl + '/cpe_api/search_criteria', {headers})
      .pipe(
        map((items: SearchCriteriaOptions) => {
          return items;
        })
      );
  }
  
  normalizeHours(hours: string = '') {

    // Can search for common indications that the title has a timestamp
    if(hours.includes('.')) {
      const h = hours.split('.');
      if(h[1] == '00') {
        return Number(h[0]);
      } else if(h[1] == '50') {
        return Number(h[0] + '.5');
      } else {
        return Number(hours);
      }
    } else {
      return Number(hours);
    }
  }

  searchEvent(): void {
    this.searchSubject.next(true);
  }
}
