import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { SessData, SessionService } from '../_services/session.service';
import { SearchService } from '../_services/search.service';
import { TopicResource, TopicsResource } from "../../topic";
import { isPlatformServer } from '@angular/common';


// The expected search parameters
interface CPESearchParams extends Params {
  textSearch: string;
  searchCredits: string;
  priceRange: string;
  formats: string;
  startDate: string;
  topics: string;
  pass: string;
  city: string;
  state: string;
  utm_source: string;
}

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css']
})
export class SearchResultsComponent implements OnInit {

  @ViewChild('searchResultTable', { static: false }) searchResultTable: any;
  @Input() entities: any[];
  // List of subscriptions to unsubscribe on destroy
  private subs: Subscription[];
  query: any;
  queryData: CPESearchParams;
  rawResponse: any[];
  returnedTopics: any[];
  returnedCourses: any[];
  loading: boolean = false;
  resource: string = '';
  errorMsg: string;
  topics: TopicsResource | null = null;
  topic: TopicResource | null = null;
  paginator: boolean = false;
  utm_discount_code_param: string | null = null;
  utm_source_discount_data: any = null;
  // The resolved grid to show for the search results
  searchGridToShow = 'topics';

  public sessData: SessData | null = null;

  private isServer = isPlatformServer(this.platformId);

  //
  // Set the default state of the component
  //

  // Settings for repsonsive display by default
  // displayResponsiveTableView: boolean = false;
  // displayResponsiveSidebar: boolean = true;
  // sidebarVisible: boolean = false;

  // Settings for desktop display by default
  // displayResponsiveTableView: boolean = false;
  // displayResponsiveSidebar: boolean = false;
  // sidebarVisible: boolean = true;

  // Settings for mobile (smallest screen)
  // Whenever this is true things don't look right
  displayResponsiveTableView: boolean = true;
  displayResponsiveSidebar: boolean = true;
  sidebarVisible: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private sessionService: SessionService,
    public es: SearchService,
    private cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.subs = [];
  }
  display: boolean = false;

  showDialog() {
      this.display = true;
  }
  ngOnInit(): void {

    this.route.data.subscribe(data => {
      this.utm_source_discount_data = data?.['res'];
    })

    // Get current session data
    if(!this.isServer) {
      this.sessionService.sessData.currentPage.heading = 'Search Results';
      this.sessionService.setSessData(this.sessionService.sessData)
    }


    // get return url route parameters
    this.subs.push(
      this.route.queryParams.subscribe(queryParams => {
        // Clear response data
        this.reset();
        this.utm_discount_code_param = null;
        if(queryParams['utm_source']) {
          this.utm_discount_code_param = queryParams['utm_source'];
        } else {
          this.utm_source_discount_data = null;
        }
        // Check to see if we have queryParams, or if we're using a snapshot
        if (queryParams) {
          this.queryData = queryParams as CPESearchParams;
        } else {
          // We're using a snapshot
          this.queryData = this.route.snapshot.queryParams as CPESearchParams;
        }

        this.reset();
        // This seems to be hit twice. So searching here is BAD.
        console.log('search-results:queryParams:', queryParams);
        // We're running the search too many times. NOTICE: This is a band-aid
        if (!this.loading) {
          console.log('search-results->searching based on queryParams.');
          this.search();
        }
      })
    );


    setTimeout(() => {
      // This will resolve the expression has changed after it was checked error
      this.calculateResponsiveSearchFilterUI();
    },0);

  }

  ngAfterViewInit(): void {
    // console.log(`ngAfterViewInit() fired`);
  }

  ngAfterViewChecked() {
    // console.log(`ngAfterViewChecked() fired`);
  }

  ngAfterContentInit() {
    // console.log(`ngAfterContentInit() fired`);
  }

  /**
   * The purpose of this function is to decide if the responsive filter sidebar should be active or not.
   */
  calculateResponsiveSearchFilterUI() {

    // console.group('calculateResponsiveSearchFilterUI')
    // console.log(`fired`);
    if(!this.isServer) {
      // console.log(`Setting displayResponsiveSidebar`);

      setTimeout(() => {
        // console.log('calculateResponsiveSearchFilterUI set timeout fired');

        if (window.innerWidth < 992) {
          this.displayResponsiveSidebar = true;
        }
        if (window.innerWidth <= 960) {
          this.displayResponsiveTableView = true;
        }
        this.resizeHandler();
        this.cd.detectChanges();

      },0);

      window.addEventListener('resize', () => {
        // console.log(`resize listener fired`);
       this.resizeHandler();
      });
    } else {
      // console.log(`In server, not doing anything`)
    }
    // console.groupEnd();
  }

  /**
   * Handles setting new window state based on resize events.
   */
  resizeHandler() {
    this.displayResponsiveSidebar = (window.innerWidth < 992);
    if(!this.displayResponsiveSidebar) {
      this.sidebarVisible = this.displayResponsiveSidebar;
    }
    this.displayResponsiveTableView = (window.innerWidth <= 960);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
    for (let i = 0; i < this.subs.length; i++) {
      this.subs.pop();
    }
  }

  reset(): void {
    this.errorMsg = null;
    this.returnedTopics = null;
    this.returnedCourses = null;
  }

  search(): void {
    this.sidebarVisible = false;
    this.loading = true;

    // This triggers a search if our query params are one of the known search criteria
    if(
      this.queryData.formats ||
      this.queryData.searchCredits ||
      this.queryData.priceRange ||
      this.queryData.startDate ||
      (this.queryData['time-zone'] && (this.queryData['start-time-earliest'] || this.queryData['start-time-latest'])) ||
      this.queryData.topics ||
      this.queryData['knowledge-level'] ||
      this.queryData.pass ||
      this.queryData.city ||
      this.queryData.state
      ) {

      this.es.searchWithCriteria(this.queryData)
        .subscribe({
          next: (data) => {
            // Array of custom response objects, should be either custom objects or an error object.
            if(data.length > 25) {
              this.paginator = true;
              this.rawResponse = data;
            } else {
              this.rawResponse = data;
            }
          },
          error: (error) => {
            console.error(
              'search-results:search(): Error has occurred'
            );
            this.errorMsg = 'Unable to get search results, please try again later'
            this.resolveGridToShow();
            this.loading = false;
          },
          complete: () => {
            // if(this.rawResponse.length > 50) {
            //   // Get entity_id and send to courses component
            //   this.loading = false;
            // } else {
              if(this.queryData?.textSearch) {
                this.returnedCourses = this.rawResponse;
              } else {
                this.returnedCourses = this.rawResponse.sort((a, b) => {
                  let [aTitle, aFormat] = [a.field_canonical_title, a.field_course_type];
                  let [bTitle, bFormat] = [b.field_canonical_title, b.field_course_type];

                  return (aTitle+" "+aFormat).localeCompare(bTitle+" "+bFormat);
                });
              }

              if(!this.returnedCourses?.length || (this.returnedCourses?.length && this.returnedCourses[0]?.["Error"])) {
                this.errorMsg = 'No courses to display. Please use the filter courses options to find a topic of interest.'
                if(this.returnedCourses?.length && this.returnedCourses[0]?.["Error"]) {
                  this.errorMsg = "Unable to get search results, please try again later";
                }
                this.returnedCourses = undefined
              }
              this.resolveGridToShow();
              this.loading = false;
              this.searchResultTable.reset();
            // }
          },
        });
    } else if(this.queryData.textSearch || Object.keys(this.queryData).length === 0 || this.utm_discount_code_param) {

      // Normalize the full text search query so if it's undefined assume an empty string and search ll
      let textToSearch = '';
      if (this.queryData.textSearch === undefined) {
        textToSearch = '';
      } else {
        textToSearch = this.queryData.textSearch;
      }

      this.es
        .fulltextSearch(textToSearch)
        .subscribe({
          next: (data) => {
            // Array of custom response objects, should be either custom objects or an error object.
            if(data.length > 25) {
              this.paginator = true;
              this.rawResponse = data;
            } else {
              this.rawResponse = data;
            }
          },
          error: (error) => {
            console.error(
              'search-results:search(): Error has occurred'
            );
            this.errorMsg = 'Unable to get search results, please try again later'
            this.resolveGridToShow();
            this.loading = false;
          },
          complete: () => {
            if(this.rawResponse.length === 1) {
              // Get entity_id and send to courses component
              this.resolveGridToShow();
              this.loading = false;
              // Can't do this for some reason, need to explore it more later.
              //const entity = this.rawResponse.pop();
              console.warn('search-results:search returned a single element', this.rawResponse);
              //this.router.navigate(['/courses/'] + entity.uuid);
              // This is a band-aid until I work through it. Routing doesn't work during refresh for some reason.

            } else {
              if(textToSearch) {
                this.returnedTopics = this.rawResponse;
              } else {
                this.returnedTopics = this.rawResponse.sort((a, b) => {
                  let [aTitle, aFormat] = [a.field_canonical_title, a.field_course_type];
                  let [bTitle, bFormat] = [b.field_canonical_title, b.field_course_type];

                  return (aTitle+" "+aFormat).localeCompare(bTitle+" "+bFormat);
                });
              }
              this.resolveGridToShow();
              this.loading = false;
              this.searchResultTable.reset();
            }
          },
        });
    } else {
      // Unhandled query
      this.errorMsg = 'No courses to display. Please use the filter courses options to find a topic of interest.'
      this.resolveGridToShow();
      this.loading = false;
    }
  }

  /**
   * Check the state of the component and determine what results grid should show the search results
   */
  resolveGridToShow() {

    // Assume no grid will br shown
    this.searchGridToShow = '';

    // Make this a local variable for easier access
    let queryData = this.queryData;

    //
    // Criteria for showing the topics result
    //

    if (Object.keys(this.queryData).length === 0 || (queryData.textSearch && Object.keys(this.queryData).length === 1) || (Object.keys(this.queryData).length === 2 && queryData.textSearch && this.utm_discount_code_param)) {
      this.searchGridToShow = 'topics';
      return;
    }

    //
    // Criteria for showing the course results
    //
    if (queryData.city || queryData.state || queryData.searchCredits || queryData.priceRange || queryData.startDate || queryData.formats || queryData['start-time-earliest'] || queryData['start-time-latest'] || queryData.topics || queryData['knowledge-level'] || queryData.pass) {
      this.searchGridToShow = 'courses';
      return;
    }

  }

  resolveFormat(topic_id: string) {
    /*
    The beginning of the Topic ID should tell us what format we're working with here
     */
    var format = '';
    switch (topic_id[0]) {
      case 'S':
        // Should be a Seminar
        format = 'Seminar';
        break;
      case 'W':
        // Should be a Live Webinar
        format = 'Live Webinar';
        break;
      case 'SS':
        // Should be Self-Study
        format = 'Self-Study';
        break;
      default:
        // Dunno?
        format = '???';
        break;
    }
    return format;
  }
  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
