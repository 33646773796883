import { ContactUsComponent } from './contact-us/contact-us.component';
import { UserAccountComponent } from './user-account/user-account.component';
import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
  Route,
  UrlSegment,
  UrlSegmentGroup,
  UrlMatchResult,
} from '@angular/router';

import { HomeComponent } from './home/home.component';
import { TopicsComponent } from './topics/topics.component';
import { WebinarsComponent } from './webinars/webinars.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { GenericEmailTemplateComponent } from './email-templates/generic-email-template/generic-email-template.component';
import {NoContentComponent} from './no-content';
import { AuthGuard } from './guard/auth.guard';
import { TopicsByCategoryComponent } from './topics-by-category/topics-by-category.component';
import { EthicsCoursesComponent } from './ethics-courses/ethics-courses.component';
import {CartDevComponent} from './cart-dev/cart-dev.component';
import { PassesComponent } from './passes/passes.component';
import { CanDeactivateGuardGuard} from './can-deactivate-guard.guard';

import { CourseCodesListComponent } from './admin/course-codes-list/course-codes-list.component';
import { CourseStateListComponent } from './course-state-list/course-state-list.component';
import { CoursesByStateComponent } from './courses-by-state/courses-by-state.component';
import { ContentPageComponent } from './content-page/content-page.component';
import { GetUTMCodeDataResolverService } from './_services/get-utmcode-data-resolver.service';
import { AccountRegistrationEmailTemplateComponent } from './email-templates/account-registration-email-template/account-registration-email-template.component';

function pathMatcher(
  segments: UrlSegment[],
  group: UrlSegmentGroup,
  route: Route
): UrlMatchResult {
  // match urls like "/files/:filepath" where filepath can contain '/'
  if (segments.length > 0) {
    // if first segment is 'courses', then concat all the next segments into a single one
    // and return it as a parameter named 'canonical'
    if (segments[0].path == 'courses') {
      return {
        consumed: segments,
        posParams: {
          query: new UrlSegment(segments.slice(1).join('/'), {}),
        },
      };
    }
  }
  return null;
}

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', loadChildren:()=> import('./login-status/login-status.module').then(m=> m.LoginStatusModule), canActivate: [AuthGuard] },
  { path: 'logout', loadChildren:()=> import('./login-status/login-status.module').then(m=> m.LoginStatusModule) },
  { path: 'login-status', loadChildren:()=> import('./login-status/login-status.module').then(m=> m.LoginStatusModule) },
  { path: 'dev/cart', component: CartDevComponent },
  { path: 'topics', component: TopicsComponent },
  { path: 'search-results', component: SearchResultsComponent, resolve: {res: GetUTMCodeDataResolverService} },
  { path: 'seminars/alltopics/:cat_name', component: TopicsByCategoryComponent, resolve: {res: GetUTMCodeDataResolverService}  },
  { path: 'seminars/ethics', component: EthicsCoursesComponent },
  { path: 'webinars', component: WebinarsComponent },
  { path: 'user', component: UserAccountComponent, canActivate: [AuthGuard]  },
  { path: 'email-test', component: GenericEmailTemplateComponent },
  { path: 'account-email-test', component: AccountRegistrationEmailTemplateComponent },
  { path: 'contact-us', component: ContactUsComponent },
  {
    path: 'checkout',
    loadChildren:()=> import('./checkout/checkout.module').then(m=> m.CheckoutModule),
    canDeactivate: [CanDeactivateGuardGuard]
  },
  { path: 'passes', component: PassesComponent },
  { path: 'admin/course-codes', component: CourseCodesListComponent },
  { path: 'cpe-courses', component: CourseStateListComponent },
  { path: 'cpe-courses/:state_slug', component: CoursesByStateComponent},
  { path: 'privacy-policy', component: ContentPageComponent },
  { path: 'terms-of-use', component: ContentPageComponent },
  { path: 'policies', component: ContentPageComponent },
  {
    path: 'render-api',
    children: [
      {
        path: 'registration-email',
        component: AccountRegistrationEmailTemplateComponent,
      },
      {
        path: 'generic-email',
        component: GenericEmailTemplateComponent,
      },
    ],
  },
  { path: ':course_type/:slug', loadChildren:()=> import('./courses/courses.module').then(m=> m.CoursesModule), resolve: {res: GetUTMCodeDataResolverService}},
  { path: ':course_type/:course_category/:slug', loadChildren:()=> import('./courses/courses.module').then(m=> m.CoursesModule), resolve: {res: GetUTMCodeDataResolverService}},
  { path: '**', component: NoContentComponent},
];

@NgModule({
  imports: [
    // Add enableTracing: true, to the config for route troubleshooting
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
