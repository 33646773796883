<div class="cpe-container" id="courseByState">
    <div class="cpe-wrapper">
        <div class="grid">
            <div class="col-12">
                <p class="w-full text-justify">CPE Inc. offers the widest selection of NASBA-approved courses to ensure that Arizona CPAs, and other financial professionals, stay on top of the latest rules and regulations. From Ethics to ongoing Tax Code Changes, we can help keep you up-to-date and meet your CPE reporting requirements.</p>
                <p class="w-full text-justify">Whether you prefer CPE Webinars or Self-Study Courses, our convenient programs allow you to earn CPE credits and meet CPE requirements at your leisure.</p>
                <p class="w-full text-justify">Sign up now for our CPE courses and start learning today.</p>
            </div>
            <div class="col-12" *ngIf="courses_list_data">
                <h1>{{ courses_list_data.sectionName }}</h1>
                <p *ngFor="let course of courses_list_data?.courses">
                    <a pButton [label]="course.title" class="font-bold p-0 border-none " icon="pi pi-angle-right" iconPos="right" [routerLink]="[course.url]"></a>
                </p>
            </div>
            <div class="col-12">
                <div class="w-full search-people-action-buttons">
                    <div class="grid">
                      <div class="col-12 md:col-4 lg:col-4 xl:col-4">
                        <a pButton label="See All Live Webinars" class="w-full font-bold" [routerLink]="['/search-results']" [queryParams]='{formats: "webinar"}'></a>
                      </div>
                      <div class="col-12 md:col-4 lg:col-4 xl:col-4">
                        <a pButton label="See All Self-Study Webinars" class="w-full font-bold" [routerLink]="['/search-results']" [queryParams]='{formats: "self-study"}'></a>
                      </div>
                      <div class="col-12 md:col-4 lg:col-4 xl:col-4">
                        <a pButton label="See All Ethic Courses" class="w-full font-bold" [routerLink]="['/seminars/ethics']"></a>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
</div>