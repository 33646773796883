<div class="cpe-container pt-6 pb-6 grey-background">
  <div id="cpe-upcoming-conferences" class="cpe-wrapper wrapper-full-width">
    <div class="grid container-full-width">
      <div class="col-12">
        <h1>Upcoming Conferences</h1>
      </div>
      <div class="col-12">
        <p-carousel styleClass="slider-container upcoming-conferences-slider" [value]="cards" [numVisible]="3" [numScroll]="3" [responsiveOptions]="responsiveOptions" *ngIf="!isServer">
          <ng-template let-card pTemplate="item">
              <p-panel styleClass="m-2">
                <ng-template pTemplate="header">
                  <div class="upcome-info"><img [src]="card.card_image_url"/></div>
                  <span class="label">VIRTUAL CONFERENCE</span>
                </ng-template>
                <span class="course-title font-bold">{{card.title}}</span>
                <hr />
                <div class="course-price">
                  <p class="m-0">${{card.field_price}} <i class="pi pi-circle-fill"></i> {{card.field_hours}} credits</p>
                </div>
                <div class="py-3">
                  <span class="course-date">{{card.field_startdatetime | date: 'longDate'}}</span>
                </div>
                <a pButton label="Details" class="course-details-btn absolute" icon="pi pi-angle-right" iconPos="right" [routerLink]="['/'+card.field_canonical_url]" *ngIf="!isServer"></a>
                <p-button label="Details" class="course-details-btn absolute" icon="pi pi-angle-right" iconPos="right" [routerLink]="['/'+card.field_canonical_url]" *ngIf="isServer"></p-button>
              </p-panel>
          </ng-template>
        </p-carousel>
      </div>
    </div>
    <!-- Cards! -->
  </div>
</div>
