import { Component, OnInit } from '@angular/core';
import { CategoryEntry, TopicService } from 'src/app/_services/topic.service';

@Component({
  selector: 'app-explore-all-topics-widget',
  templateUrl: './explore-all-topics-widget.component.html',
  styleUrls: ['./explore-all-topics-widget.component.scss']
})
export class ExploreAllTopicsWidgetComponent implements OnInit {
  
  topicCategories: CategoryEntry[];

  constructor(private topicService: TopicService) { }

  ngOnInit(): void {
    this.topicCategories = this.topicService.codesAndCategories();
  }

}
