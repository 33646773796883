import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-email-template',
  templateUrl: './generic-email-template.component.html',
  styleUrls: ['./generic-email-template.component.scss'],
})
export class GenericEmailTemplateComponent implements OnInit {
  headerText = true;
  body = true;
  isAccountRegistration = true;
  emailFooter = true;

  constructor() {}

  ngOnInit(): void {
  }
}
