import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SearchService } from '../_services/search.service';
import { TopicsResource } from 'src/topic';
import { SessData, SessionService } from '../_services/session.service';
import { CategoryEntry, TopicService } from '../_services/topic.service';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-topics-by-category',
  templateUrl: './topics-by-category.component.html',
  styleUrls: ['./topics-by-category.component.scss']
})
export class TopicsByCategoryComponent implements OnInit, OnDestroy {
  
  private subs: Subscription[];
  errorMsg: string;
  topics: TopicsResource | null = null;
  paginator: boolean = false;
  loading: boolean = false;
  rawResponse: any[];
  returnedCourses: any[];
  sessData: SessData | null = null;
  categoryDetails: any;
  queryData: any;
  utm_source_discount_data: any = null;

  private isServer = isPlatformServer(this.platformId);
  displayResponsiveTableView: boolean = false;
  displayResponsiveSidebar: boolean = false;
  
  sidebarVisible: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public searchService: SearchService,
    private sessionService: SessionService,
    private topicService: TopicService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.utm_source_discount_data = data?.['res'];
    })

    this.subs = [];

    this.subs.push(
      this.sessionService.currentData.subscribe((data) => {
        this.sessData = data;
      })
    );

    this.subs.push(
      this.route.params.subscribe((queryParams) => {
        this.queryData = {topics: queryParams['cat_name']}
        let topicCategories = this.topicService.codesAndCategories()
        this.categoryDetails = topicCategories.find((cat: CategoryEntry) => {
          return queryParams['cat_name'] == cat.category
        });
        this.sessData.currentPage.heading = this.categoryDetails['description'];
        this.getTopicList(queryParams);
      })
    );
  }

  ngAfterViewInit(): void {
    if(!this.isServer) {
      if (window.innerWidth < 992) {
        this.displayResponsiveSidebar = true;
      }
      if (window.innerWidth <= 960) {
        this.displayResponsiveTableView = true;
      }
      window.addEventListener('resize', () => {
        this.displayResponsiveSidebar = (window.innerWidth < 992);
        if(!this.displayResponsiveSidebar) {
          this.sidebarVisible = this.displayResponsiveSidebar;
        }
        this.displayResponsiveTableView = (window.innerWidth <= 960);
      });
    }
  }

  private getTopicList(queryParams): void {
    this.sidebarVisible = false;
    this.loading = true;
    this.searchService.searchWithCriteria({ topics: queryParams['cat_name'] })
      .subscribe({
        next: (data) => {
          // Array of custom response objects, should be either custom objects or an error object.
          if (data.length > 25) {
            this.paginator = true;
            this.rawResponse = data;
          } else {
            this.rawResponse = data;
          }
        },
        error: (error) => {
          this.errorMsg = 'Unable to get search results, please try again later';
          this.loading = false;
        },
        complete: () => {
          // if(this.rawResponse.length > 50) {
          //   // Get entity_id and send to courses component
          //   this.loading = false;
          // } else {
          this.returnedCourses = this.rawResponse;

          this.returnedCourses = this.rawResponse.sort((a, b) => {
            let [aTitle, aFormat] = [a.field_canonical_title, a.field_course_type];
            let [bTitle, bFormat] = [b.field_canonical_title, b.field_course_type];
            
            return (aTitle+" "+aFormat).localeCompare(bTitle+" "+bFormat);
          });
          
          if (!this.returnedCourses?.length || (this.returnedCourses?.length && this.returnedCourses[0]?.["Error"])) {
            this.errorMsg = 'No courses to display. Please use the filter courses options to find a topic of interest.'
            if (this.returnedCourses?.length && this.returnedCourses[0]?.["Error"]) {
              this.errorMsg = "Unable to get search results, please try again later";
            }
            this.returnedCourses = undefined
          }
          this.loading = false;
          // }
          this.sessionService.setSessData(this.sessData);
        },
      })

  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
    for (let i = 0; i < this.subs.length; i++) {
      this.subs.pop();
    }
  }
}
