<div class="grid">
  <div class="col-12 lg:col-8 lg:col-offset-2 xl:col-6 xl:col-offset-3">
    <p-card header="Contact Us">
      <form [formGroup]="contactForm" (onSubmit)="onSubmit()">
        <div class="formgrid grid">
          <div class="field col-12">
            <span class="p-float-label">
              <input
                id="name"
                type="text"
                pInputText
                formControlName="name"
                class="w-full"
              />
              <label for="name">Name</label>
            </span>
          </div>
          <div class="field col-12">
            <span class="p-float-label">
              <input
                id="email"
                type="text"
                pInputText
                formControlName="email"
                class="w-full"
              />
              <label for="email">Email</label>
            </span>
          </div>
          <div class="field col-12">
            <span class="p-float-label">
              <input
                id="subject"
                type="text"
                pInputText
                formControlName="subject"
                class="w-full"
              />
              <label for="subject">Subject</label>
            </span>
          </div>
          <div class="field col-12">
            <span class="p-float-label">
              <textarea id="message" pInputTextarea [rows]="10" class="w-full"></textarea>
              <label for="message">Message</label>
            </span>
          </div>
          <div class="field col-12">
            <re-captcha formControlName="recaptcha" siteKey="{{getSiteKey}}"></re-captcha>
          </div>
        </div>
      </form>
      <ng-template pTemplate="footer">
        <p-button label="Submit" icon="pi pi-check" (onClick)="onSubmit()" [disabled]="contactForm.invalid"></p-button>
      </ng-template>
    </p-card>
  </div>
</div>
