<div id="cpe-explore-in-demand" class="cpe-container pt-6 pb-6 grey-background">
  <div  class="cpe-wrapper wrapper-full-width">
    <div class="grid container-full-width">
      <div class="col-12">
        <h1>Explore in-demand courses!</h1>
        <h3>Gain new insights in these highly-rated programs</h3>
      </div>
      <div class="col-12 m-0" *ngIf="inDemandCourseData">
        <div *ngIf="inDemandCourseData.sections">
          <p-tabView [(activeIndex)]="activeIndex" (onChange)="setSection($event)">
          <p-tabPanel [disabled]="isSectionEmpty(section)" *ngFor="let section of inDemandCourseData.sections; let i = index" [header]="section.label" [selected]="i == 0">

            <!-- Cards! -->
            <p-carousel styleClass="slider-container in-demand-slider" [value]="cards" [numVisible]="4" [numScroll]="4" [responsiveOptions]="responsiveOptions" *ngIf="!isServer">
              <ng-template pTemplate="header">
                <div *ngIf="section.categories.length > 0">
                  <div class="m-3 ml-0">
                    <p-tag *ngFor="let category of section.categories; index as i" [class.pl-0]="i == 0" class="p-2 {{category.category}} {{isActive(category.category)}}" value="{{category.description}}" (click)="setCategory(category.category);"></p-tag>
                  </div>
                </div><!-- ngIf=categories -->
                <div *ngIf="!inDemandCourseData.categories">
                  <h3>There are no categories!</h3>
                </div>
              </ng-template>
              <ng-template let-card pTemplate="item">
                <div *ngIf="card.type === 'node--course'">
                  <p-panel styleClass="m-2 h-full {{getClass(card)}}">
                    <ng-template pTemplate="header"> <span class="text-white font-bold">{{topicService.getCourseType(card.field_course_type)}}</span></ng-template>
                    <span class="course-title font-bold">{{card.title}}</span>
                    <hr />
                    <div class="course-content">
                      <div class="course-price"><p class="mb-0">${{card.field_price}} <i class="pi pi-circle-fill" style="font-size: .25rem; vertical-align: middle;"></i> {{card.field_hours}} credits</p></div>
                      <div class="py-3">
                        <span class="label">Next Available Date:</span><br />
                        <span class="course-date">
                          {{card.field_startdatetime | date: 'longDate'}} <i class="pi pi-circle-fill"></i> {{card.field_startdatetime | date: 'shortTime'}} - {{card.field_enddatetime | date: 'shortTime'}}
                        </span>
                      </div>
                    </div>
                    <p-button label="Course Details" class="pt-2 course-details-btn absolute" icon="pi pi-angle-right" iconPos="right" [routerLink]="['/'+card.field_canonical_url]" *ngIf="isServer"></p-button>
                    <a pButton label="Course Details" class="pt-2 course-details-btn absolute" icon="pi pi-angle-right" iconPos="right" [routerLink]="['/'+card.field_canonical_url]" *ngIf="!isServer"></a>
                  </p-panel>
                </div>
                <div *ngIf="card.type === 'node--topic'">
                  <p-panel styleClass="m-2 h-full {{getClass(card)}}">
                    <ng-template pTemplate="header"> <span class="text-white font-bold">TOPIC</span></ng-template>
                    <span class="course-title font-bold">{{card.field_canonical_title}}</span>
                    <hr />
                    <div style="font-size: 1.25vw;"><p class="mb-0"><strong>${{card.field_price}} <i class="pi pi-circle-fill" style="font-size: .25rem; vertical-align: middle;"></i> {{card.field_hours}} credits</strong></p></div>
                    <p-button label="Topic Details" class="font-bold p-0 border-none course-details-btn" icon="pi pi-angle-right" iconPos="right" [routerLink]="['/'+card.field_canonical_url]" *ngIf="isServer"></p-button>
                    <a pButton label="Topic Details" class="font-bold p-0 border-none course-details-btn" icon="pi pi-angle-right" iconPos="right" [routerLink]="['/'+card.field_canonical_url]" *ngIf="!isServer"></a>
                  </p-panel>
                </div>
              </ng-template>
            </p-carousel>
          </p-tabPanel>
        </p-tabView>

        </div><!-- *ngIf="inDemandCourseData.sections.length > 0" -->
        <div *ngIf="!inDemandCourseData.sections">
          <h3>There are no sections!</h3>
        </div>
      </div><!--ngIf=!inDemandCourseData-->
      <div class="col-12 flex justify-content-center flex-wrap pt-6">
        <p-button label="View All Courses" class="view-all-courses-btn" [routerLink]="['/courses']" *ngIf="isServer"></p-button>
        <a pButton label="View All Courses" class="view-all-courses-btn" [routerLink]="['/courses']" *ngIf="!isServer"></a>
      </div>
    </div>
  </div>
</div>
