import { Component, ElementRef, ViewChild } from '@angular/core';
import {SessData, SessionService} from "../_services/session.service";
import { Router } from '@angular/router';

@Component({
  selector: 'no-content',
  template: `
    <div class="cpe-container">
      <div class="cpe-wrapper" style="display: block;">
        <h1>Let Us Help You</h1>
        <p>
          Thank you for your interest in CPE INC.
          The link you clicked is no longer working. Let us help you find the information you are seeking.
          You may use the navigation links at the top of this Web page or call us at 1-800-544-1114,
          Monday through Friday 8:00 AM to 7:00 PM Eastern Standard Time. We look forward to helping
          you meet your continuing education needs.
        </p>
        Reason : <h3 style='color: red; display: contents' [innerHTML]='errorMessage'></h3>
      </div>
    </div>
  `
})
export class NoContentComponent {
  private sessData: SessData | null = null;
  errorMessage: string = ""
  constructor(
    private router: Router,
    private sessionService: SessionService,
  ) {
    // Get current session data
    this.sessData = this.sessionService.sessData;
    this.sessData.currentPage.heading = '404: page missing';
    console.log('no-content.component->sessData: ', this.sessData);
    this.errorMessage = this.router.getCurrentNavigation().extras.state?.['error'] || "Unknown error occurred";
    
    // Update the session with the current page title.
    this.sessionService.setSessData(this.sessData);
  }
  
}
