import { Component, OnInit, Input, Inject, PLATFORM_ID, HostListener } from '@angular/core';
import { MenuItem } from 'primeng/api';

import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { NavigationEnd, Router } from '@angular/router';

import { BannerResource, BannersResource } from "../../../banner";
import { environment } from "../../../environments/environment";
import { CartService } from "../../_services/cart.service";
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { SessionService } from 'src/app/_services/session.service';
import { SearchService } from 'src/app/_services/search.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  searchText: any;
  menuItems: MenuItem[];
  banners: BannersResource;
  banner: BannerResource | null = null;
  loadingBanners: boolean = true;
  @Input() menuData : MenuItem[];
  visible: boolean = true;
  isServer = isPlatformServer(this.platformId);
  public isLoggedIn: boolean = this.sessionService.isLoggedIn;
  cartItemsCount: string | number = 0;
  displaySearchInput: boolean = false;

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    public searchService: SearchService,
    private router: Router,
    private cart: CartService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.menuItems = this.menuData;
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if(e.url === '/') {
          this.displaySearchInput = false;
        } else {
          this.displaySearchInput = true;
        }
      }
    });

    this.getCartItems();

    //Subscribe update cart items on add/remove course from cart
    this.cart.currentData.subscribe((data) => {
        this.cartItemsCount = data?.lineItems?.length.toString() || '0';
    });

    this.sessionService.isLoggedinStatusChanged$.subscribe((loginStatus: string) => {
      this.isLoggedIn = (loginStatus == "logged-in");
      //Refresh cart items on signin/signout
      this.getCartItems();
    })
    //this.banners = this.sess.activeBanners;
    this.getBanners();

    let queryString = '';
    if(!this.isServer) {
      queryString = window.location.search;
    }

    const urlParams = new URLSearchParams(queryString);

    this.searchText = urlParams.get('textSearch');
    this.searchService.searchQuery = this.searchText;
  }

  ngAfterViewInit(): void {
    if(!this.isServer) {
      setTimeout(() => {
        if(this.document.getElementsByTagName("p-menubar")?.length) {
          this.document.getElementsByTagName("p-menubar")[0].parentElement.style.height = (this.document.getElementsByTagName("p-menubar")[0].clientHeight+"px")
          if (window.outerWidth < 569) {
            this.displaySearchInput = true;
          }
          window.addEventListener('resize', () => {
            if (window.outerWidth < 569) {
              this.displaySearchInput = true;
            }
           }); 
        }
      }, 500);
    }
  }

  private getCartItems(): void {
    // Once the header is rendered, we need to keep the cart badge up-to-date.
    this.cart.getCart().subscribe((data) => {
      // We need to grab the cart item and update it.
      this.cartItemsCount = data?.lineItems?.length.toString() || '0';
      this.cart.cartSource.next(data);
    });
  }

  showSearchInput() {
    if(!this.displaySearchInput && !this.isServer) {
       this.displaySearchInput = true;
       setTimeout(() => {
        this.document.getElementById("search").focus()
       }, 0);
    } else {
      this.search()
    }
  }

  takeAction(action:string) {
    if(action == "Sign In") {
      this.router.navigateByUrl('/login')
    }
    if(action == "Sign Out") {

      // 2024-01-24 I don't think this is the proper way to handle this as this will call the logout URL with a
      // CSRF token AND potentially a session cookie which may or may not match. Ultimately we just want to log
      // the user out so we'll rely on Drupal to do it all without using the REST API.
      // const url = environment.baseUrl + this.sessionService.getUrl('logout') +
      // '?_format=json&token=' + this.sessionService.getLogoutToken();

      const url = environment.baseUrl + this.sessionService.getUrl('logout');

      this.http.get(url).subscribe({
        next: (data) => {
          this.sessionService.logout();
          this.router.navigateByUrl('');
        },
        error: (err: HttpErrorResponse) => {
          let errMsg = err.toString();
          if ( errMsg === "Error: 'csrf_token' URL query argument is invalid.") {
            this.sessionService.logout();
            this.router.navigateByUrl('');
          } else {
            console.error('LogoutError', err);
          }
        },
      })

    }
  }
  getBanners() {

    const headers = new HttpHeaders()
      .set("Content-Type", "application/vnd.api+json")
      .set('Cache-Control', 'max-age=604800')
      .set('Expires', '640');

    this.http.get<BannersResource>(environment.jsonApiUrl + '/node/banner/', {headers})
      .subscribe((data) => {
        this.banners = data;
        this.loadingBanners = false;
      });
  }

  normalizeUrl(uri: string) {
    // Can search for common indications that the uri is marked by Drupal as internal or external
    if(uri.includes(':')) {
      const h = uri.split(':');
      if(h[0] == 'internal') {
        return h[1];
      } else {
        return uri;
      }
    } else {
      return uri;
    }
  }

  checkout(): void {
    this.router.navigate(['/checkout']);
  }

  search(): void {
    if(this.router.url.includes("/search-results") || this.router.url.includes("/seminars/alltopics")) {
      this.searchService.searchEvent();
    } else {
      let params = {}
      if(this.searchText) {
        params['textSearch'] = this.searchText;
      }
      this.router.navigate(['/search-results'], {
        queryParams: params
      });
    }

  }

}
