import { Component, OnInit } from '@angular/core';

import {TopicResource, TopicsResource} from '../../topic';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "../../environments/environment";


@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})
export class TopicsComponent implements OnInit {

  constructor(private http: HttpClient) {}

  topics: TopicsResource | null = null;
  topic: TopicResource | null = null;

  ngOnInit() {
    this.getTopics();
  }

  getTopics() {

    const headers = new HttpHeaders()
      .set("Content-Type", "application/vnd.api+json");

    this.http.get<TopicsResource>(environment.jsonApiUrl + '/node/topic', {headers}).subscribe((data) => {
      this.topics = data;
    })
  }
  getTopic(entity_id:number) {

    const headers = new HttpHeaders()
      .set("Content-Type", "application/vnd.api+json");

    this.http.get<TopicResource>(environment.jsonApiUrl + '/node/topic/' + entity_id, {headers}).subscribe((data) => {
      this.topic = data;
    })
  }

}
