import {Inject, Injectable, PLATFORM_ID} from '@angular/core';

import {Order} from "../../order.model";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {SessionService} from "./session.service";
import { environment } from 'src/environments/environment';
import {TopicsResource} from '../../topic';
import {concatMap, flatMap, map} from 'rxjs/operators';
import {concat, firstValueFrom, forkJoin, Observable} from 'rxjs';

/*
     * cpe_orders
     * - order_id
     * - uid
     * - order_status
     * - order_total
     * - item_count
     * - primary_email
     * - billing_first_name
     * - billing_last_name
     * - billing_street1
     * - billing_street2
     * - billing_city
     * - billing_state
     * - billing_zip
     * - data
     * - created
     * - modified
     * - host
 */
@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private http: HttpClient,
    private sess: SessionService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {}
  public createOrder(orderData: Order) {
    // console.log(`order.service:createOrder() enter.`);

    // This should actually BE stored in the session, but for now...
    orderData.host = '127.0.0.1';
    // We need to make sure validation is done before this is sent
    // let tokenObservable = this.sess.getAccessTokenAndRetrieveIfNeeded();
    return this.sess.getAccessTokenAndRetrieveIfNeeded().pipe(
      concatMap(accessToken =>{
        // console.log(`Using access token ${accessToken}`);
        return this.updateOrderHandler(orderData, accessToken);
      })
    );

  }

  /**
   *
   * This function needs to return an observable with the shopping cart data returned.
   * It needs to verify the token is available prior to making a call to update the order data
   *
   * We cannot use async here because it will change the signature to be a promise.
   *
   * @param orderData
   */
  public updateOrder(orderData: Order) {
    // console.log(`order.service:updateOrder() enter.`);

    // We'll need an access token so retrieve it if needed and then make the request.
    // Perform the observable and then pass the results to the actual function which we return
    // the results of updateOrderHandler
    return this.sess.getAccessTokenAndRetrieveIfNeeded().pipe(
      concatMap(accessToken =>{
        // Taken the returned access token and pass it to the update function
        // The results of this observable will contain the data of the http call
        // console.log(`Using access token ${accessToken}`);
        return this.updateOrderHandler(orderData, accessToken);
      })
    );

  }

  private updateOrderHandler(orderData: Order, token = ''): Observable<Object> {
    // This should actually BE stored in the session, but for now...
    orderData.host = '127.0.0.1';
    // We need to make sure validation is done before this is sent

    let headers;

    // Send different headers depending if we have an access token
    if (token) {
      // We have an access token so include it
      // console.log(`order.service:updateOrderHandler() token is ${token} and service ${this.sess.getAccessToken()}`);
      headers = new HttpHeaders()
        .set("Accept", "application/json")
        .set("Content-Type", "application/json")
        .set("X-CSRF-Token", token);
    } else {
      // No access token so send headers without
      headers = new HttpHeaders()
        .set("Accept", "application/json")
        .set("Content-Type", "application/json");
    }

    // Return the http observable response to the calling function
    return this.http.post(`${environment.baseUrl}/cart/add?_format=json`, JSON.stringify(orderData), {headers});

  }

  public getRegistrations() {
    // console.log(`getRegistrations(): enter`);

    const headers = new HttpHeaders()
      .set("Content-Type", "application/vnd.api+json");

    const url = environment.baseUrl + '/admin/store/registrations';
    // return this.http.get<any>(url, {headers}).subscribe((data) => {
    //   return data;
    // });

    return this.http.get<any>(url, {headers})
      .pipe(
        map((items: any[]) => {
          return items;
      })
    );
  }

}
