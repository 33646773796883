<div class="cpe-wrapper">
  <div class="grid">
    <div class="col-4">
      <p>LEFT COLUMN</p>
    </div><!--col-2-->
    <div class="col-6">
      <h1>Topics</h1>

      <table>
        <tr *ngFor="let topic of topics?.data">
          <td>{{topic.attributes.field_topic_id}}</td>
          <td>{{topic.attributes.title}}</td>
        </tr>
      </table>

      <pre>
      <!--  {{topics | json}}-->
      </pre>
    </div>
  </div><!--grid-->
</div><!--cpe-wrapper-->
