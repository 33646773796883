import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'CPEHtmlEntitiesEncode'
})
export class CpeHtmlEntitiesEncodePipe implements PipeTransform {
    transform(data: string): string {
      return data?.replace('•', '&middot;');
    }
}


@Pipe({
  name: 'CPEFormattedDateOnly'
})
export class CPEFormattedDateOnlyPipe implements PipeTransform {
  transform(data: string): string {
    // Given a date like:
    // Thu, Dec 19, 2024 · 9:00 PM
    // Return the date
    return data.split('•')[0];
  }
}

@Pipe({
  name: 'CPEFormattedTimeOnly'
})
export class CPEFormattedTimeOnlyPipe implements PipeTransform {
  transform(data: string): string {
    // Given a date like:
    // Thu, Dec 19, 2024 · 9:00 PM
    // Return the time
    return data.split('•')[1];
  }
}
