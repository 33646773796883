import { Component, OnInit } from '@angular/core';
import {OrderService} from '../_services/order.service';
import {Address, Attendee, LineItem, ShoppingCart} from '../../shopping-cart.model';
import {Order} from '../../order.model';

@Component({
  selector: 'app-cart-dev',
  templateUrl: './cart-dev.component.html',
  styleUrls: ['./cart-dev.component.scss']
})
export class CartDevComponent implements OnInit {

  constructor(
    private orderService: OrderService,
  ) { }

  ngOnInit(): void {
  }

  setCartItems() {
//     let payload = `{
// \t"orderDate": "2023-08-08",
// \t"orderValue": 10,
// \t"orderStatus": "pending",
// \t"primaryEmail": "currentUser@fict.out",
// \t"billingFirstName": "Current",
// \t"billingLastName": "User",
// \t"billingPhone": "",
// \t"billingCellPhone": "",
// \t"billingAddress": {
// \t\t"addressType": "billing",
// \t\t"company": "Krueger Solutions",
// \t\t"street1": "1428 Elm Street",
// \t\t"street2": "",
// \t\t"city": "Springwood",
// \t\t"state": "OH",
// \t\t"zip": "45505"
// \t},
// \t"shoppingCart": {
// \t\t"id": "7342",
// \t\t"lineItems": [
// \t\t\t{
// \t\t\t\t"uuid": "86f18e03-56a7-4d86-9751-a2dd44f2f386",
// \t\t\t\t"name": "GAAP Refresher Self-Study Webinar (10 Hours)",
// \t\t\t\t"cost": "399.00",
// \t\t\t\t"courseID": "SA28922L",
// \t\t\t\t"courseDate": "2024-12-19",
// \t\t\t\t"location": "static_location",
// \t\t\t\t"isAttending": false,
// \t\t\t\t"qty": 1
// \t\t\t}
// \t\t],
// \t\t"totalDiscounts": 0,
// \t\t"extTotal": 0,
// \t\t"totalDue": 0,
// \t\t"changed": "1683677029"
// \t},
// \t"host": "127.0.0.1"
// }`;
//     let payloadObj = JSON.parse(payload);


    const billingAddress: Address = {
      addressType: 'billing',
      fullName: 'John Doe',
      company: 'Krueger Solutions',       // Company Name
      street1: '1428 Elm Street',       // Company Address Line 1
      street2: '',
      city: 'Springwood',           // City
      state: 'OH',          // State
      zip: '45505',            // Postal Code
      country: 'US',
      phone: "9876543210",
      phonePurpose: "office"
    }

    const contactAddress: Address = {
      addressType: 'billing',
      fullName: 'John Doe',
      company: 'Krueger Solutions',       // Company Name
      street1: '1428 Elm Street',       // Company Address Line 1
      street2: '',
      city: 'Springwood',           // City
      state: 'OH',          // State
      zip: '45505',            // Postal Code
      country: 'US',
      phone: "9876543210",
      phonePurpose: "office"
    }

    const currentUser: Attendee = {
      firstName: 'Current',
      lastName: 'User',
      email: 'currentUser@fict.out',
      licenseInformation: {
        stateLicensedCPA: false,
        state: 'PA',
        expiration: '2028-12-12',
        njStateLicenseNumber: '88888888888',
      },
      address1: billingAddress,
    }

    const item: LineItem = {
      uuid: '86f18e03-56a7-4d86-9751-a2dd44f2f386',
      name: 'GAAP Refresher Self-Study Webinar (10 Hours)',
      cost: 399,
      courseID: 'SA28922L',
      courseDate: '2024-12-19',
      courseType: 'webinar',
      location: 'static_location',
      isAttending: false,
      qty: 1,
    }

    let items: LineItem[] = [];
    items.push(item);

    const cart: ShoppingCart = {
      id: '123',
      totalDiscounts: 0,
      extTotal: 0,
      totalDue: 0,
      changed: 1683677029,
      lineItems: items,
      status: 'in-progress',
    }

    const order: Order = {
      orderDate: '2023-08-08',
      orderValue: 10.00,
      orderStatus: 'pending',
      primaryEmail: 'someone@example.com',
      contactFirstName: 'Some',
      contactLastName: 'One',
      contactAddress: contactAddress,
      contactPhone: '',
      contactCellPhone: '',
      billingFirstName: 'Some',
      billingLastName: 'One',
      billingAddress: billingAddress,
      billingPhone: '',
      billingCellPhone: '',
      shoppingCart: cart,
    }


    this.orderService.createOrder(order).subscribe( {
      next: (data: ShoppingCart) => {
        console.log('order.service: createOrder Success. Order ID: ', data.orderId);
      },
      error: (error) => {
        // console.error('submitAcct returned false.');
        try {
          throw error;
        } catch (error) {
          console.error('order.service: createOrder returned false: ', error);
          // We should override the state to negate what we tried to do.
          //return this.cart;
        }
      },
    });



  }

  getCartItems() {
    // Not yet implemented
  }

}
