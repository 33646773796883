import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericEmailTemplateComponent } from './generic-email-template/generic-email-template.component';
import { AccountRegistrationEmailComponent } from './account-registration/account-registration.component';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AccountRegistrationEmailTemplateComponent } from './account-registration-email-template/account-registration-email-template.component';
import {PipesModule} from '../_pipes/pipes.module';


@NgModule({
  declarations: [
    GenericEmailTemplateComponent,
    AccountRegistrationEmailComponent,
    AccountRegistrationEmailTemplateComponent
  ],
    imports: [
        CommonModule,
        CardModule,
        ProgressSpinnerModule,
        PipesModule
    ],
  exports: [
    GenericEmailTemplateComponent,
    AccountRegistrationEmailComponent,
    AccountRegistrationEmailTemplateComponent,
  ]
})
export class EmailTemplatesModule { }
