<p-table
  [value]="courses"
  [paginator]="true"
  [rows]="10"
  responsiveLayout="scroll"
  styleClass="p-datatable-striped p-datatable-gridlines p-5 course-codes-datatable"
  [loading]="loading"
>
<ng-template pTemplate="caption">
  <div class="grid">
      <span class="col-2 p-input-icon-left m-2">
          <i class="pi pi-search"></i>
          <input pInputText type="text" [(ngModel)]="searchTopic" placeholder="Search Topic" />
      </span>
      <span class="col-2 p-input-icon-left m-2">
        <i class="pi pi-search"></i>
        <input pInputText type="text" [(ngModel)]="searchName" placeholder="Search Name" />
      </span>
      <span class="col-2 p-input-icon-left m-2">
        <i class="pi pi-search"></i>
        <input pInputText type="text" [(ngModel)]="searchEmail" placeholder="Search Email" />
      </span>
      <span class="col-2 m-2">
        <p-button label="Submit" styleClass="w-full font-bold" (click)="searchCourseCode()"></p-button>
      </span>
      <span class="col-2 m-2">
        <p-button label="Clear" styleClass="w-full font-bold" (click)="clear()"></p-button>
      </span>
  </div>
</ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="course_nid" class="text-center">
        ID <p-sortIcon field="course_nid"></p-sortIcon>
      </th>
      <th pSortableColumn="course_title" class="text-center">
        Course Name <p-sortIcon field="course_title"></p-sortIcon>
      </th>
      <th pSortableColumn="email_attendee" class="text-center">
        Email <p-sortIcon field="email_attendee"></p-sortIcon>
      </th>
      <th pSortableColumn="first_name" class="text-center">
        Name <p-sortIcon field="first_name"></p-sortIcon>
      </th>
      <th pSortableColumn="type" class="text-center">
        Course Type <p-sortIcon field="type"></p-sortIcon>
      </th>
      <th pSortableColumn="course_nid" class="text-center">
        Login Code <p-sortIcon field="course_nid"></p-sortIcon>
      </th>
      <th class="text-center">Resend</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-course>
    <tr [pSelectableRow]="course">
      <td class="text-center">{{ course.course_nid }}</td>
      <td class="text-center">{{ course.course_title }}</td>
      <td class="text-center">{{ course.email_attendee }}</td>
      <td class="text-center">{{ course.first_name }} {{ course.last_name }}</td>
      <td class="text-center">{{ course.type }}</td>
      <td class="text-center">{{ course.login_code }}</td>
      <td class="text-center">
        <p-button label="Resend" styleClass="font-bold white-space-nowrap text-white" [routerLink]="['/']"></p-button>
      </td>
    </tr>
  </ng-template>
</p-table>