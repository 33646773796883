<div class="cpe-wrapper pb-6">
  <div class="grid">
    <div class="col-12 text-xl font-bold">
      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut</p>
    </div>
    <div class="col-12 lg:col-3">
      <div class="column-heading cpe-dark-blue-background align-content-center pl-3"><h1>Filter Courses</h1></div>
      <app-search-widget
        [queryData]="queryData"
      ></app-search-widget>
    </div><!--col-3-->
    <div class="col-12 lg:col-9">
      <div class="column-heading cpe-dark-blue-background align-content-center pl-3"><h1>Search Results</h1></div>
      <p-table [value]="courses?.data" responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="attributes.title">Course Title <p-sortIcon field="attributes.title"></p-sortIcon></th>
            <th>Format</th>
            <th pSortableColumn="attributes.field_price">Price <p-sortIcon field="attributes.field_price"></p-sortIcon></th>
            <th>&nbsp;</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-course>
          <tr>
            <td>{{course.attributes.title}}</td>
            <td>Live Webinar</td>
            <td *ngIf="course.attributes.field_price">{{course.attributes.field_price}}</td><td *ngIf="!course.attributes.field_price">$1010.10</td>
            <td><button pButton pRipple label="More" class="font-bold white-space-nowrap" routerLink="/courses/{{course.attributes.id}}"></button></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div><!--grid-->
</div><!--cpe-wrapper-->
