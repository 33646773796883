import {AfterViewChecked, Component, HostListener, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {DOCUMENT, isPlatformServer} from "@angular/common";
import {TopicService, InDemandCourses, Section} from "../../_services/topic.service";
import {SimpleCourse} from "../../../course";
import {SimpleTopic} from "../../../topic";

@Component({
  selector: 'app-in-demand-courses-widget',
  templateUrl: './in-demand-courses-widget.component.html',
  styleUrls: ['./in-demand-courses-widget.component.scss']
})

export class InDemandCoursesWidgetComponent implements OnInit, AfterViewChecked {
  responsiveOptions = [
    {
      breakpoint: '1400px',
      numVisible: 4,
      numScroll: 1
    },
    {
      breakpoint: '1220px',
      numVisible: 4,
      numScroll: 1
    },
    {
      breakpoint: '1100px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '600px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '420px',
      numVisible: 1,
      numScroll: 1
    }
  ];
  isServer = isPlatformServer(this.platformId);
  sections: any[];
  sectionKeys: any[];
  cards: (SimpleCourse|SimpleTopic)[];
  activeIndex: number;
  activeSection: string = '';
  activeCategory: string = '';
  fullyLoaded: boolean = false;
  componentLoaded: boolean = false;
  isVertical: boolean = false;
  numShow: number = 4;
  @Input() inDemandCourseData : InDemandCourses;
  constructor(
    public topicService: TopicService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    // console.log('in-demand-courses.widget->Widget has been reloaded. Input: ', this.inDemandCourseData);
    this.sections = this.inDemandCourseData.sections;
    // console.log('Sections are ', this.inDemandCourseData.sections);
    this.sectionKeys = this.inDemandCourseData.sectionKeys;
    //console.log('Section Keys are ', this.sectionKeys);
    this.activeIndex = 0; // set to 0 to trigger p-tabView refresh after data is done loading
    this.activeSection = this.sectionKeys[this.activeIndex];
    this.cards = this.inDemandCourseData.sections[this.activeIndex].cards;
    this.fillBlanks();
    this.fullyLoaded = true;
  }

  ngAfterViewChecked() {
    if(!this.componentLoaded) {
      this.componentLoaded = true;
      this.setSlidesHeight();
    }
      // $('.container .column').each(function(){
      //   if($(this).height() > highestBox){
      //     highestBox = $(this).height();
      //   }
      // });
      // $('.container .column').height(highestBox);
  }

  setSlidesHeight(sliderIndex = 0): void {
    let highestBox = 0;
    let sliders = this.document.getElementsByClassName('in-demand-slider');
    if(sliders?.length) {
      // for (let index = 0; index < sliders.length; index++) {
        const slider = sliders[sliderIndex];
        const slides = slider.children[1].children[0].children[1].children[0].children;
        highestBox = 0;
        for (let i = 0; i < slides.length; i++) {
          const slide = slides[i];

          if(slide.clientHeight > highestBox){
            highestBox = slide.clientHeight;
          }
        }
        for (let ii = 0; ii < slides.length; ii++) {
          const slide = slides[ii];
          if(slide.children.length) {
            slide.children[0].children[0].children[0].children[1].children[0]['style'] = "height: "+(highestBox - 55)+"px !important; position: relative;";
            slide.children[0].children[0].children[0].children[1].children[0].lastElementChild['style'] = "bottom: 15px";
          }
        }
      // }
    }
  }

  @HostListener('window:resize') onWindowResize() {
    if (this.fullyLoaded) {
      if (window.innerWidth < 992) {
        this.isVertical = true;
      } else {
        this.isVertical = false;
      }
      // We need to fill in blanks if there are less cards than the innerWidth can support.
      if (window.innerWidth >= 1100) {
        this.numShow = 4;
      } else if(window.innerWidth <= 1100) {
        this.numShow = 3;
      } else if(window.innerWidth <= 600) {
        this.numShow = 2;
      } else {
        this.numShow = 1;
      }
    }
  }
  isCardVisible(card) {
    if(card.field_category_ids?.includes(this.activeCategory)) {
      return '';
    } else {
      return 'hidden';
    }
  }
  isSectionEmpty(section: Section) {
    if(section.cards.length > 0) {
      return false;
    } else {
      return true;
    }
  }
  /*
  Use the innerWidth to generate blank cards to keep card width consistent. Until we find a better way.
   */
  fillBlanks() {
    if(this.cards.length < this.numShow) {
      //console.log(`Number to Show is: `, this.numShow);
      //console.log(`Number to Cards is: `, this.cards.length);
      const blank: SimpleCourse = {
        type: 'blank', // Node Type
        id: null, // Entity ID
        field_nid: null,
        title: null,
        field_course_type: null, // webcast, self-study, etc
        field_timezone: null,
        field_startdatetime: null,
        field_enddatetime: null,
        field_eveningsweekends: '0',
        field_id: null, // Course ID
        field_hours: null,
        field_price: null,
        field_topic_id: null,
        field_category_ids: null,
        field_section_tag: null,
      }
      const diff = this.numShow - this.cards.length;
      var i = 0;
      do {
        this.cards.push(blank)
        i++;
      }
      while (i < diff);
      //console.log('New Cards:', this.cards);
      //console.log('Inserted Blanks: ', diff);
    }
  }

  setSection(event) {
    //console.log('event: ', event);
    // console.log('index: ', event.index);
    this.activeSection = this.sectionKeys[event.index];
    this.cards = this.inDemandCourseData.sections[event.index].cards;
    this.fillBlanks();
    // console.log('Setting activeSection to ', this.activeSection);
    if (!this.isServer) {
      setTimeout(() => {
        this.setSlidesHeight(event.index)
      }, 500);
    }
  }

  setCategory(category) {
    if(category === this.activeCategory) {
      this.activeCategory = null;
      this.cards = this.inDemandCourseData.sections[this.activeIndex].cards
    } else {
      this.activeCategory = category;
      // Filter the cards
      this.cards = this.inDemandCourseData.sections[this.activeIndex].cards.filter((card) => {
        if(card.type !== 'blank') {
          return card.field_category_ids.includes(this.activeCategory);
        } else {
          return false;
        }
      });
      setTimeout(() => {
        this.setSlidesHeight(this.activeIndex)
      }, 500);
    }
    this.fillBlanks();
    // console.log('activeIndex is currently ', this.activeIndex);
    // console.log('Setting activeCategory to ', this.activeCategory);
  }
  isActive(category) {
    if(category === this.activeCategory) {
      return 'active-category';
    } else {
      return '';
    }
  }
  // Simple utility to return the class of the current card, used to set styles for now
  getClass(card: SimpleCourse|SimpleTopic) {
    if(card.type === 'node--topic') {
      return 'topic-card';
    }
    switch(card.field_course_type){
      case "webcast":
      case "webinar":
        return "webinar-card"
      case "seminar":
        return "seminar-card";
      case "self-study":
      case "self-study conference":
        return "ss-card";
      case "self-study webcast":
      case "self-study webinar":
        return "virtual-conference-card";
      default:
        return "";
    }
  }
  /*
  getCardData() {
    // Jared says that we will most likely tag content to show up in the view.
    const headers = new HttpHeaders()
      .set("Content-Type", "application/vnd.api+json");

    this.http.get<CoursesResource>(environment.jsonApiUrl + '/views/front_page_slider_content/page_1', {headers}).subscribe((data) => {
      this.events = data;
      for (let i = 0; i < this.events.data.length; i++) {

        var title = this.normalizeTitle(this.events.data[i].attributes.title);

        //var startDateObj = new Date(this.normalizeDateTime(this.events.data[i].attributes.field_startdatetime));
        //var endDateObj = new Date(this.normalizeDateTime(this.events.data[i].attributes.field_enddatetime));

        this.events.data[i].attributes.title = title;
        //this.events.data[i].attributes.field_startdatetime = startDateObj;
        //this.events.data[i].attributes.field_enddatetime = endDateObj;

      }
    });
    this.http.get<TopicsResource>(environment.jsonApiUrl + '/views/front_page_slider_content/page_2', {headers}).subscribe((data) => {
      this.topics = data;
      for (let i = 0; i < this.topics.data.length; i++) {
        var title = this.normalizeTitle(this.topics.data[i].attributes.title);
        this.topics.data[i].attributes.title = title;
      }
    });
    this.http.get<CoursesResource>(environment.jsonApiUrl + '/views/front_page_slider_content/page_3', {headers}).subscribe((data) => {
      this.courses = data;
      for (let i = 0; i < this.courses.data.length; i++) {
        var title = this.normalizeTitle(this.courses.data[i].attributes.title);
        this.courses.data[i].attributes.title = title;
      }
    });

  }
*/

}
