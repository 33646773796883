import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { map } from 'rxjs';
import { TopicService } from './topic.service';

@Injectable({
  providedIn: 'root'
})
export class GetUTMCodeDataResolverService  {
  constructor(private topicService: TopicService) { }

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    let utm_source = activatedRouteSnapshot.queryParamMap.get('utm_source');
    if(!utm_source) {
      return null;
    }
    return this.topicService.getHomeData().pipe(
      map((result: any) => {
        if(utm_source == "gg_ppc_ssjnh") {
          return {
              topic_ids: ["WC302B","WA395", "SA053", "ST165"],
              discount: 0.5
            }
          }
          if(utm_source == "gg_ppc_sstgf") {
            return {
              topic_ids: ["WA396", "WA394", "WA393", "WT166"],
              discount: 0.7291
            }
          }
          if(utm_source == "gg_ppc_ssdxz") {
            return {
                topic_ids: ["WT165", "WC331", "WT164", "WT118"],
                discount: 0.81819
              }
            }
          return result;
      })
    )
      
  }
}
