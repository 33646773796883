import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SEOService {
  constructor(@Inject(DOCUMENT) private dom: Document, private title: Title, private meta: Meta) {}

  async updateTitle(title: string) {
    this.title.setTitle(title);
  }

  async updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  async updateKeywords(keywords: string) {
    this.meta.updateTag({ name: 'keywords', content: keywords });
  }

  async updateOgUrl(url: string) {
    this.meta.updateTag({
      name: 'og:url',
      property: 'og:url',
      content: url
    });
  }

  async updateOgTitle(ogTitle: string) {
    this.meta.updateTag({
      name: 'og:title',
      property: 'og:title',
      content: ogTitle
    });
  }

  async updateOgDescription(ogDesc: string) {
    this.meta.updateTag({
      name: 'og:description',
      property: 'og:description',
      content: ogDesc
    });
  }

  async updateOgImage(ogImg: string) {
    this.meta.updateTag({
      name: 'og:image',
      property: 'og:image',
      content: ogImg
    });
  }

  async disableFollow() {
    this.meta.addTag({
      name: 'robots',
      property: 'robots',
      content: 'noindex, nofollow'
    });
  }

  async enableFollow() {
    this.meta.removeTag('robots');
  }

  updateCanonicalUrl(url:string){
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }

  async removeTag(tag_attribute: string) {
    this.meta.removeTag(tag_attribute);
  }
  
  removeCanonicalUrl(): void{
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element != null) {
      element.remove();
    }
  }
}
