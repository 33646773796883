<p-toast></p-toast>
<div *ngIf="!isIE; else unsupported_browser">
  <div *ngIf="path.startsWith('/render-api'); else notRenderAPI">
    <router-outlet></router-outlet>
  </div>
  <ng-template #notRenderAPI>
    <header>
      <app-header [menuData]="menuData"></app-header>
    </header>
    <div id="cpeBanner" class="cpe-container" *ngIf="path != '/'">
    <div
      class="flex cpe-wrapper"
      [ngClass]="{
        'front-page align-items-center justify-content-end': path === '/',
        'align-items-end justify-content-start': path !== '/'
      }"
    >
      <div id="hero-text" class="" *ngIf="heroHeading">
        <h1
          id="hero-heading"
          [ngClass]="{
            'cpe-yellow-text': path === '/',
            'text-white': path !== '/'
          }"
        >
          {{ heroHeading }}
        </h1>
        <div *ngIf="path.startsWith('/search-results')">
          <h1 id="hero-message">
            {{ heroMessage }}
          </h1>
        </div>
      </div>
    </div>
    </div><!--cpe-container-->
    <!-- main app container -->
    <main style="min-height: 90vh">
      <div class="sidebar" *ngIf="displayAdminMenu">
        <ul>
          <li *ngFor="let option of adminMenuOptions"><a [href]="option.link">{{ option.title }}</a></li>
        </ul>
      </div>
      <router-outlet></router-outlet>
    </main>

    <footer>
      <app-footer></app-footer>
    </footer>
  </ng-template>
</div>

<ng-template #unsupported_browser>
  <div class="container">
    <div class="row mt-4">
      <!-- <div class="col-md-6 offset-md-3 mt-5">-->
      <div class="col-sm">
        <div class="card" style="height: 500px; text-align: center">
          <h1 class="card-header" style="display: flex; align-items: center">
            <img
              src="../../../assets/cpelogo.png"
              class="img-fluid flex-center"
            />Unsupported Browser
          </h1>
          <div class="card-body">
            <div>
              This site requires Edge, Chrome, Firefox, Safari or newer web
              browser. Please update your browser and try again.
            </div>
            <div>
              You may also call us at 1-800-828-1110 if you wish to make a
              payment or have questions about your account.
            </div>
          </div>
        </div>
      </div>
      <!-- column -->
    </div>
    <!-- row -->
  </div>
  <!-- container-fluid -->
</ng-template>
