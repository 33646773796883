import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {DOCUMENT, isPlatformServer} from "@angular/common";
import {TopicService, UpcomingConferences} from "../../_services/topic.service";
import {SimpleCourse} from "../../../course";
import {SimpleTopic} from "../../../topic";

@Component({
  selector: 'app-upcoming-conferences-widget',
  templateUrl: './upcoming-conferences-widget.component.html',
  styleUrls: ['./upcoming-conferences-widget.component.scss']
})
export class UpcomingConferencesWidgetComponent implements OnInit {

  isServer = isPlatformServer(this.platformId);

  responsiveOptions : any[] = [
    {
      breakpoint: '1200px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '992px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '575px',
      numVisible: 1,
      numScroll: 1
    }
  ];
  cards: (SimpleCourse|SimpleTopic)[];
  @Input() upcomingConferencesData : UpcomingConferences;
  componentLoaded: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    public topicService: TopicService,
  ) { }

  ngOnInit(): void {
    // console.log('upcoming-conferences.widget->Widget has been reloaded. Input: ', this.upcomingConferencesData);
    this.cards = this.upcomingConferencesData.cards;
  }

  ngAfterViewChecked() {
    if(!this.componentLoaded) {
      this.componentLoaded = true;
      this.setSlidesHeight();
    }
  }
  setSlidesHeight(): void {
    let highestBox = 0;
    let sliders = this.document.getElementsByClassName('upcoming-conferences-slider');
    if(sliders?.length) {

      for (let index = 0; index < sliders.length; index++) {
        const slider = sliders[index];
        const slides = slider.children[0].children[0].children[1].children[0].children;
        highestBox = 0;
        for (let i = 0; i < slides.length; i++) {
          const slide = slides[i];
          if(slide.clientHeight > highestBox){
            highestBox = slide.clientHeight;
          }
        }
        for (let ii = 0; ii < slides.length; ii++) {
          const slide = slides[ii];
          if(slide.children.length) {
            slide.children[0].children[0].children[1].children[0]['style'] = "height: "+(highestBox - 100)+"px !important; position: relative;";
            slide.children[0].children[0].children[1].children[0].lastElementChild['style'] = "bottom: 15px";
          }
        }
      }
    }
  }

}
